import { createSelector } from 'reselect';
import { createSelector as quickSelector } from '@ux/fabric';
import { selectors as prodSelectors } from '@ux/product';
import * as r from 'ramda';
import { TaxBand } from 'core/basket';

import { State as RootState } from '../../reducers';
import { State } from '../../reducers/uop/basket';
import { getCredits } from '../account-credits';

const { shouldIncludeTax } = prodSelectors;

const getState: (state: RootState) => State = r.path(['uop', 'basket']);

export const getBasket = quickSelector([getState], r.prop('basket'));

export const getSubtotal = quickSelector(
  [getBasket],
  r.path<number>(['subtotal']),
);

export const getPreCreditGrossTotal = createSelector([getBasket], (basket) => basket?.basketPrices?.total?.gross);

export const getTotal = createSelector(
  [getBasket, shouldIncludeTax, (state) => getCredits(state)],
  (basket, includeTax, credits = 0) => {
    const basketTotal = basket?.basketPrices?.total;
    if (basketTotal == null) {
      return 0;
    }
    const total = includeTax ? basketTotal.gross : basketTotal.net;
    return total - credits;
  },
);

export const getGrossTotal = createSelector(
  [getPreCreditGrossTotal, (state) => getCredits(state)],
  (grossTotal = 0, credits = 0) => grossTotal - credits,
);

export const getAdjustments = createSelector([getBasket], (basket) => {
  const adjustments = basket?.basketPrices?.basketAdjustments;
  if (adjustments == null) {
    return 0;
  }
  return adjustments.net;
});

export const getCurrency = quickSelector(
  [getBasket],
  r.path<string>(['currency']),
);

export const getTaxBands = createSelector([getBasket], (basket) => {
  const bands = basket?.taxes || [];
  return r.map(r.over(r.lensProp('rate'), r.multiply(100)), bands) as TaxBand[];
});

export const getTotalTax = createSelector(
  [getBasket],
  r.path<number>(['basketPrices', 'total', 'tax']),
);

export const getIsNewCustomer = createSelector(
  [getBasket],
  r.path<boolean>(['customer', 'newCustomer']),
);

export const isBasketAssigned = quickSelector([getBasket], (basket) => basket?.customer?.registered === true);
