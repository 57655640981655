import React, { Fragment } from 'react';
import { withProps } from '@team-griffin/rehook';
import { puree, mush, GetInnerProps } from '@ux/pure-enhance';
import { useStyles } from '@team-griffin/stylr';
import { useTheme, Theme } from '@ux/theme-utils';
import { ConnectedImpersonationRibbon as ImpersonationRibbon } from '@ux/impersonation-ribbon';
import { MinimalHeader, MinimalFooter } from '@ux/bookends';
import errorBoundary from '@ux/error-boundary';

import Steptracker from '../steptracker';

interface OuterProps {
  children?: React.ReactNode;
  homeUrl: string;
}

const useStylesheet = ({ theme }: { theme: Theme }) => {
  const { palette } = theme;

  return useStyles({
    default: {
      root: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
      },
      content: {
        flex: 1,
        flexBasis: 'auto', // IE11
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: palette.grey12,
        position: 'relative',
      },
    },
  });
};

const PageBody = mush('PageBody')(errorBoundary())(({ children }) => <Fragment>{children}</Fragment>);

const enhance = puree<OuterProps>('PageTemplate')(
  withProps(useTheme),
  withProps((props) => useStylesheet(props)),
);

type InnerProps = GetInnerProps<typeof enhance>;

export const PurePageTemplate = ({ styles, theme, children, homeUrl }: InnerProps) => (
  <div style={styles.root}>
    <ImpersonationRibbon theme={theme} />
    <MinimalHeader returnUrl={homeUrl} />
    <Steptracker />
    <main style={styles.content}>
      <PageBody>{children}</PageBody>
    </main>

    <MinimalFooter theme={theme} support={true} />
  </div>
);

export default enhance(PurePageTemplate);
