import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { messages as wlMessages } from '@ux/whitelabel';

import { FETCH, fetch } from '../../signals/root';
import * as basket from '../../signals/basket';
import * as customer from '../../signals/customer';
import * as login from '../../signals/login';

const initialFetchEpic = () =>
  createEpic({
    signal: wlMessages.APP_CONFIG_SUCCESS,
    onSuccess: () => fetch(),
  });

const fetchEpic = () =>
  createEpic({
    signal: FETCH,
    onSuccess: () => [basket.fetch(), customer.fetch(), login.initialize()],
  });

export default () => combineEpics([initialFetchEpic(), fetchEpic()]);
