import { Http } from '@ux/drivers';

import _prefetchUpSells from './prefetchUpsells';
import _fetchUpSells from './fetchUpsells';
import _purchaseUpSell from './purchaseUpsell';

export default (json: Http) => {
  const prefetchUpSells = _prefetchUpSells(json);
  const fetchUpSells = _fetchUpSells(json);
  const purchaseUpSell = _purchaseUpSell(json);

  return {
    prefetchUpSells,
    fetchUpSells,
    purchaseUpSell,
  };
};
