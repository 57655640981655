import { Http, Method } from '@ux/drivers';
import { createSequentialQueue } from '@ux/queue';
import { getBasketId } from 'domain/selectors/common';

import { RemoveFromBasket } from '../../core/basket';

const removeFromBasket = (driver: Http): RemoveFromBasket => {
  // because PPE can't handle removing multiple items at once
  // we have to queue up each remove request and send them one
  // at a time :(
  const action: RemoveFromBasket = ({ itemIdsToBeDeleted }) => {
    const basketId = getBasketId();

    return driver({
      url: `/api/basket/api/v2/baskets/${basketId}/items`,
      method: Method.DELETE,
      data: {
        itemIdsToBeDeleted,
      },
    });
  };

  return createSequentialQueue(action);
};

export default removeFromBasket;
