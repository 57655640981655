import * as r from 'ramda';
import * as rA from 'ramda-adjunct';
import * as c from '@team-griffin/capra';

export const nuller = r.always(null);

export const isTrue = r.equals(true);

export const isFalse = r.equals(false);

export const allTrue = r.all(isTrue);

export const allFalse = r.all(isFalse);

export const anyTrue = r.any(isTrue);

export const notEquals = c.curry2((a, b) => r.not(r.equals(a, b)));

export const mapIndexed = r.addIndex(r.map);

export const isNotNilOrEmpty = r.complement(rA.isNilOrEmpty);

export const isPathNotNilOrEmpty = (path: any, obj: Object) => r.pipe(r.pathOr({}, path), r.isEmpty, r.not)(obj);

export const containsAny = (items: any[], list: any[]) => items.some((x) => list.includes(x));

// takes a list and returns only the elements that have duplicates
// only works if it is a list of primitives
export const duplicates = r.pipe(
  r.groupBy<string>(r.identity),
  r.reject(r.pipe(r.length, r.lte(r.__, 1))),
  r.values,
  r.flatten,
  r.uniq,
);

// checks that all elements in a list are the same
export const allEqual = r.pipe(r.uniq, r.length, r.lte(r.__, 1));

// equivalent of dotize.convert except doesn't
// convert arrays
export const dotize = (obj: any, prefix: string) =>
  r.reduce(
    (a, b) => {
      // @ts-ignore
      const c = prefix ? `${prefix}.${b}` : b;
      const value = obj[b];
      if (Object.prototype.toString.call(value) === '[object Object]') {
        return {
          ...a,
          // @ts-ignore
          ...dotize(value, c),
        };
      }
      return {
        ...a,
        [c]: value,
      };
    },
    {},
    r.keys(obj),
  );

// opposite of dotize.convert method
// in: { 'foo.bah': 'x', 'foo.baz': 'y', 'ray': 'z' }
// out: { foo: { bah: 'x', baz: 'y' }, ray: 'z' }
export const undotize = (obj: any): Object =>
  r.pipe(
    r.keys,
    r.map((key: string) =>
      r.pipe(
        r.split(/[^\w\d]+/g),
        r.reduceRight(
          (part, result) => ({
            [part]: result == null ? obj[key] : result,
          }),
          null,
        ),
      )(key),
    ),
    r.reduce(r.mergeDeepRight, {}),
  )(obj);

// eslint-disable-next-line no-empty-function
export const noop = () => {};

export const whileNil = <A>(...predicates: Array<(arg: A) => any>) => {
  return (arg: A) => {
    return r.reduce(
      (result, f) => {
        if (result == null) {
          return f(arg);
        }
        return result;
      },
      null,
      predicates,
    );
  };
};
