import { AnyAction } from 'redux';
import { createEpic } from '@ux/fabric';
import { hasFetched, getError, getBasketItems } from 'domain/selectors/basket';
import { FETCHED as BASKET_FETCHED } from 'domain/messages/basket';

const basketSelector = (state: any) => ({
  fetched: hasFetched(state),
  failed: getError(state) != null,
  items: getBasketItems(state),
});
const basketFilter = (action: AnyAction, state: ReturnType<typeof basketSelector>) => {
  return state.fetched && !state.failed;
};

const createStaleEpic = ({
  messages = [],
  fetchSignal,
  basketRequired,
}: {
  messages?: string[];
  fetchSignal: AnyAction;
  basketRequired: boolean;
}) => {
  if (basketRequired && !messages.includes(BASKET_FETCHED)) {
    // eslint-disable-next-line no-param-reassign
    messages = [...messages, BASKET_FETCHED];
  }
  const selector = basketRequired ? basketSelector : void 0;
  const filter = basketRequired ? basketFilter : void 0;
  const onSuccess = () => fetchSignal;

  return createEpic({
    signal: messages,
    selector,
    filter,
    onSuccess,
  });
};

export default createStaleEpic;
