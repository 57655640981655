import { createSelector } from 'reselect';
import * as c from '@team-griffin/capra';
import * as r from 'ramda';

import { State as RootState } from '../../reducers';
import { State as RequiredFieldsState } from '../../reducers/uop/details';
import { State as RequiredValuesState } from '../../reducers/uop/requiredValues';

const getStateFields: (state: RootState) => RequiredFieldsState = r.path(['uop', 'requiredFields']);
const getStateValues: (state: RootState) => RequiredValuesState = r.path(['uop', 'requiredValues']);

// returns an array of strings for each product
// i.e. { foo: [ 'domain.basic' ] }
export const getFieldSets = createSelector(
  [getStateFields],
  (state: RequiredFieldsState) => state.requiredFields,
);

export const hasFieldSets = createSelector([getFieldSets], c.isNotNilOrEmpty);

// these are the initial values from the api
// { foo: { domain: { basic: { field: 'value' } } } }
export const getFieldValues = createSelector([getStateValues], r.path(['requiredValues']));

export const hasFieldValues = createSelector([getFieldValues], c.isNotNilOrEmpty);

// gets the current help field
export const getHelpField = createSelector<any, any, string>([getStateFields], r.propOr('', 'helpField'));

export const getHelpProductId = createSelector<any, any, string>([getStateFields], r.propOr('', 'productId'));
