import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import { FETCHED, FETCHING, FETCH_FAILED, Fetched, FetchFailed } from '../../messages/account-credits';
import { FETCH_EMPTY } from '../../messages/basket';

export interface State {
  fetching: boolean;
  fetched: boolean;
  credits: {
    available: number;
  };
  error: any;
}

const initialState: State = {
  fetching: false,
  fetched: false,
  credits: {
    available: 0,
  },
  error: null,
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: r.assoc('fetching', true),
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: false,
      fetched: true,
      credits: {
        available: Number(action.payload.credits.available),
      },
      error: null,
    };
  },
  [FETCH_FAILED]: (state, action: FetchFailed) => {
    return {
      ...state,
      fetching: false,
      fetched: false,
      credits: {
        available: 0,
      },
      error: action.payload,
    };
  },
  [FETCH_EMPTY]: (state) => ({
    ...state,
    fetching: false,
    fetched: true,
    credits: {
      available: 0,
    },
    error: null,
  }),
});
