import { createSelector as quickSelector } from '@ux/fabric';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import * as c from '@team-griffin/capra';
import * as r from 'ramda';
import * as a from 'ramda-adjunct';

import { Voucher } from '../../../core/vouchers';
import { State as RootState } from '../../reducers';
import { getScreen } from '../common';
import { State } from '../../reducers/uop/vouchers';
import { ProductStatus } from '../../constants/product';

const getState: (state: RootState) => State = r.path(['uop', 'vouchers']);

export const hasPrefetched = quickSelector([getState], r.prop('prefetched'));
export const hasFetched = quickSelector([getState], r.prop('fetched'));

export const getVouchers = createSelector(
  [getState],
  r.pipe<State, State['vouchers'], Voucher[], Voucher[]>(
    r.prop('vouchers'),
    r.values,
    r.filter(r.propEq('autoApplied', false)),
  ),
);

export const getVoucherIds = createSelector([getVouchers], r.map<Voucher, string>(r.prop('offerCodeId')));

export const getVoucher = (code: string) =>
  createSelector<RootState, Voucher[], Voucher>([getVouchers], r.find(r.propEq('offerCodeId', code)));

export const getCode = (code: string) => createSelector([getVoucher(code)], r.prop('offerCodeId'));

export const getName = (code: string) => createSelector([getVoucher(code)], r.prop('marketingMessage'));

export const getPrice = (code: string) => createSelector([getVoucher(code)], r.prop('adjustmentValue'));

export const getPendingStates = quickSelector([getState], (state) => state?.pendingStates);

const getPendingState = (code: string) => quickSelector([getPendingStates], (pendingStates) => pendingStates?.[code]);

export const getStatus = (code: string) =>
  createSelector([hasFetched, getPendingState(code)], (fetched, pending) => {
    if (!fetched) {
      return ProductStatus.REQUESTING;
    }
    if (pending) {
      return pending;
    }
    return ProductStatus.DEFAULT;
  });

export const showVoucherInput = (screen: string) =>
  quickSelector<RootState, any, boolean>([getScreen(screen)], r.pathOr(true, ['showVoucherInput']));

export const getVoucherTotal = createSelector(
  [getVouchers],
  r.pipe(
    r.map(
      r.path<number>(['adjustmentValue']),
    ),
    r.map<number, number>(r.defaultTo(0)),
    r.reduce<number, number>(r.add, 0),
  ),
);

type FormSelector = <T>(field: string) => (state: any) => T;
const formSelector: FormSelector = r.flip(c.curry2(formValueSelector('voucherRedemption')));

export const getDesire = formSelector<boolean>('desire');
export const isDisabled = createSelector([formSelector<string>('code')], a.isNilOrEmpty);
