import { createSelector } from 'reselect';
import * as r from 'ramda';
import * as rA from 'ramda-adjunct';
import { selectors as prodSelectors } from '@ux/product';

import { State as RootState } from '../../reducers';
import { FieldSets } from '../../constants/details';
import { getFieldSets, getHelpField, getHelpProductId } from './fields';
import { getBasketItems } from '../basket/basketItem';

// returns all of the product ids
// (although actually these are basket item ids!)
export const getProductIds = createSelector<any, any, number[]>(
  [getFieldSets],
  // @ts-ignore
  r.keys,
);

// we want to know whether the customer has more than 1 product
// so we can decide whether or not to show grouping options
export const hasManyProducts = createSelector(getProductIds, rA.lengthGt(1));

// gets field sets for a specific product
export const getProductFieldSets = (productId: number) => createSelector(getFieldSets, r.prop(`${productId}`));

// gets an array of all fields for a product
// i.e. [ 'domain.basic.address.line1' ]
export const getProductFields = (productId: number) =>
  createSelector<RootState, any, string[]>(
    [getProductFieldSets(productId)],
    // @ts-ignore
    r.pipe(
      r.map((set: string) => r.map((field) => `${set}.${field}`, r.propOr([], set, FieldSets))),
      r.flatten,
    ),
  );

// returns the ids of any products that have a given required field
export const getProductsByField = (field: string) =>
  createSelector(getProductIds, r.identity, (productIds, state) =>
    r.pipe(
      r.filter(
        r.pipe(
          getProductFields,
          // @ts-ignore
          r.apply(r.__, [state]),
          r.contains(field),
        ),
      ),
      // @ts-ignore
    )(productIds),
  );

export const getDomainNamesArray = createSelector(
  [getBasketItems],
  r.pipe(
    r.filter<any>(prodSelectors.isDomain),
    r.map((x) => {
      return {
        productName: r.propOr('', 'domainName', x),
        productId: r.propOr('', 'id', x),
      };
    }),
  ),
);

export const getProductName = (id: number) =>
  createSelector([getDomainNamesArray], (domainNamesArray) =>
    r.pipe(r.filter(r.propEq('productId', id)), r.head, r.propOr('', 'productName'))(domainNamesArray),
  );

export const getProducts = createSelector(
  getHelpField,
  getHelpProductId,
  r.identity,
  // @ts-ignore
  (field: string, product: string, state: RootState) =>
    r.pipe(
      r.ifElse(
        rA.isNilOrEmpty,
        () => getProductsByField(field)(state),
        // @ts-ignore
        r.append(r.__, []),
      ),
      r.map((id: string): any => {
        const productId: number = parseInt(id, 10);
        return getProductName(productId)(state);
      }),
      // @ts-ignore
    )(product),
);

// returns an object of id = name
// i.e. { myProduct: 'mydomain.com' }
export const getDomainNames = createSelector(
  getDomainNamesArray,
  (domains: Array<{ productName: string, productId: string }>) => {
    if (domains == null) {
      return [];
    }
    return domains.map((domain) => domain.productName);
  }
);
