import { createValidationMessage } from '@ux/form-utils';

const vMessage = createValidationMessage('uop.requiredInformation.validation');

export const required = vMessage('required');
export const invalid = vMessage('invalid');
export const ukPostcode = vMessage('uk');
export const max = vMessage('max', ['max']);
export const min = vMessage('min', ['min']);

export default vMessage;
