import { combineEpics } from 'redux-most';

import { Core } from '../../../core';
import fetch from './fetch';
import change from './change';
import pageView from './pageView';
import remove from './remove';
import submit from './submit';
import validate from './validate';
import help from './help';
import closeHelp from './closeHelp';
import fetchRequiredValues from './fetchRequiredValues';
import stale from './stale';

export default (deps: Core) =>
  combineEpics([
    submit(deps.details.saveDetails, deps.customer.updateAccountDetails),
    change(),
    fetch(deps.details.fetchRequiredFields),
    pageView(deps.events.view, window),
    help(deps.events.dispatchGAEvent),
    remove(deps.events.dispatchGAEvent),
    closeHelp(deps.events.dispatchGAEvent),
    validate(deps.scroller),
    fetchRequiredValues(deps.details.fetchRequiredValues),
    stale,
  ]);
