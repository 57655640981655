export enum PaymentMethods {
  ADD_CARD = 'add-card',
  CARD = 'card',
  ADYEN_CARD = 'adyen-card',
  ADYEN_SEPA = 'adyen-sepa',
  PAYPAL = 'paypal',
  SEPA = 'sepa',
  ADD_SEPA = 'add-sepa',
  DIRECT_DEBIT = 'direct-debit',
  WIRE_TRANSFER = 'wire-transfer',
  ZERO = 'none',
}

export const PaymentRoutes: {
  [key: string]: string;
} = {
  [PaymentMethods.CARD]: '/card/new',
  [PaymentMethods.ADYEN_CARD]: '/card/embedded',
  [PaymentMethods.ADYEN_SEPA]: '/sepa/embedded',
  [PaymentMethods.SEPA]: '/sepa/new',
  [PaymentMethods.DIRECT_DEBIT]: '/direct-debit/new',
  [PaymentMethods.PAYPAL]: '/paypal/new',
};
