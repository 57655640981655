import { createSelector } from 'reselect';
import * as r from 'ramda';

import { getScreen, getFirstScreen, getNextScreen } from './screens';

export const getFirstUrl = createSelector([getFirstScreen], r.pipe(r.propOr([], 'path'), r.head));

export const getNextUrl = createSelector([getNextScreen], r.pipe(r.propOr([], 'path'), r.head));

export const getScreenUrl = (screen: string) =>
  createSelector([getScreen(screen)], r.pipe(r.propOr([], 'path'), r.head));
