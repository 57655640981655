import { createSelector } from 'reselect';
import * as r from 'ramda';

import { getScreen } from '../common';
import { getDomainsInBasketCount } from '../basket';

const getDomainUrgency = (screen: string) => createSelector([getScreen(screen)], r.propOr(false, 'domainUrgency'));

export const showDomainUrgency = (screen: string) =>
  createSelector([getDomainUrgency(screen), getDomainsInBasketCount], (domainUrgency, domainsInBasket) =>
    r.and(domainUrgency, domainsInBasket > 0),
  );
