import * as yup from 'yup';

import { required } from '../../../basic/messages';

export const idType = yup.string().label('domain.group.es.idType').required(required);

export const idNumber = yup.string().label('domain.group.es.idNumber').nullable(true);

export const contactType = yup.string().label('domain.group.es.contactType').required(required);

export const organisation = yup.string().label('domain.group.es.organisation').nullable(true);
