import { dispatchGAEvent, dispatchFBEvent, ecommerce, link, view } from '@ux/tagmg';
import { scroller } from 'react-scroll';

import { Core } from '../core';
import _accountCredits from './accountCredits';
import _basket from './basket';
import cookies from './cookies';
import _crossSells from './cross-sells';
import _payment from './payment';
import _confirmation from './confirmation';
import _followUps from './follow-ups';
import _ads from './ads';
import _details from './details';
import drivers from './drivers';
import _navigation, { fullyQualifyPath } from './navigation';
import _customer from './customer';
import _vouchers from './vouchers';
import _login from './login';
import _boltOns from './bolt-ons';
import _upsells from './upsells';

export default (): Core => {
  const { json } = drivers();

  const events = {
    dispatchGAEvent,
    dispatchFBEvent,
    ecommerce,
    link,
    view,
  };

  return {
    accountCredits: _accountCredits(json),
    basket: _basket(json, fullyQualifyPath(window)),
    cookies,
    crossSells: _crossSells(json),
    payment: _payment(json, document),
    details: _details(json),
    confirmation: _confirmation(json),
    followUps: _followUps(),
    ads: _ads(),
    events,
    navigation: _navigation(window),
    customer: _customer(json),
    vouchers: _vouchers(json),
    login: _login(window),
    boltOns: _boltOns(json),
    upsells: _upsells(json),
    scroller,
  };
};
