import { Http, Method } from '@ux/drivers';
import { getBasketId } from 'domain/selectors/common';

import { RemoveVoucher } from '../../core/vouchers';

const removeVoucher = (driver: Http): RemoveVoucher => ({ code }) => {
  const basketId = getBasketId();

  return driver({
    url: `/api/basket/api/v2/baskets/${basketId}/offers/${code}`,
    method: Method.DELETE,
  });
};

export default removeVoucher;
