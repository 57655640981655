import { Stream } from 'most';
import { Store } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';
import { waitUntil } from 'most-adjunct';
import { createEpic, combineEpics } from '@ux/fabric';
import { State } from 'domain/reducers';
import { getCurrentScreen } from 'domain/selectors/common';
import { messages as wlMessages } from '@ux/whitelabel';

// when we land on the specified page we want to send a page view event to tealium
const createPageViewEpic = (screen: string, test: (state: any) => boolean, process: (...args: any[]) => any) => {
  // wait until we navigate to the page
  //and app is loaded
  const locationEpic = createEpic({
    signal: [wlMessages.APP_CONFIG_SUCCESS, LOCATION_CHANGE],
    selector: getCurrentScreen,
    filter: (_, currentScreen) => currentScreen.key === screen,
    onSuccess: (_, action) => action,
  });
  // wait until we've got all the data we need
  const stateEpic = (actions$: Stream<any>, store: Store<State>) => waitUntil(() => test(store.getState()), 50);
  const processEpic = createEpic({ process });
  return combineEpics(
    [locationEpic, stateEpic],
    // redux-most sometimes gets angry about Store vs MiddlewareApi
    processEpic as any,
  );
};

export default createPageViewEpic;
