import { createAction } from 'redux-actions';

import { ProductId } from '../../core/basket';

const prefix = '@@uop/details/S_';
export const FETCH = `${prefix}FETCH`;
export const SUBMIT = `${prefix}SUBMIT`;
export const HELP = `${prefix}HELP`;
export const REMOVE = `${prefix}REMOVE`;
export const CLOSE_HELP = `${prefix}CLOSE_HELP`;
export const VALIDATE = `${prefix}VALIDATE`;

export const fetch = createAction(FETCH);

export const submit = createAction<{ values: Object }>(SUBMIT);

export const help = createAction<{
  field: string;
  productId: ProductId;
}>(HELP);

export const remove = createAction<{
  field: string;
  productId: ProductId;
}>(REMOVE);

export const closeHelp = createAction(CLOSE_HELP);

export const validate = createAction(
  VALIDATE,
  ({ values }: { values: Object }) => ({ values }),
  ({
    resolve,
    reject,
  }): {
    resolve: Function;
    reject: Function;
  } => ({
    resolve,
    reject,
  }),
);

export type Fetch = ReturnType<typeof fetch>;
export type Submit = ReturnType<typeof submit>;
export type Help = ReturnType<typeof help>;
export type Remove = ReturnType<typeof remove>;
export type CloseHelp = ReturnType<typeof closeHelp>;
export type Validate = ReturnType<typeof validate>;
