import { Indexer } from '@uxdev/types';
import { schema, normalize } from 'normalizr';
import { Product } from '@ux/product';

import { CrossSell } from '../../core/cross-sells';
import { productSchema } from './products';

const crossSellSchema = new schema.Entity<CrossSell>(
  'crossSells',
  { product: productSchema },
  { idAttribute: 'skuId' },
);

export default (data: CrossSell[]) => {
  const { entities } = normalize(data, [crossSellSchema]);

  return (entities as unknown) as {
    crossSells: Indexer<{}, CrossSell>;
    products: Indexer<{}, Product>;
  };
};
