import { combineEpics } from 'redux-most';

import { Core } from '../../../core';
import fetch from './fetch';
import pageView from './pageView';
import add from './add';
import remove from './remove';
import addGab from './addGab';
import removeGab from './removeGab';
import changeTerm from './changeTerm';
import stale from './stale';
import empty from './empty';
import assignBasket from './assign';

export default (deps: Core) =>
  combineEpics([
    // @ts-ignore
    pageView(deps.events.view, window),
    fetch(deps.basket.fetchBasket, deps.cookies),
    add(deps.basket.addToBasket),
    remove(deps.basket.removeFromBasket, deps.events.dispatchGAEvent, deps.events.ecommerce),
    addGab(deps.boltOns.purchaseBoltOns, deps.events.dispatchGAEvent, deps.events.ecommerce),
    removeGab(deps.basket.removeFromBasket, deps.events.dispatchGAEvent, deps.events.ecommerce),
    changeTerm(deps.basket.updateBasketItem),
    stale,
    empty(),
    assignBasket(deps.basket.assignBasket),
  ]);
