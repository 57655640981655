import deRoutes from './de';
import enRoutes from './en';

type Key =
  | 'login'
  | 'offers'
  | 'basket'
  | 'details'
  | 'payment'
  | 'transaction'
  | 'failedTransaction'
  | 'completeTransaction'
  | 'confirmation';
const swap = (obj: {
  login: string;
  offers: string;
  basket: string;
  details: string;
  payment: string;
  transaction: string;
  failedTransaction: string;
  completeTransaction: string;
  confirmation: string;
}) => {
  const swappedObject: {
    [key: string]: any;
  } = {};

  // eslint-disable-next-line guard-for-in
  for (const key in obj) {
    const objectKey = obj[key as Key];
    swappedObject[objectKey] = key;
  }
  return swappedObject;
};

export const getEnRoute = (location: Location, language: string) => {
  const { protocol, host, pathname } = location;
  if (['en-gb', 'en'].includes(language)) {
    return `${protocol}//${host}${pathname}`;
  }
  const deSwappedRoutes = swap(deRoutes);
  const routeKey = deSwappedRoutes[pathname] as Key;
  const swappedPathname = enRoutes[routeKey];
  return `${protocol}//${host}${swappedPathname}`;
};
