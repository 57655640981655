import { createSelector } from 'reselect';
import * as r from 'ramda';
import { selectors as wlSelectors } from '@ux/whitelabel';

import { Screen } from '../../../core/common';
import { getLocation } from './router';
import { getLandingScreen } from '../root';
import { getRoutes, getEnRoutes } from '../routes';
import { hasAutoBoltOns } from '../auto-bolt-ons';
import filterScreens from './filterScreens';

// returns an object hash showing how each screen
// relates to the user's localsed routes
export const getScreenPaths = createSelector([getRoutes, getEnRoutes], (routes, en) => {
  return {
    login: {
      path: r.uniq([routes.login, en.login]),
    },
    offers: {
      path: r.uniq([routes.offers, en.offers]),
    },
    basket: {
      path: r.uniq([routes.basket, en.basket]),
    },
    details: {
      path: r.uniq([routes.details, en.details]),
    },
    payment: {
      path: r.uniq([routes.payment, en.payment, routes.failedTransaction, routes.completeTransaction]),
    },
    confirmation: {
      path: r.uniq([routes.confirmation, en.confirmation]),
    },
  };
});

const getAllRawScreens = createSelector(
  [wlSelectors.getAppConfig('uop')],
  r.pipe<any, Screen[], Screen[]>(
    r.prop('screens'),
    r.defaultTo([
      {
        key: 'basket',
        path: [],
      },
    ]),
  ),
);

const isAuthScreen = r.propEq('key', 'login');
const isBasketScreen = r.propEq('key', 'basket');
const isOffersScreen = r.propEq('key', 'offers');
// get the position of the login screen
// so we can work out which screens need authenticating
const getAuthIndex = createSelector(
  [getAllRawScreens],
  r.pipe(r.findIndex(isAuthScreen), r.when(r.flip(r.lt)(0), r.always(0))),
);

const isLandingScreenBasket = createSelector(getLandingScreen, isBasketScreen);

const screenHasAuth = createSelector([getAuthIndex, getAllRawScreens], (authIndex, allScreens) =>
  r.pipe(
    r.prop('key'),
    (key) => r.findIndex(r.propEq('key', key), allScreens),
    (index) => index > authIndex,
  ),
);

const getScreenPath = createSelector([getScreenPaths], (paths) => (screen: Screen) =>
  r.path<string[]>([screen.key, 'path'], paths),
);

// returns whether the app config contains a basket screen
const hasBasketScreen = createSelector([getAllRawScreens], r.pipe(r.find(isBasketScreen), Boolean));
const hasOffersScreen = createSelector([getAllRawScreens], r.pipe(r.find(isOffersScreen), Boolean));

// if the user lands on /basket we want to show the basket even if it's not enabled
const prependBasket = createSelector(
  [isLandingScreenBasket, hasBasketScreen, getLandingScreen],
  (landingScreenIsBasket, hasBasket, landingScreen) => (screens: Screen[]) =>
    r.when(r.always(landingScreenIsBasket && !hasBasket), r.prepend(landingScreen))(screens),
);

// if ther are auto bolt ons, we want to show the offers page even if disabled
// const prependOffers
const prependOffers = createSelector(
  [hasOffersScreen, hasAutoBoltOns, getRoutes],
  (hasOffersScreen, hasAutoBoltOns, routes) => (screens: Screen[]) =>
    r.when(
      r.always(!hasOffersScreen && hasAutoBoltOns),
      r.prepend({
        key: 'offers',
        path: [routes.offers],
      }),
    )(screens),
);

export const getAllScreens = createSelector(
  [getAllRawScreens, screenHasAuth, getScreenPath, prependBasket, prependOffers],
  (screens, screenHasAuth, getScreenPath, prependBasket, prependOffers) => {
    return r.pipe(
      prependBasket,
      prependOffers,
      r.map((screen) => {
        const auth = screenHasAuth(screen);
        const path = getScreenPath(screen);
        return {
          ...screen,
          auth,
          path,
        } as Screen;
      }),
    )(screens);
  },
);

export const getScreens = createSelector([filterScreens, getAllScreens], (filter, screens) => filter(screens));

export const getScreen = (key: string) =>
  createSelector(
    [getScreens],
    r.pipe<Screen[], Screen, Screen>(
      r.find(r.propEq('key', key)),
      r.defaultTo({
        key,
        path: [],
      }),
    ),
  );

export const getCurrentScreen = createSelector(
  [getLocation, getAllScreens, getScreenPaths],
  (location, screens, screenPaths) => {
    return r.pipe(
      r.filter((screen: { path: string[] }) => {
        return r.contains(location.pathname, screen.path);
      }),
      r.keys,
      r.head,
      (key) => {
        return r.pipe<Screen[], Screen, Screen>(
          r.find(r.propEq('key', key)),
          r.defaultTo({
            key,
            path: [],
          }),
        )(screens);
      },
    )(screenPaths);
  },
);

export const getFirstScreen = createSelector<any, Screen[], Screen>([getScreens], r.head);

export const getNextScreen = createSelector(
  [getCurrentScreen, getScreens, getAllScreens],
  (current, screens, allScreens) => {
    // we can't just get the current dscreen and find the next index
    // as the current screen might not available any more, so instead
    // we get he next screen from the list of all screens
    // then loop through until we find one that's actually available
    let i = r.findIndex(r.propEq('key', current.key), allScreens) + 1;
    while (i < allScreens.length) {
      const key = r.path([i, 'key'], allScreens);
      const j = r.findIndex(r.propEq('key', key), screens);
      if (j >= 0) {
        return screens[j];
      }
      i = i + 1;
    }
  },
);
