import { createAction } from 'redux-actions';

import { Screen } from '../../core/common';

const prefix = '@@uop/root/M_';
export const LANDING_CAPUTRED = `${prefix}LANDING_CAPUTRED`;

export const landingCaptured = createAction<{
  screen: Screen;
}>(LANDING_CAPUTRED);

export type LandingCaptured = ReturnType<typeof landingCaptured>;
