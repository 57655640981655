import { createSelector as quickSelector } from '@ux/fabric';
import { createSelector } from 'reselect';
import { selectors as prodSelectors } from '@ux/product';
import { selectors as wlSelectors } from '@ux/whitelabel';
import * as r from 'ramda';

import { State as RootState } from '../../reducers';
import { State } from '../../reducers/uop/basket';
import { ProductStatus } from '../../constants/product';
import { BasketItemId, BasketItem } from '../../../core/basket';
import { hasFetched, isFetching } from './common';
import { getProduct as getRawProduct } from '../products';
import makeGetBasketItemProduct from './getBasketItemProduct';
import { getCurrency } from './basket';

const getState: (state: RootState) => State = r.path(['uop', 'basket']);

const getProduct = (state: RootState) => (productId: number) => getRawProduct(productId)(state);
const getBasketItemProduct = r.always(makeGetBasketItemProduct);

export const getBasketItems = quickSelector(
  [getState, getCurrency, getProduct, getBasketItemProduct],
  (state, currency, getProduct, getBasketItemProduct) => {
    const basketItems = Object.values(state?.basketItems ?? {});

    return basketItems
      .map((item) => {
        return {
          ...item,
          product: getBasketItemProduct(
            {
              ...item,
              product: getProduct(item.productId),
            },
            currency,
          ),
        };
      })
      .sort((a, b) => (a.itemIndex > b.itemIndex ? 1 : -1));
  },
);

export const getBasketItemIds = quickSelector([getBasketItems], (basketItems) => basketItems.map((item) => item.id));

export const getLastBasketItem = quickSelector(
  [getBasketItems],
  r.findLast<BasketItem>(r.complement(r.prop('autoAddedBoltOn'))),
);

export const getBasketItem = (id: BasketItemId) =>
  createSelector([getBasketItems], (items: Array<BasketItem>) => items.find((item) => item.id === id));

export const getDomainsInBasketCount = createSelector(
  [getBasketItems],
  r.pipe(r.filter<any>(prodSelectors.isDomain), r.length),
);

export const getCategory = (id: BasketItemId) =>
  createSelector([getBasketItem(id)], (item: BasketItem) =>
    item?.product == null ? '' : prodSelectors.getCategory({ product: item.product }),
  );

export const getName = (id: BasketItemId) =>
  createSelector([getBasketItem(id)], (item: BasketItem) => {
    if (item == null) {
      return '';
    }
    return item?.domainName ?? prodSelectors.getName(item);
  });

export const getBasketItemPrice = (id: BasketItemId) =>
  createSelector([getBasketItem(id), prodSelectors.shouldIncludeTax], (item: BasketItem, includeTax: boolean) =>
    prodSelectors.getPrice({
      product: item?.product,
      interval: item?.paymentInterval,
      term: item?.term,
      includeTax,
    }),
  );

// Price for all quantity of the basketItem
export const getBasketItemSubtotal = (id: BasketItemId) =>
  createSelector([getBasketItem(id), prodSelectors.shouldIncludeTax], (item: BasketItem, includeTax: boolean) => {
    if (includeTax) {
      return item?.itemPrices?.totalPrice?.gross;
    }
    return item?.itemPrices?.totalPrice?.net;
  });

export const getPendingStates = quickSelector([getState], (state) => state?.pendingStates);

const getPendingState = (id: BasketItemId) => quickSelector([getPendingStates], (pendingStates) => pendingStates?.[id]);

export const getInterval = (id: BasketItemId) => quickSelector([getBasketItem(id)], r.prop('paymentInterval'));

export const getTerm = (id: BasketItemId) => quickSelector([getBasketItem(id)], r.prop('term'));

export const getSkuId = (id: BasketItemId) => quickSelector([getBasketItem(id)], (item) => item?.skuId);

export const getStatus = (id: BasketItemId) =>
  createSelector([isFetching, hasFetched, getPendingState(id)], (fetching, fetched, pending) => {
    if (fetching && !fetched) {
      return ProductStatus.REQUESTING;
    }
    if (pending) {
      return pending;
    }
    return ProductStatus.DEFAULT;
  });

export const isRegraded = (id: BasketItemId) => quickSelector([getBasketItem(id)], r.prop('regraded'));

export const getQuantity = (id: BasketItemId) => quickSelector([getBasketItem(id)], r.prop('quantity'));

export const getBoltOnIds = (id: BasketItemId) =>
  createSelector([getBasketItems], (items: Array<BasketItem>) =>
    items.reduce((acc, item) => {
      if (item.boltOnReferencedItemId === id) {
        return [...acc, item.id];
      }
      return acc;
    }, []),
  );

// when updating a basket item, will have to pass the bolt-ons skuIds
// excluding the auto-added ones because they will be added anyway
export const getBoltOnSkuIds = (id: BasketItemId) =>
  createSelector([getBasketItems], (items: Array<BasketItem>) =>
    items.reduce((acc, item) => {
      if (item.boltOnReferencedItemId === id && item.autoAddedBoltOn !== true) {
        return [...acc, item.skuId];
      }
      return acc;
    }, []),
  );

export const showRecurringPrice = createSelector<RootState, any, boolean>(
  [wlSelectors.getBrandConfig],
  r.path(['payment', 'transparentPricing']),
);
