import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import * as rk from '@ux/reactive-knowledge';
import { selectors as product } from '@ux/product';
import * as r from 'ramda';

import { Event } from '../../../domain/constants/events';
import { mapEvent } from '../../../domain/transformers/events';
import { DispatchGAEvent, Ecommerce } from '../../../core/events';
import { PurchaseBoltOns } from '../../../core/bolt-ons';
import { State as RootState } from '../../../domain/reducers';
import * as signals from '../../signals/basket';
import * as messages from '../../../domain/messages/basket';
import * as selectors from '../../../domain/selectors/auto-bolt-ons';
import { getProduct, getSkuId } from '../../../domain/selectors/products';
import { handleAuth, handleEsocketError } from '../utils';

const selector = (state: RootState, action: signals.AddGab) => {
  const ids: number[] = selectors.getBasketItemIds(action.payload.productId)(state);
  const addableIds = r.reject((id) => selectors.isInBasket(id)(state), ids);
  const boltOns = r.map((id) => {
    const gab = selectors.getGab(id)(state);
    const parentId = gab.boltOnReferencedItemId;
    const skuId = getSkuId(action.payload.productId)(state);

    return {
      boltOnRefOrderItemId: parentId,
      skuId,
      quantity: gab.quantity ?? 1,
    };
  }, addableIds);

  return {
    boltOns,
    parentItemIds: addableIds,
  };
};
type State = ReturnType<typeof selector>;

const addGabEpic = (purchase: PurchaseBoltOns) =>
  createEpic({
    signal: signals.ADD_GAB,
    selector,
    pending: (action: signals.AddGab, state: State) => {
      return messages.addingGab({
        productId: action.payload.productId,
        basketItemIds: state.parentItemIds,
      });
    },
    process: (action, state: State) => {
      return purchase({
        items: state.boltOns,
      });
    },
    // @ts-ignore
    onSuccess: (x, action: signals.AddGab, state: State) => {
      return [
        messages.addedGab({
          productId: action.payload.productId,
          basketItemIds: state.parentItemIds,
        }),
        rk.signals.add({
          type: rk.KnowledgeType.SMIRK,
          message: 'uop.basket.added',
        }),
      ];
    },
    onFailure: handleEsocketError(
      handleAuth((err, action: signals.AddGab, state: State) => {
        return [
          messages.addGabFailure({
            productId: action.payload.productId,
            basketItemIds: state.parentItemIds,
          }),
          rk.signals.add({
            type: rk.KnowledgeType.ERK,
            message: 'uop.basket.add.failed',
          }),
        ];
      }),
    ),
  });

const analyticsEpic = (dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  createEpic({
    signal: messages.ADDED_GAB,
    process: (action: messages.AddedGab, state: RootState) => {
      const o = {
        product: getProduct(action.payload.productId)(state),
      };
      const name = product.getName(o);
      const category = product.getCategory(o);
      const sku = action.payload.productId;
      const list = 'bolt-on (offers)';

      ecommerce([
        [
          'addProduct',
          {
            id: sku,
            name: `${name} (auto-added)`,
          },
        ],
        [
          'setAction',
          'add',
          {
            list,
            category,
          },
        ],
      ]);

      dispatch(
        mapEvent({
          event: Event.ADD_ALL_GABS,
          sku,
        }),
      );
    },
  });

export default (purchase: PurchaseBoltOns, dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  combineEpics([addGabEpic(purchase), analyticsEpic(dispatch, ecommerce)]);
