import { schema, normalize } from 'normalizr';
import * as r from 'ramda';

import { Card, Debit, Paypal, Sepa, PaymentToken as RawToken, PaymentTokens } from '../../core/payment';
import { isExpired, parseDate } from './utils';

export const cardSchema = new schema.Entity(
  'cards',
  {},
  {
    processStrategy: (token: RawToken): Card => {
      const expires = parseDate(token.expirydate || '');
      const expired = isExpired(expires);

      return {
        id: token.id,
        type: 'card',
        ending: (token.pan || '').substr(-4),
        expires,
        expired,
      };
    },
  },
);

export const debitSchema = new schema.Entity(
  'debits',
  {},
  {
    processStrategy: (token: RawToken): Debit => ({
      id: token.id,
    }),
  },
);

export const paypalSchema = new schema.Entity(
  'paypal',
  {},
  {
    processStrategy: (token: RawToken): Paypal => ({
      id: token.id,
    }),
  },
);

export const sepaSchema = new schema.Entity(
  'sepa',
  {},
  {
    processStrategy: (token: RawToken): Sepa => {
      const added = new Date(token.addedDate);
      return {
        id: token.id,
        added,
        mandateId: token.mandateId,
      };
    },
  },
);

export const paymentTokenSchema = new schema.Union(
  {
    card: cardSchema,
    debit: debitSchema,
    paypal: paypalSchema,
    sepa: sepaSchema,
  },
  r.pipe(
    r.prop('provider'),
    r.cond([
      [r.equals('gocardless'), r.always('debit')],
      [r.equals('sagepay'), r.always('card')],
      [r.equals('wirecard'), r.always('card')],
      [r.equals('wirecardcc'), r.always('card')],
      [r.equals('datacash'), r.always('card')],
      [r.T, r.identity],
    ]),
  ),
);

const normalizePaymentTokens = (paymentTokens: RawToken[]): PaymentTokens => {
  return r.pipe(
    normalize,
    r.prop('entities'),
    r.merge({
      cards: {},
      debits: {},
      paypal: {},
      sepa: {},
    }),
  )(paymentTokens, [paymentTokenSchema]);
};

export default normalizePaymentTokens;
