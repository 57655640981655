import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { signals as rkSignals, KnowledgeType } from '@ux/reactive-knowledge';
import * as c from '@team-griffin/capra';
import { selectors as prodSelectors } from '@ux/product';
import * as r from 'ramda';
import { dispatchGA4Event } from 'infrastructure/ga4';
import { selectors as wlSelectors } from '@ux/whitelabel';

import { RemoveFromBasket } from '../../../core/basket';
import { DispatchGAEvent, Ecommerce } from '../../../core/events';
import { Event } from '../../../domain/constants/events';
import { getCurrentScreen } from '../../../domain/selectors/common';
import { REMOVE, Remove } from '../../signals/basket';
import {
  REMOVED,
  REMOVE_FAILURE,
  RemoveFailure,
  Removed,
  removeFailure,
  removed,
  removing,
} from '../../../domain/messages/basket';
import * as selectors from '../../../domain/selectors/basket';
import { mapEvent } from '../../../domain/transformers/events';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';

const removeEpic = (removeFromBasket: RemoveFromBasket) =>
  createEpic({
    signal: REMOVE,
    pending: (action: Remove) => removing({ basketItemId: action.payload.basketItemId }),
    process: (action) => {
      const {
        payload: { basketItemId },
      } = action;

      return removeFromBasket({
        itemIdsToBeDeleted: [basketItemId],
      });
    },
    onSuccess: (x, action) => removed({ basketItemId: action.payload.basketItemId }),
    onFailure: handleEsocketError(
      handleAuth((err, action) => {
        return removeFailure(err, action.payload.basketItemId);
      }),
    ),
    join: true,
  });

const successEpic = () =>
  createEpic({
    signal: REMOVED,
    onSuccess: () =>
      rkSignals.add({
        type: KnowledgeType.SMIRK,
        message: 'uop.basket.removed',
      }),
  });

const failedEpic = () =>
  createEpic({
    signal: REMOVE_FAILURE,
    onSuccess: (action: RemoveFailure) =>
      rkSignals.add({
        type: KnowledgeType.ERK,
        message: serverErrorToIntlId('uop.basket.remove.failed')(action.payload),
      }),
  });

const analyticsEpic = (dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  createEpic({
    signal: REMOVED,
    selector: (state: any, action: Removed) => {
      const {
        payload: { basketItemId },
      } = action;
      const item = selectors.getBasketItem(basketItemId)(state);
      const screen = getCurrentScreen(state);
      const productId = prodSelectors.getId(item);
      const name = prodSelectors.getProductName(item);
      const step = r.ifElse(r.equals('basket'), r.always(1), r.always(2))(screen.key);
      const list = c.ifElseBool(r.always('bolt-on'), r.always('basket'))(Boolean(item.boltOnReferencedItemId));
      const brandId = wlSelectors.getBrandId(state);
      const currency = selectors.getCurrency(state);
      const includeTax = prodSelectors.shouldIncludeTax(state);

      return {
        productId,
        name,
        step,
        list,
        item,
        brandId,
        currency,
        includeTax,
      };
    },
    process: (action, state) => {
      const {
        payload: { basketItemId },
      } = action;
      const { list, name, productId, step, item, brandId, currency, includeTax } = state;

      ecommerce([
        [
          'addProduct',
          {
            id: productId,
            name,
          },
        ],
        [
          'setAction',
          'remove',
          {
            step,
            list,
          },
        ],
      ]);

      dispatch(
        mapEvent({
          event: Event.REMOVE_FROM_BASKET,
          basketItemId,
        }),
      );
      const product = item?.product;
      dispatchGA4Event('remove_from_cart', {
        items: [{
          'item_name': product?.name ?? item?.name,
          'item_id': item?.skuId,
          'item_group_id': item?.skuId,
          'item_brand': brandId,
          'item_category': product?.categoryName,
          'item_variant': `${product?.name ?? item?.name} ${item?.term}`,
          'currency': currency,
          'price': product == null ? undefined : prodSelectors.getPrice({
            product,
            interval: item?.paymentInterval,
            term: item?.term,
            includeTax,
          }),
          'quantity': 1,
        }],
      });
    },
  });

export default (removeFromBasket: RemoveFromBasket, dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  combineEpics([removeEpic(removeFromBasket), successEpic(), failedEpic(), analyticsEpic(dispatch, ecommerce)]);

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  removeEpic,
  analyticsEpic,
};
