import { createEpic } from '@ux/fabric';
import { selectors as prodSelectors } from '@ux/product';

import { Ecommerce } from '../../../core/events';
import { FETCHED, Fetched } from '../../../domain/messages/cross-sells';

const impressionEpic = (ecommerce: Ecommerce) =>
  createEpic({
    signal: FETCHED,
    process: (action: Fetched) => {
      const crossSells = action.payload.products ?? {};
      const events = Object.keys(crossSells).map((crossSell, i) => {
        const product = crossSells[crossSell];
        return [
          'addImpression',
          {
            id: prodSelectors.getId({ product }),
            name: prodSelectors.getName({ product }),
            list: 'UOP Offers',
            position: i,
          },
        ] as [string, object];
      });

      ecommerce(events, { nonInteraction: true });
    },
  });

export default impressionEpic;
