import { combineEpics } from 'redux-most';

import { Core } from '../../../core';
import fetch from './fetch';
import impression from './impression';
import pageView from './pageView';
import add from './add';
import stale from './stale';

export default (deps: Core) =>
  combineEpics([
    fetch(deps.crossSells.fetchCrossSells),
    impression(deps.events.ecommerce),
    pageView(deps.events.view, window),
    add(deps.events.dispatchGAEvent, deps.events.ecommerce),
    stale,
  ]);
