import React, { Fragment, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { authGuard } from '@ux/auth/idp';
import { mush, Hoc } from '@ux/pure-enhance';

import AsyncPending from './Pending';

const Checkout = lazy(
  () =>
    import(
      /* webpackChunkName: 'basket' */
      '../checkout'
    ),
);

const enhance = mush('CheckoutPage')(authGuard() as Hoc<{}, {}>);

export const PureCheckoutPage = () => {
  const intl = useIntl();

  return (
    <Fragment>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'brand.metaTitle.template.structure',
            },
            {
              page: intl.formatMessage({
                id: 'uop.metaTitle.payment',
              }),
              brand: intl.formatMessage({
                id: 'brand.metaTitle.template.brand',
              }),
            },
          )}
        </title>
      </Helmet>
      <Suspense fallback={<AsyncPending />}>
        <Checkout />
      </Suspense>
    </Fragment>
  );
};

export default enhance(PureCheckoutPage);
