import * as a from 'ramda-adjunct';

import * as Error from '../../constants/error';

export const isEmptyBasketError = a.contained([
  Error.BASKET_EXPIRED,
  Error.BASKET_NOT_FOUND,
  Error.BASKET_BAD_REQUEST,
  Error.BRAND_BASKET_NOT_FOUND,
  Error.BRAND_CUSTOMER_NOT_FOUND,
  Error.BRAND_BASKET_EMPTY,
  Error.BASKET_NOT_FOUND_FOR_CUSTOMER,
]);
