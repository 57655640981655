import { Indexer } from '@uxdev/types';
import { createEpic } from '@ux/fabric';
import { combineEpics } from 'redux-most';
import { getFormValues } from 'redux-form';
import * as mA from 'most-adjunct';
import * as r from 'ramda';
import { Event } from 'domain/constants/events';
import { PaymentMethods } from 'domain/constants/payment';
import { getDirectDebit, getPaypal, isAdyenProvider } from 'domain/selectors/payment-tokens';
import {
  SUBMIT,
  Submit,
  inlineTransaction,
  redirect,
  fetchProvider as redirectWithProvider,
} from 'application/signals/payment';
import * as basketSelectors from 'domain/selectors/basket';
import { submitting } from 'domain/messages/payment';
import { mapEvent } from 'domain/transformers/events';

import { DispatchGAEvent } from '../../../core/events';

const getPaymentValues = getFormValues('uop.payment') as (state: any) => Indexer;

const forPaymentMethod = r.curryN(
  2,
  r.converge(r.equals, [r.nthArg(0), r.pipe(r.nthArg(1), r.path(['payload', 'method']))]),
);

// we don't want to be able to submit until the basket has loaded
// pretty sure this will never happen, but it's better to be safe
const waitForBasket = (store: any) =>
  mA.waitUntil(() => {
    const state = store.getState();
    const basketFetched = basketSelectors.hasFetched(state);
    const basketFetching = basketSelectors.isFetching(state);
    return basketFetched && !basketFetching;
  }, 100);

// so we can view the basket post-transaction, even after redirecting,
// we put a temporary copy of it into session storage
export const backupStateEpic = (window: Window) =>
  createEpic({
    signal: SUBMIT,
    process: (action, state, actions$, store) => {
      return waitForBasket(store).map(() => {
        window.sessionStorage.setItem('post-transaction-state', JSON.stringify(store.getState()));
      });
    },
  });

export const submitWireTransferEpic = () =>
  createEpic({
    signal: SUBMIT,
    filter: forPaymentMethod(PaymentMethods.WIRE_TRANSFER),
    onSuccess: () =>
      inlineTransaction({
        tokenId: void 0,
        type: PaymentMethods.WIRE_TRANSFER,
      }),
  });

export const submitCardEpic = () =>
  createEpic({
    signal: SUBMIT,
    filter: forPaymentMethod(PaymentMethods.CARD),
    onSuccess: (data, action, state: any) => {
      const id: string = getPaymentValues(state).identifier;
      if (id.startsWith('v2Token_')) {
        return redirectWithProvider({
          method: PaymentMethods.ADYEN_CARD,
          identifier: true,
        });
      }
      return inlineTransaction({
        tokenId: id,
        type: PaymentMethods.CARD,
      });
    },
  });

export const submitAddCardEpic = () =>
  createEpic({
    signal: SUBMIT,
    filter: forPaymentMethod(PaymentMethods.ADD_CARD),
    onSuccess: (data, action, state: any) => {
      const isAdyen = isAdyenProvider(state);
      return isAdyen
        ? redirectWithProvider({ method: PaymentMethods.ADYEN_CARD, identifier: false })
        : redirect({ method: PaymentMethods.CARD });
    },
  });

export const submitPaypalEpic = () =>
  createEpic({
    signal: SUBMIT,
    filter: forPaymentMethod(PaymentMethods.PAYPAL),
    onSuccess: (data, action, state: any) => {
      const paypal = getPaypal(state);

      return r.ifElse(r.isNil, r.always(redirect({ method: PaymentMethods.PAYPAL })), () =>
        inlineTransaction({
          tokenId: paypal.id,
          type: PaymentMethods.PAYPAL,
        }),
      )(paypal);
    },
  });

export const submitDirectDebitEpic = () =>
  createEpic({
    signal: SUBMIT,
    filter: forPaymentMethod(PaymentMethods.DIRECT_DEBIT),
    onSuccess: (data, action, state: any) => {
      const directDebit = getDirectDebit(state);

      return r.ifElse(r.isNil, r.always(redirect({ method: PaymentMethods.DIRECT_DEBIT })), () =>
        inlineTransaction({
          tokenId: directDebit.id,
          type: PaymentMethods.DIRECT_DEBIT,
        }),
      )(directDebit);
    },
  });

// @TODO split this into 2 epics: submitSepa and submitSepaMandate
// the mandate epic will redirect to the
// https://kis.hosteurope.de/kundenkonto/zahlungsmandate/
// and will probably need a SEPA_MANDATE payment method to handle it
export const submitSepaEpic = () =>
  createEpic({
    signal: SUBMIT,
    filter: forPaymentMethod(PaymentMethods.SEPA),
    onSuccess: (data, action, state: any) => {
      const sepaId = getPaymentValues(state).identifierSepa;
      if (sepaId.startsWith('v2Token_')) {
        return redirectWithProvider({
          method: PaymentMethods.ADYEN_SEPA,
          identifier: true,
        });
      }
      return inlineTransaction({
        tokenId: sepaId,
        type: PaymentMethods.SEPA,
      });
    },
  });

export const submitAddSepaEpic = () =>
  createEpic({
    signal: SUBMIT,
    filter: forPaymentMethod(PaymentMethods.ADD_SEPA),
    onSuccess: (data, action, state: any) => {
      const isAdyen = isAdyenProvider(state);
      return isAdyen
        ? redirectWithProvider({ method: PaymentMethods.ADYEN_SEPA, identifier: false })
        : redirect({ method: PaymentMethods.SEPA });
    },
  });

export const submitZeroEpic = () =>
  createEpic({
    signal: SUBMIT,
    filter: forPaymentMethod(PaymentMethods.ZERO),
    onSuccess: () =>
      inlineTransaction({
        tokenId: void 0,
        type: PaymentMethods.ZERO,
      }),
  });

export const submittingEpic = createEpic({
  signal: SUBMIT,
  onSuccess: ({ payload }: Submit) => submitting(payload),
});

export const submitTagEpic = (dispatch: DispatchGAEvent) =>
  createEpic({
    signal: SUBMIT,
    process: (action: Submit) => {
      const {
        payload: { method },
      } = action;

      dispatch(
        mapEvent({
          event: Event.PAYMENT_METHOD_PICKED,
          method,
        }),
      );
    },
  });

export default (dispatch: DispatchGAEvent, window: Window) =>
  combineEpics([
    submittingEpic,
    submitWireTransferEpic(),
    submitCardEpic(),
    submitAddCardEpic(),
    submitPaypalEpic(),
    submitDirectDebitEpic(),
    submitSepaEpic(),
    submitAddSepaEpic(),
    submitZeroEpic(),
    submitTagEpic(dispatch),
    backupStateEpic(window),
  ]);
