import { createEpic, createStructuredSelector } from '@ux/fabric';
import { FetchFollowUps, FollowUp } from 'core/follow-ups';
import { FETCHED, Fetched } from 'domain/messages/confirmation';
import { fetchFailed, fetched, fetching } from 'domain/messages/follow-ups';
import { getBasketKeywords, getFollowUps } from 'domain/selectors/common';

import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';

const selector = createStructuredSelector({
  basketKeywords: getBasketKeywords,
  followUps: getFollowUps,
});
const fetchEpic = (fetch: FetchFollowUps) =>
  createEpic({
    signal: FETCHED,
    selector,
    pending: fetching,
    process: (action: Fetched, { followUps, basketKeywords }) => fetch({ followUps, basketKeywords }),
    onSuccess: (data: Array<FollowUp>) => fetched(data),
    onFailure: handleEsocketError(
      handleAuth((err) => {
        return fetchFailed(serverErrorToIntlId('uop.followUps.load.failed')(err));
      }),
    ),
  });

export default fetchEpic;
