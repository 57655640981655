import * as r from 'ramda';

import { Event } from '../constants/events';

const parseEvent = r.pipe(
  r.split('/'),
  r.converge(r.pipe(r.unapply(r.identity), r.zipObj(['category', 'action'])), [
    r.pipe(r.take(2), r.join('/')),
    r.pipe(r.nth(2)),
  ]),
);

// TODO labelKey type can be string or Function
const eventCreator = (event: string, labelKey: string | (() => string) = '@@nolabel') => {
  const { category, action } = parseEvent(event);

  return [
    r.propEq('event', event),
    r.pipe(
      r.assoc('hitType', 'event'),
      r.assoc('eventCategory', category),
      r.assoc('eventAction', action),
      r.ifElse(
        r.always(r.is(Function, labelKey)),
        (evt) => r.assoc('eventLabel', (labelKey as () => string)(), evt),
        (evt) => r.assoc('eventLabel', evt[labelKey as string], evt),
      ),
      r.pick(['hitType', 'eventCategory', 'eventAction', 'eventLabel']),
    ),
  ] as [(x: any) => boolean, (x: any) => object];
};

// TODO how to type this as above can be string or Function
export const mapEvent = r.cond([
  eventCreator(Event.AD_CLICKED, 'index'),
  eventCreator(Event.FIELD_HELP, 'field'),
  eventCreator(Event.FIELD_HELP_REMOVE, () => 'source-help'),
  eventCreator(Event.FIELD_HELP_CLOSE),
  eventCreator(Event.FOLLOW_UP_CLICKED),
  eventCreator(Event.PAYMENT_METHOD_PICKED, 'method'),
  eventCreator(Event.PAYMENT_CREDITS_USED, 'value'),
  eventCreator(Event.REDEEM_VOUCHER, 'screen'),
  eventCreator(Event.ADD_BOLT_ON, 'sku'),
  eventCreator(Event.ADD_OFFER, 'index'),
  eventCreator(Event.REMOVE_FROM_BASKET, 'basketItemId'),
  eventCreator(Event.UPGRADE_BASKET_ITEM),
  eventCreator(Event.ADD_DOP, 'status'),
  eventCreator(Event.REMOVE_DOP, 'status'),
  eventCreator(Event.TOGGLE_GLOBAL_DOP, 'status'),
  eventCreator(Event.ADD_GAB, 'sku'),
  eventCreator(Event.REMOVE_GAB, 'sku'),
  eventCreator(Event.ADD_ALL_GABS, 'sku'),
  eventCreator(Event.REMOVE_ALL_GABS, 'sku'),
]);

const productArray = (val: { skuId: string; productName: string }) => [
  'addProduct',
  {
    id: val.skuId,
    name: val.productName,
  },
];

const actionArray = (status: boolean) => [
  'setAction',
  r.ifElse(r.equals(false), r.always('remove'), r.always('add'))(status),
  {
    step: 0,
    list: 'UOP DOP',
  },
];

export const mapEcommerceData = (data: Array<Object>, status: boolean) => {
  const productDataSet = r.map((x: any) => [productArray(x), actionArray(status)])(data);
  return r.map(r.flatten)(productDataSet);
};

export default null;
