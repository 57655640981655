import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { signals as rkSignals, KnowledgeType } from '@ux/reactive-knowledge';
import { reset, setSubmitSucceeded, setSubmitFailed, stopAsyncValidation } from 'redux-form';
import * as most from 'most';
import * as r from 'ramda';

import { RedeemVoucher } from '../../../core/vouchers';
import { DispatchGAEvent } from '../../../core/events';
import { ADD, Add } from '../../signals/vouchers';
import {
  ADDED,
  ADD_FAILURE,
  AddFailure,
  Added,
  Adding,
  addFailure,
  added,
  adding,
} from '../../../domain/messages/vouchers';
import { getCurrentScreen } from '../../../domain/selectors/common';
import { mapEvent } from '../../../domain/transformers/events';
import { Event } from '../../../domain/constants/events';
import { handleAuth, serverErrorToIntlId } from '../utils';

const addEpic = (redeemVoucher: RedeemVoucher) =>
  createEpic<Add, Adding | Added | AddFailure, any, { error?: any }>({
    signal: ADD,
    pending: (action) => adding({ code: action.payload.code }),
    process: (action) => {
      const {
        payload: { code },
      } = action;

      return redeemVoucher({ code });
    },
    onSuccess: (data, action) => {
      const {
        payload: { code },
      } = action;

      return r.ifElse(r.prop('error'), handleAuth(addFailure), r.always(added({ code })))(data);
    },
    onFailure: addFailure,
  });

const successEpic = (dispatch: DispatchGAEvent) =>
  createEpic({
    signal: ADDED,
    selector: (state) => ({
      screen: getCurrentScreen(state).key,
    }),
    process: (action, state: { screen: string }) => {
      dispatch(
        mapEvent({
          event: Event.REDEEM_VOUCHER,
          screen: state.screen,
        }),
      );
    },
    onSuccess: () =>
      most.mergeArray([
        most.of<any>(setSubmitSucceeded('voucherRedemption')),
        most.of<any>(reset('voucherRedemption')).delay(2000),
        most.of(
          rkSignals.add({
            type: KnowledgeType.SMIRK,
            message: 'uop.basket.voucherRedeem.success',
          }),
        ),
      ]),
  });

const failedEpic = () =>
  createEpic({
    signal: ADD_FAILURE,
    onSuccess: ({ payload: err }) => [
      setSubmitFailed('voucherRedemption'),
      stopAsyncValidation('voucherRedemption', {
        code: serverErrorToIntlId('uop.basket.voucherRedeem.invalid')(err),
      }),
    ],
  });

export default (redeemVoucher: RedeemVoucher, dispatch: DispatchGAEvent) =>
  combineEpics([addEpic(redeemVoucher), successEpic(dispatch), failedEpic()]);
