import { createSelector as quickSelector } from '@ux/fabric';
import { createSelector } from 'reselect';
import { selectors as wlSelectors } from '@ux/whitelabel';
import * as c from '@team-griffin/capra';
import * as r from 'ramda';

import { State as RootState } from '../../reducers';
import { State } from '../../reducers/uop/details';

const getStateFields: (state: RootState) => State = r.path(['uop', 'requiredFields']);
const getStateValues: (state: RootState) => State = r.path(['uop', 'requiredValues']);

export const hasFetchingFields = quickSelector([getStateFields], r.prop('fetching'));

export const hasFetchedFields = quickSelector([getStateFields], r.prop('fetched'));

export const hasFetchingValues = quickSelector([getStateValues], r.prop('fetching'));

export const hasFetchedValues = quickSelector([getStateValues], r.prop('fetched'));

export const hasFetchFieldsFailed = quickSelector([getStateFields], r.propSatisfies(c.isNotNilOrEmpty, 'error'));

export const hasFetchValuesFailed = quickSelector([getStateValues], r.propSatisfies(c.isNotNilOrEmpty, 'error'));

export const isLoaded = createSelector(
  [
    hasFetchedFields,
    hasFetchedValues,
    hasFetchFieldsFailed,
    hasFetchValuesFailed,
    hasFetchingFields,
    hasFetchingValues,
  ],
  (fields, values, fieldsFailed, valuesFailed, fetchingFields, fetchingValues) => {
    if (fetchingFields || fetchingValues) {
      return false;
    }
    if (fieldsFailed || valuesFailed) {
      return true;
    }
    return fields && values;
  },
);

// For now we're never showing the related product on this screen
// type ShowRelatedProduct = (state: State) => boolean;
// export const showRelatedProduct: ShowRelatedProduct = createSelector(
//   getScreen('details'),
//   r.propEq('related', true),
// );
export const showRelatedProduct = r.F as (state: any) => boolean;

export const getMarket = createSelector<any, any, string | string[]>(
  [wlSelectors.getMarket],
  r.pipe(r.map(r.toUpper), r.defaultTo('GB')),
);

const addressFieldOrder = r.ifElse(
  r.equals('DE'),
  r.always(['line1', 'line2', 'postcode', 'city', 'state', 'country']),
  r.always(['line1', 'line2', 'city', 'state', 'postcode', 'country']),
);

export const getAddressOrder = createSelector(getMarket, addressFieldOrder);

export const getError = quickSelector([getStateFields], r.prop('error'));
