import { createReducer } from 'redux-create-reducer';

import { FETCHING, FETCHED, FETCH_FAILED, Fetched, FetchFailed } from '../../messages/ads';
import { Ad } from '../../../core/ads';

export interface State {
  fetching: boolean;
  fetched: boolean;
  error: any;
  ads: Ad[];
}

const initialState: State = {
  fetching: false,
  fetched: false,
  error: null,
  ads: null as Ad[],
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: (state) => {
    return {
      ...state,
      fetching: true,
      fetched: false,
      ads: [{}, {}, {}] as any,
    };
  },
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: true,
      fetched: true,
      ads: action.payload,
    };
  },
  [FETCH_FAILED]: (state, action: FetchFailed) => {
    return {
      ...state,
      fetching: false,
      fetched: false,
      error: action.payload,
    };
  },
});
