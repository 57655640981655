import { messages as authMessages } from '@ux/auth/idp';
import * as signals from 'application/signals/basket';
import * as messages from 'domain/messages/basket';
import * as vouchers from 'domain/messages/vouchers';
import * as boltOns from 'domain/messages/bolt-ons';
import * as payment from 'domain/messages/payment';
import * as upsells from 'domain/messages/upsells';

import createStaleEpic from '../createStaleEpic';

export default createStaleEpic({
  messages: [
    messages.ADDED,
    messages.REMOVED,
    messages.TERM_CHANGED,
    messages.ADDED_GAB,
    messages.REMOVED_GAB,
    vouchers.ADDED,
    vouchers.REMOVED,
    authMessages.FETCHED,
    boltOns.ADDED,
    payment.TRANSACTION_FAILED,
    upsells.ADDED,
    messages.ASSIGNED,
  ],
  fetchSignal: signals.fetch(),
  basketRequired: false,
});
