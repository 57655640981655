import * as yup from 'yup';

import { required } from '../../../basic/messages';

export const birthDate = yup
  .date()
  .label('domain.group.afnic.birthDate')
  .when('registrantType', {
    is: (x) => x === 'IND' || x === 'FIND',
    then: yup.date().required(required),
    otherwise: yup.date().nullable(true),
  });

export const birthCountryCode = yup
  .string()
  .label('domain.group.afnic.birthCountryCode')
  .when('registrantType', {
    is: (x) => x === 'IND' || x === 'FIND',
    then: yup.string().required(required),
    otherwise: yup.string().nullable(true),
  });

export const birthCity = yup.string().label('domain.group.afnic.birthCity').nullable(true);

export const birthPostCode = yup.string().label('domain.group.afnic.birthPostCode').nullable(true);

export const registrantType = yup.string().label('domain.group.afnic.registrantType').required(required);

export const organisation = yup
  .string()
  .label('domain.group.afnic.organisation')
  .when('registrantType', {
    is: (x) => x && x !== 'IND' && x !== 'FIND',
    then: yup.string().required(required),
    otherwise: yup.string().nullable(true),
  });
