import { createAction } from 'redux-actions';

const prefix = '@@uop/root/S_';
export const INITIALIZE = `${prefix}INITIALIZE`;
export const FETCH = `${prefix}FETCH`;

export const initialize = createAction(INITIALIZE);
export const fetch = createAction(FETCH);

export type Initialize = ReturnType<typeof initialize>;
export type Fetch = ReturnType<typeof fetch>;
