import { createReducer } from 'redux-create-reducer';

import { FETCHED, FETCHING, FETCH_FAILED, Fetched, FetchFailed } from '../../messages/follow-ups';
import { FollowUp } from '../../../core/follow-ups';

export interface State {
  fetching: boolean;
  fetched: boolean;
  error: any;
  followUps: FollowUp[];
}

const initialState: State = {
  fetching: false,
  fetched: false,
  error: null,
  followUps: null as FollowUp[],
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: (state) => {
    return {
      ...state,
      fetching: true,
      fetched: false,
      followUps: [{}] as any,
    };
  },
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: true,
      fetched: true,
      followUps: action.payload,
    };
  },
  [FETCH_FAILED]: (state, action: FetchFailed) => {
    return {
      ...state,
      fetching: false,
      fetched: false,
      error: action.payload,
    };
  },
});
