import { createEpic } from '@ux/fabric';
import { messages as wlMessages } from '@ux/whitelabel';

import { State } from '../../../domain/reducers';
import { getCurrentScreen } from '../../../domain/selectors/common';
import { landingCaptured } from '../../../domain/messages/root';

const selector = (state: State) => ({ screen: getCurrentScreen(state) });
const captureLandingEpic = () =>
  createEpic({
    signal: wlMessages.APP_CONFIG_SUCCESS,
    selector,
    onSuccess: (data, action: wlMessages.AppConfigSuccess, state) => landingCaptured(state),
  });

export default captureLandingEpic;
