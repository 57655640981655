import * as r from 'ramda';

import * as Error from '../../domain/constants/error';

// since we can get a "missing auth from request" validation error before we
// get an actual auth error, we need to intercept it and transform it into
// a normal auth error so, you know, we can actually handle auth errors...
// what a hack
export const handleAuthRequestValidation = r.cond([
  // User does not have an auth token when auth is required
  [
    r.both(
      r.pathEq(['code'], Error.REQUEST_VALIDATION),
      r.pipe(r.propOr([], 'data'), r.any(r.pathEq(['path'], 'auth'))),
    ),
    r.always({
      code: Error.AUTH_ERROR,
    }),
  ],
  [r.T, r.identity],
]);
