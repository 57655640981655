import { PostPayment, PostPaymentType, Process3DSecure } from 'core/payment';
import { OrderBasket } from 'core/basket';
import * as most from 'most';
import { createEpic } from '@ux/fabric';
import { combineEpics } from 'redux-most';
import { push } from 'connected-react-router';
import { stopSubmit } from 'redux-form';
import { PromiseType } from '@uxdev/types';
import { getCurrency } from 'domain/selectors/common';
import { getRoutes } from 'domain/selectors/routes';
import {
  INLINE_TRANSACTION,
  InlineTransaction as InlineTransactionSignal,
  SECURE_TRANSACTION,
  SecureTransaction,
  secureTransaction,
} from 'application/signals/payment';
import {
  TRANSACTION_PROCESSED,
  TRANSACTION_PROCESSED_V2,
  TRANSACTION_FAILURE,
  TransactionProcessed,
  TransactionFailure,
  processingTransaction,
  transactionProcessed,
  transactionFailure,
  transactionFailed,
  submitSucceeded,
} from 'domain/messages/payment';
import { serverErrorToIntlId, handleAuth } from 'application/epics/utils';
import { getOperationId } from 'domain/selectors/pages/payment';

const THREE_D_SECURE_STATUS = 'THREED_SECURE';

export const inlineTransactionEpic = (orderBasket: OrderBasket) =>
  createEpic({
    signal: INLINE_TRANSACTION,
    pending: processingTransaction,
    process: (action: InlineTransactionSignal, state: any) => {
      const {
        payload: { tokenId, type },
      } = action;

      const currency = getCurrency(state);
      const routes = getRoutes(state);

      const successUrl = routes.completeTransaction;
      const failureUrl = routes.failedTransaction;
      return orderBasket({
        currency,
        type,
        tokenId,
        successUrl,
        failureUrl,
      });
    },
    onSuccess: (orderResponse: PromiseType<ReturnType<OrderBasket>>) => {
      if (orderResponse && orderResponse.status === THREE_D_SECURE_STATUS) {
        return secureTransaction({ orderResponse });
      }
      return most.from([submitSucceeded(), transactionProcessed(orderResponse)]);
    },
    onFailure: handleAuth(transactionFailure),
  });

export const process3DSecureEpic = (process3DSecure: Process3DSecure) =>
  createEpic({
    signal: SECURE_TRANSACTION,
    process: (action: SecureTransaction) => {
      const {
        payload: { orderResponse },
      } = action;
      return process3DSecure({
        redirectUrl: orderResponse.acsUrl,
        paReq: orderResponse.paReq,
        callbackUrl: orderResponse.termUrl,
        md: orderResponse.md,
      });
    },
    onSuccess: (result: { orderId: string }) => most.from([submitSucceeded(), transactionProcessed(result)]),
    onFailure: handleAuth(transactionFailure),
  });

export const transactionSuccessEpic = createEpic({
  signal: TRANSACTION_PROCESSED,
  onSuccess: (data, action: TransactionProcessed, state: any) => {
    const routes = getRoutes(state);
    const completeTransaction = routes.completeTransaction ?? '';
    return push(`${completeTransaction}?order=${action.payload.orderId}`);
  },
});

export const transactionSuccessV2Epic = (postPayment: PostPayment) =>
  createEpic({
    signal: TRANSACTION_PROCESSED_V2,
    process: (action) =>
      postPayment({
        operationId: action.payload.operationId,
        transactionId: action.payload.transactionId,
        type: PostPaymentType.SUCCESS,
      }),
    onSuccess: (data: { orderId: string }, _, state: any) => {
      const routes = getRoutes(state);
      const completeTransaction = routes.completeTransaction ?? '';
      return push(`${completeTransaction}?order=${data.orderId}`);
    },
  });

const transactionFailedEpic = (postPayment: PostPayment) =>
  createEpic({
    signal: TRANSACTION_FAILURE,
    process: (_, state: any) => {
      //only if we have this in the url
      const operationId = getOperationId(state);
      if (operationId) {
        return postPayment({
          operationId,
          type: PostPaymentType.FAILURE,
        });
      }
    },
    onSuccess: (_, action: TransactionFailure, state: any) => {
      const routes = getRoutes(state);
      const paymentRoute = routes.payment ?? null;
      const error = serverErrorToIntlId('uop.payment.errors.unknown')(action.payload);
      return most.from([transactionFailed(error), push(paymentRoute), stopSubmit('uop.payment')]);
    },
  });

export default (orderBasket: OrderBasket, process3DSecure: Process3DSecure, postPayment: PostPayment) =>
  combineEpics([
    transactionSuccessEpic,
    transactionSuccessV2Epic(postPayment),
    transactionFailedEpic(postPayment),
    inlineTransactionEpic(orderBasket),
    process3DSecureEpic(process3DSecure),
  ]);

// eslint-disable-next-line
export const __test__ = {
  transactionFailedEpic,
  transactionSuccessV2Epic,
};
