import { Http, Method } from '@ux/drivers';
import { getBasketId } from 'domain/selectors/common';

import { RedeemVoucher } from '../../core/vouchers';

const redeemVoucher = (driver: Http): RedeemVoucher => ({ code }) => {
  const basketId = getBasketId();
  
  return driver({
    url: `/api/basket/api/v2/baskets/${basketId}/offers/${code}`,
    method: Method.POST,
  });
};

export default redeemVoucher;
