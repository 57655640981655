import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { signals as rkSignals, KnowledgeType } from '@ux/reactive-knowledge';

import { UpdateBasketItem } from '../../../core/basket';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';
import * as signals from '../../signals/basket';
import * as messages from '../../../domain/messages/basket';

const changeTermEpic = (update: UpdateBasketItem) =>
  createEpic({
    signal: signals.CHANGE_TERM,
    pending: (action: signals.ChangeTerm) => messages.termChanging(action?.payload),
    process: (action) => {
      return update(action?.payload);
    },
    onSuccess: (x, action) => messages.termChanged(action?.payload),
    onFailure: handleEsocketError(handleAuth((err, action) => messages.termFailure(err, action?.payload))),
    join: true,
  });

const successEpic = () =>
  createEpic({
    signal: messages.TERM_CHANGED,
    onSuccess: () =>
      rkSignals.add({
        type: KnowledgeType.SMIRK,
        message: 'uop.basket.renewalPeriod.update.success',
      }),
  });

const failedEpic = () =>
  createEpic({
    signal: messages.TERM_FAILURE,
    onSuccess: (action: messages.TermFailure) =>
      rkSignals.add({
        type: KnowledgeType.ERK,
        message: serverErrorToIntlId('uop.basket.renewalPeriod.update.failed')(action.payload),
      }),
  });

export default (updateBasketItem: UpdateBasketItem) =>
  combineEpics([changeTermEpic(updateBasketItem), successEpic(), failedEpic()]);

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  changeTermEpic,
  successEpic,
  failedEpic,
};
