import * as yup from 'yup';

import { required, max, min } from './basic/messages';
import getBasicSchema from './basic';

const contactDetailsSchema = getBasicSchema('accountDetails.', false);
const companyDetailsSchema = yup.object().shape({
  companyName: yup.string().when('$isCompany', {
    is: true,
    then: yup.string().required(required).max(255, max),
  }),
  companyNumber: yup.string().when('$isCompany', {
    is: true,
    then: yup.string().min(3, min).max(255, max),
  }),
  companyTaxNumber: yup.string().when('$isCompany', {
    is: true,
    then: yup.string().min(3, min).max(255, max),
  }),
});

const schema = yup.object().shape({
  // validate company details only when company details form section is displayed (is partial profile)
  accountDetails: yup.object().when('$isPartialProfile', {
    is: true,
    then: companyDetailsSchema.concat(contactDetailsSchema),
    otherwise: contactDetailsSchema,
  }),
});

export default schema;
