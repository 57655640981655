import { createAction } from 'redux-actions';

// Action Types
const prefix = '@@uop/vouchers/S_';
export const ADD = `${prefix}ADD`;
export const REMOVE = `${prefix}REMOVE`;

// Action Creators
export const add = createAction<{ code: string }>(ADD);
export const remove = createAction<{ code: string }>(REMOVE);

// Action Payloads
export type Add = ReturnType<typeof add>;
export type Remove = ReturnType<typeof remove>;
