import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { signals as rkSignals, KnowledgeType } from '@ux/reactive-knowledge';
import { dispatchGA4Event } from 'infrastructure/ga4';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { selectors as prodSelectors } from '@ux/product';

import { AddToBasket } from '../../../core/basket';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';
import * as signals from '../../signals/basket';
import * as messages from '../../../domain/messages/basket';
import { getSkuId, getProduct } from '../../../domain/selectors/products';
import { getCurrency } from '../../../domain/selectors/basket';

const addToBasketEpic = (addToBasket: AddToBasket) =>
  createEpic({
    signal: signals.ADD,
    selector: (state: any, action) => {
      return {
        skuId: getSkuId(action.payload.productId)(state),
      };
    },
    pending: (action) => {
      const {
        payload: { productId },
      } = action;
      return messages.adding({ productId });
    },
    process: (action, { skuId }) => {
      return addToBasket({
        items: [{
          skuId,
          paymentInterval: action.payload.paymentInterval,
          term: action.payload.term,
          quantity: action.payload.quantity ?? 1,
        }],
      });
    },
    onSuccess: (data, action) => {
      const {
        payload: { productId },
      } = action;
      return messages.added({ productId });
    },
    onFailure: handleEsocketError(
      handleAuth((err, action) => {
        const {
          payload: { productId },
        } = action;
        return messages.addFailure(err, productId);
      }),
    ),
    join: true,
  });

const successEpic = () =>
  createEpic({
    signal: messages.ADDED,
    onSuccess: () =>
      rkSignals.add({
        type: KnowledgeType.SMIRK,
        message: 'uop.basket.added',
      }),
  });

const failedEpic = () =>
  createEpic({
    signal: messages.ADD_FAILURE,
    onSuccess: (action: messages.AddFailure) =>
      rkSignals.add({
        type: KnowledgeType.ERK,
        message: serverErrorToIntlId('uop.basket.add.failed')(action.payload),
      }),
  });

const analyticsEpic = () =>
  createEpic({
    signal: messages.ADDED,
    selector: (state: any, action: messages.Added) => {
      const productId = action.payload.productId;
      return {
        product: getProduct(productId)(state),
        brandId: wlSelectors.getBrandId(state),
        currency: getCurrency(state),
        includeTax: prodSelectors.shouldIncludeTax(state),
        skuId: getSkuId(productId)(state),
      };
    },
    process: (action, state) => {
      const { brandId, currency, includeTax, product, skuId } = state;
      const term = action.payload.term ?? product?.defaultPrice?.term;

      dispatchGA4Event('add_to_cart', {
        items: [{
          'item_name': product?.name,
          'item_id': skuId,
          'item_group_id': skuId,
          'item_brand': brandId,
          'item_category': product?.categoryName,
          'item_variant': `${product?.name} ${term}`,
          'currency': currency,
          'price': product == null ? undefined : prodSelectors.getPrice({
            product,
            interval: action.payload.paymentInterval ?? 1,
            term,
            includeTax,
          }),
          'quantity': 1,
        }],
      });
    },
  });

export default (addToBasket: AddToBasket) => combineEpics([addToBasketEpic(addToBasket), successEpic(), failedEpic(), analyticsEpic()]);

// eslint-disable-next-line
export const __test__ = {
  addToBasketEpic,
  successEpic,
  failedEpic,
};
