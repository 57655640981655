import * as yup from 'yup';

import { required } from '../../../basic/messages';

export const legalType = yup.string().label('domain.group.nl.legalType').required(required);

export const organisation = yup.string().label('domain.group.nl.organisation').nullable(true);

export const legalTypeRegistrationNumber = yup
  .string()
  .label('domain.group.nl.legalTypeRegistrationNumber')
  .nullable(true);
