export const parseDate = (expiry: string) => {
  if (!expiry) {
    return null;
  }

  let [month, year] = expiry.split('/');
  if (!year?.length || !month?.length) {
    month = expiry.substr(0, 2);
    year = expiry.substr(2);
    if (isNaN(Number(month)) || isNaN(Number(year))) {
      return null;
    }
  }

  month = month.padStart(2, '0');
  year = year.padStart(2, '0');
  year = year.padStart(4, '20');
  const dateStr = `${year}/${month}/01`;

  return new Date(dateStr);
};

export const isExpired = (expiry?: Date) => {
  if (expiry == null) {
    return false;
  }
  const now = new Date();
  return expiry < now;
};
