import { Indexer } from '@uxdev/types';
import { schema, normalize } from 'normalizr';
import { Product } from '@ux/product';

import { BoltOn, BoltOnResponse } from '../../core/bolt-ons';
import { productSchema } from './products';

const boltOnSchema = new schema.Entity<BoltOn>(
  'boltOns',
  { product: productSchema },
  {
    idAttribute: 'basketItemId',
    processStrategy: (data: BoltOnResponse) => {
      return {
        basketItemId: data.basketItemId,
        productId: data.productId,
        weight: data.weighting,
        product: data.product,
        promotionalMessage: data.promotionalMessage,
      };
    },
  },
);

export default (data: BoltOnResponse[]) => {
  const sortedData = data.sort((a, b) => (a.weighting < b.weighting ? -1 : 1));
  const { entities } = normalize(sortedData, [boltOnSchema]);
  return (entities as unknown) as {
    boltOns: Indexer<{}, BoltOn>;
    products: Indexer<{}, Product>;
  };
};
