import deepExtend from 'deep-extend';
import { RequiredFieldSets } from 'core/details';

export interface EligibleFieldsByRegistrantType {
  eligibleUkOrgs: string[];
  eligibleUkComps: string[];
}

export const addExtraRegistrantType = ({
  givenValue,
  isLtdUkGroup,
  isPlcUkGroup,
}: {
  givenValue: any;
  isLtdUkGroup: boolean;
  isPlcUkGroup: boolean;
}) => {
  let modifiedValue = givenValue;
  //if domain is of ltduk group, add automatic ltd registrantType
  if (isLtdUkGroup) {
    modifiedValue = deepExtend(givenValue, {
      domain: {
        group: {
          ltduk: {
            registrantType: 'LTD',
          },
        },
      },
    });
    //if domain is of plcUk group, add automatic PLC registrantType
  } else if (isPlcUkGroup) {
    modifiedValue = deepExtend(givenValue, {
      domain: {
        group: {
          plcuk: {
            registrantType: 'PLC',
          },
        },
      },
    });
  }
  return modifiedValue;
};

export const removeExtraFields = ({
  givenValue,
  registrantType,
  eligibleFieldsByRegistrantType,
}: {
  givenValue: any;
  registrantType: string;
  eligibleFieldsByRegistrantType: EligibleFieldsByRegistrantType;
}) => {
  const filteredValue = deepExtend({}, givenValue);
  const eligibleUkOrgs = eligibleFieldsByRegistrantType?.eligibleUkOrgs;
  const eligibleUkComps = eligibleFieldsByRegistrantType?.eligibleUkComps;
  //filter out unneeded values in uk groups (UEP-365)
  //if registrant type of UK group has an organisation attached
  //and it's not elligible to have one remove it
  if (!eligibleUkOrgs.includes(registrantType)) {
    delete filteredValue.domain?.group?.uk?.organisation;
  }
  //if registrant type of UK group has a companyNumber attached
  //and it's not elligible to have one remove it
  if (!eligibleUkComps.includes(registrantType)) {
    delete filteredValue.domain?.group?.uk?.companyNumber;
  }
  //if registrant type of UK group has a schoolNumber attached
  //and it's not elligible to have one remove it
  if (registrantType !== 'SCH') {
    delete filteredValue.domain?.group?.uk?.schoolNumber;
  }
  //if registrant type of UK group has a charityNumber attached
  //and it's not elligible to have one remove it
  if (registrantType !== 'RCHAR') {
    delete filteredValue.domain?.group?.uk?.charityNumber;
  }
  //if registrant type of UK group has a partnerNames attached
  //and it's not elligible to have one remove it
  if (registrantType !== 'PTNR') {
    delete filteredValue.domain?.group?.uk?.partnerNames;
  }
  return filteredValue;
};

//Given a domain object, it will return a version of that object without
//unnecessary data and will add data that is considered needed for that domain
export const pruneDomainInformation = ({
  key,
  fieldSets,
  values,
  eligibleFieldsByRegistrantType,
}: {
  key: string;
  fieldSets: RequiredFieldSets;
  //Sure, it looks weird but it's updating a lot
  //And this is the things we actually need for this function
  values: {
    domain?: {
      group: {
        [key: string]: any;
      };
      [key: string]: any;
    };
    [key: string]: any;
  };
  eligibleFieldsByRegistrantType: EligibleFieldsByRegistrantType;
}) => {
  //find out if domain is either ltduk or plcuk
  const fieldSetGroups = fieldSets?.[key] ?? [];
  const isLtdUkGroup = fieldSetGroups.includes('domain.group.ltduk');
  const isPlcUkGroup = fieldSetGroups.includes('domain.group.plcuk');

  const givenValue = values?.[key];

  //find out if this domain has uk group values
  const initialUkGroup = givenValue?.domain?.group?.uk ?? '';
  if (!initialUkGroup && !isPlcUkGroup && !isLtdUkGroup) {
    return givenValue;
  }

  //add extra fields if plc/ltd uk groups
  let filteredValue = addExtraRegistrantType({
    givenValue,
    isLtdUkGroup,
    isPlcUkGroup,
  });
  const registrantType = initialUkGroup.registrantType as string;

  //removes extra fields that are leftover between form changes
  filteredValue = removeExtraFields({
    givenValue: filteredValue,
    registrantType,
    eligibleFieldsByRegistrantType,
  });
  return filteredValue;
};
