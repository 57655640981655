import { Http } from '@ux/drivers';
import * as r from 'ramda';

import { FetchCustomer } from '../../core/customer';

const fetchCustomer = (json: Http): FetchCustomer => () => {
  const request = {
    url: '/api/account/v1/accounts/my',
  };

  return json(request).then<any>(r.prop('data'));
};

export default fetchCustomer;
