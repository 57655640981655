import { createEpic, createStructuredSelector } from '@ux/fabric';
import { getFormValues, stopSubmit } from 'redux-form';
import { Indexer } from '@uxdev/types';
import { selectors } from '@ux/language';
import { PaymentMethods } from 'domain/constants/payment';
import { signals as rkSignals, KnowledgeType } from '@ux/reactive-knowledge';

import { FetchProviderToken, ProviderData } from '../../../core/payment';
import { FETCH_PROVIDER, redirect } from '../../signals/payment';

const getPaymentValues = getFormValues('uop.payment') as (state: any) => Indexer;

const fetchProviderEpic = (fetchProvider: FetchProviderToken) =>
  createEpic({
    signal: FETCH_PROVIDER,
    selector: createStructuredSelector({
      language: selectors.getLanguage,
      paymentValues: getPaymentValues,
    }),
    process: (action, { language, paymentValues }) => {
      const paymentMethod = String(action.payload.method).replace('adyen-', '');
      const withIdentifier = action.payload.identifier;
      const identifier = action.payload.method === PaymentMethods.ADYEN_CARD ? 'identifier' : 'identifierSepa';
      const paymentTokenId: string = String(paymentValues?.[identifier]).replace('v2Token_', '');
      return fetchProvider({
        paymentMethod,
        ...(withIdentifier ? { paymentTokenId } : {}),
        language,
      });
    },
    onSuccess: (data: ProviderData, action) => {
      let paymentMethod = action.payload.method;
      if (paymentMethod === PaymentMethods.CARD) {
        paymentMethod = PaymentMethods.ADYEN_CARD;
      } else if (paymentMethod === PaymentMethods.SEPA) {
        paymentMethod = PaymentMethods.ADYEN_SEPA;
      }
      return redirect({
        method: paymentMethod,
        providerId: data.operationId,
        transactionId: data.transactionId,
      });
    },
    onFailure: () => {
      return [
        stopSubmit('uop.payment'),
        rkSignals.add({
          type: KnowledgeType.ERK,
          message: 'uop.payment.errors.unknown',
        }),
      ];
    },
  });

export default (fetchProvider: FetchProviderToken) => fetchProviderEpic(fetchProvider);

// eslint-disable-next-line
export const __test__ = {
  fetchProviderEpic,
};
