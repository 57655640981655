import { createEpic } from '@ux/fabric';
import { selectors as whitelabelSelectors } from '@ux/whitelabel';
import { selectors as authSelectors } from '@ux/auth/idp';
import { Cookies } from 'core/cookies';
import { FetchBasket, View } from 'core/basket';
import { fetchFailed, fetched, fetching } from 'domain/messages/basket';
import { isFetching } from 'domain/selectors/basket';
import { getLocation } from 'connected-react-router';
import { getRoutes } from 'domain/selectors/routes';
import { getBasketId as getBasketIdFromCookie } from 'domain/selectors/common';
import { BASKET_COOKIE } from 'domain/constants/basket';

import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';
import { FETCH } from '../../signals/basket';

const fetchEpic = (fetch: FetchBasket, cookies: Cookies) =>
  createEpic({
    signal: FETCH,
    selector: (state: any) => ({
      fetching: isFetching(state),
      pathname: getLocation(state).pathname,
      routes: getRoutes(state),
      isWhitelabelLoaded: state.whitelabel.appConfig.isLoaded,
      brandId: whitelabelSelectors.getBrandId(state),
      isAuthenticated: authSelectors.isAuthenticated(state),
    }),
    filter: (_, state) => {
      if (!state.isWhitelabelLoaded) {
        return false;
      }
      if (state.fetching) {
        return false;
      }
      const ignoreRoutes = [state.routes.transaction, state.routes.completeTransaction, state.routes.confirmation];
      if (ignoreRoutes.includes(state.pathname)) {
        return false;
      }
      return true;
    },
    pending: fetching,
    process: (_, state) => fetch({ brandId: state?.brandId }),
    onSuccess: (data: View, _, state) => {
      const basketId = data?.basket?.jwtBasketToken ?? data?.basket?.id;
      const basketIdFromCookie = getBasketIdFromCookie();
      if (state?.isAuthenticated && basketIdFromCookie == null && basketId != null) {
        cookies.setCookie(BASKET_COOKIE, basketId);
      }
      return fetched(data);
    },
    onFailure: handleEsocketError(
      handleAuth((err) => {
        return fetchFailed({
          code: err.code,
          id: serverErrorToIntlId('uop.basket.load.failed')(err),
        });
      }),
    ),
  });

export default fetchEpic;
