import { combineEpics } from 'redux-most';

import { Core } from '../../../core';
import fetch from './fetch';
import stale from './stale';
import purchase from './purchase';
import remove from './remove';

export default (deps: Core) =>
  combineEpics([
    fetch(deps.boltOns.fetchBoltOns),
    stale,
    purchase(deps.boltOns.purchaseBoltOns, deps.events.dispatchGAEvent, deps.events.ecommerce),
    remove(deps.events.dispatchGAEvent, deps.events.ecommerce),
  ]);
