import { Http } from '@ux/drivers';
import { getBasketId, getLanguageCookie } from 'domain/selectors/common';

import { FetchBoltOns } from '../../core/bolt-ons';
import normalize from '../../domain/transformers/bolt-ons';

export default (driver: Http): FetchBoltOns => ({ brandId }) => {
  const basketId = getBasketId();
  const languageCookie = getLanguageCookie();

  return driver({
    url: `/api/basket/api/v2/baskets/${basketId}/bolt-ons`,
    data: { localeCode: languageCookie },
    headers: {
      'X-Tenant': brandId,
    },
  }).then(({ data }) => {
    return normalize(data);
  });
};
