import * as r from 'ramda';
import { undotize } from 'crosscutting/ramda';
import { ensureArray } from 'ramda-adjunct';

import * as Error from '../../domain/constants/error';

const formatErrorType = r.over(
  r.lensProp('type'),

  r.replace(/^(any|array|boolean|binary|date|func|number|object|string)./, ''),
);
const buildErrorPair = (fn: any) => r.converge(r.unapply(r.identity), [r.prop('path'), fn]);

interface Err {
  path: string;
  type: string;
  data?: {
    basketItemId: string;
  };
}

const errorMap = {
  [Error.REQUEST_VALIDATION]: (err: Err, intlNamespace: string) =>
    r.pipe(
      /*
    data: [
      {
        path: 'fieldName',
        type: 'any.required',
      }
    ]
    */
      r.propOr([], 'data'),
      ensureArray,
      r.map(
        r.pipe(
          // removes the leading type from the type prop (any, string, etc)
          formatErrorType,
          // maps each data item into
          // [ 'fieldName', 'namespace.errors.fieldName.required' ]
          buildErrorPair((err: Err) => `${intlNamespace}.errors.${err.path}.${err.type}`),
        ),
      ),
      r.fromPairs,
      undotize,
      // return { fieldName: 'namespace.errors.fieldName.required' }
    )(err),

  [Error.INTERNAL_SERVER_ERROR]: 'uop.request.errors.internal',
  [Error.MALFORMED_REQUEST]: 'uop.request.errors.malformed',
  [Error.UNKNOWN_BRAND]: 'uop.request.errors.unknownBrand',
  [Error.INFRASTRUCTURE_ERROR]: 'uop.request.errors.infrastructure',

  [Error.BRAND_ERROR]: 'uop.brand.errors.unknown',
  [Error.BRAND_ORDER_NOT_FOUND]: 'uop.brand.errors.orderNotFound',
  [Error.BRAND_DOMAIN_DATA_NOT_FOUND]: (err: Err) => ({
    id: 'uop.brand.errors.domain.notFound',
    values: {
      basketItemId: err.data.basketItemId,
    },
  }),
  [Error.BRAND_DOMAIN_NOT_AVAILABLE]: (err: Err) => ({
    id: 'uop.brand.errors.domain.notAvailable',
    values: {
      basketItemId: err.data.basketItemId,
    },
  }),
  // [Error.BRAND_BASKET_EMPTY]: redirect to basket screen
  // [Error.BRAND_CUSTOMER_NOT_FOUND]: redirect to basket screen
  // [Error.BRAND_BASKET_NOT_FOUND]: redirect to basket screen

  // [Error.OFFERS_ERROR]: just redirect to the next step

  // [Error.BASKET_TOKEN_ERROR]: '%uop.basket.errors.token',
  // [Error.BASKET_NOT_FOUND]: redirect to basket screen
  // [Error.BASKET_NOT_FOUND_FOR_CUSTOMER]: ""
  // [Error.BASKET_EXPIRED]: ""
  // [Error.BASKET_ERROR]: ""
  [Error.BASKET_ADD_ITEM_FAILED]: 'uop.basket.add.failed',
  [Error.BASKET_UPDATE_ITEM_FAILED]: 'uop.basket.errors.update',
  [Error.BASKET_CHANGE_TERM_FAILED]: 'uop.basket.renewalPeriod.update.failed',
  [Error.BASKET_REMOVE_ITEM_FAILED]: 'uop.basket.remove.failed',
  [Error.BASKET_UPGRADE_ITEM_FAILED]: 'uop.basket.upgrade.failed',
  [Error.BASKET_SAVE_META_FAILED]: 'uop.basket.errors.update',
  [Error.BASKET_VOUCHER_ADD_FAILED]: 'uop.basket.voucherRedeem.invalid',
  [Error.BASKET_VOUCHER_INVALID]: 'uop.basket.voucherRedeem.invalid',
  [Error.BASKET_VOUCHER_EXPIRED]: 'uop.basket.voucherRedeem.expired',
  [Error.BASKET_VOUCHER_MAX_USAGE]: 'uop.basket.voucherRedeem.invalid',
  [Error.BASKET_VOUCHER_DUPLICATE]: 'uop.basket.voucherRedeem.invalid',
  [Error.BASKET_VOUCHER_REMOVE_FAILED]: 'uop.basket.voucher.removeFailed',

  [Error.DETAILS_ERROR]: 'uop.requiredInformation.errors.unknown',

  [Error.CHECKOUT_ERROR]: 'uop.payment.errors.unknown',
  [Error.PAYMENT_ERROR]: 'uop.payment.errors.unknown',
  [Error.PAYMENT_API_ERROR]: 'uop.payment.errors.api',
  [Error.PAYMENT_API_AUTH]: 'uop.payment.errors.auth',
  [Error.PAYMENT_URL_ERROR]: 'uop.payment.errors.url',
  [Error.PAYMENT_TOKENS_ERROR]: 'uop.payment.errors.tokens',
  [Error.PAYMENT_TOKENISATION_ERROR]: 'uop.payment.errors.tokenisation',

  [Error.CONFIRMATION_ERROR]: 'uop.confirmation.errors.unknown',
};

export default errorMap;
