import { createSelector } from 'reselect';
import { createSelector as quickSelector } from '@ux/fabric';
import * as c from '@team-griffin/capra';
import * as r from 'ramda';

import { isEmptyBasketError } from '../../logic/basket';
import { State as RootState } from '../../reducers';
import { State } from '../../reducers/uop/basket';

const getState: (state: RootState) => State = r.path(['uop', 'basket']);

export const hasPrefetched = quickSelector([getState], r.prop('prefetched'));

export const hasFetched = quickSelector([getState], r.prop('fetched'));

export const getError = quickSelector([getState], r.prop('error'));

export const getErrorId = quickSelector([getError], r.prop('id'));

export const hasFetchFailed = createSelector(
  [getError],
  r.both(c.isNotNilOrEmpty, r.propSatisfies(r.complement(isEmptyBasketError), 'code')),
);

export const isFetching = quickSelector([getState], (state) => state.fetching);

export const hasBasketBeenAssigned = quickSelector([getState], (state) => state.assigned);
