import { createSelector as quickSelector } from '@ux/fabric';
import { createSelector } from 'reselect';
import { ProductAttribute, GabProduct, selectors as prodSelectors } from '@ux/product';
import * as c from '@team-griffin/capra';
import * as r from 'ramda';

import { State as RootState } from '../../reducers';
import { State } from '../../reducers/uop/basket';
import { getProduct as getRawProduct } from '../products';
import { BasketItemId, ProductId, BasketItem } from '../../../core/basket';
import { ProductStatus } from '../../constants/product';
import { getStatus as getBoltOnStatus } from '../bolt-ons';
import { getBasketItemIds as getAllBasketItemIds, getCurrency } from '../basket';
import makeGetBasketItemProduct from '../basket/getBasketItemProduct';

const getState: (state: RootState) => State = r.path(['uop', 'basket']);
const getProduct = (state: RootState) => (productId: number) => getRawProduct(productId)(state);
const getBasketItemProduct = r.always(makeGetBasketItemProduct);

export const getGabItems = createSelector<
  RootState,
  State,
  ReturnType<typeof getCurrency>,
  ReturnType<typeof getProduct>,
  ReturnType<typeof getBasketItemProduct>,
  BasketItem[]
>([getState, getCurrency, getProduct, getBasketItemProduct], (state, currency, getProduct, getBasketItemProduct) =>
  r.pipe(
    r.path(['gabs']),
    //@ts-ignore
    r.values,
    r.map((item: BasketItem) => {
      return {
        ...item,
        product: getBasketItemProduct(
          {
            ...item,
            product: getProduct(item.productId),
          },
          currency,
        ),
      };
    }),
    r.sortBy(r.prop('boltOnReferencedItemId')),
  )(state),
);

export const getGab = (id: BasketItemId) => {
  return createSelector<RootState, BasketItem[], BasketItem>([getGabItems], r.find(r.propEq('id', id)));
};

export const getCategory = (id: BasketItemId) =>
  createSelector([getGab(id)], ({ product }) => prodSelectors.getCategory({ product }));

export const getProductIds = createSelector(
  [getGabItems],
  r.pipe<BasketItem[], number[], number[]>(r.map(r.prop('productId')), r.uniq),
);

export const hasAutoBoltOns = createSelector<any, number[], boolean>([getProductIds], c.hasLength);

export const getBasketItemIds = (id: ProductId) =>
  createSelector<any, any[], number[]>(
    [getGabItems],
    // @ts-ignore
    r.pipe(r.filter(r.propEq('productId', id)), r.map(r.prop('id'))),
  );

const getGabProduct = (id: BasketItemId) =>
  createSelector(
    [getGab(id)],
    r.path<GabProduct>(['product', 'gabProduct']),
  );

const getProductAttributes = (id: BasketItemId) =>
  createSelector<any, any, ProductAttribute[]>([getGab(id)], r.pathOr([], ['product', 'productAttributes']));

const makeAttributeGetter = (key: string) => (id: BasketItemId) =>
  createSelector(
    [getProductAttributes(id)],
    r.pipe(r.filter(r.propEq('attributeName', key)), r.map(r.prop('attributeValue')), r.head, r.defaultTo('')),
  );

const hasFirstClassGabFields = (id: BasketItemId) =>
  createSelector([getGabProduct(id)], r.pathSatisfies(Boolean, ['title']));

const getGabProductTitle = (id: BasketItemId) => createSelector([getGabProduct(id)], r.prop('title'));

const getGabProductBenefit = (id: BasketItemId) => createSelector([getGabProduct(id)], r.prop('benefit'));

const getGabProductBody = (id: BasketItemId) => createSelector([getGabProduct(id)], r.prop('body'));

const getMetaTitle = makeAttributeGetter('gab.title');

const getMetaBenefit = makeAttributeGetter('gab.benefit');

const getMetaBody = makeAttributeGetter('gab.body');

export const getTitle = (id: BasketItemId) =>
  createSelector(
    [hasFirstClassGabFields(id), getGabProductTitle(id), getMetaTitle(id)],
    (hasGabProduct, productTitle, metaTitle) => {
      if (hasGabProduct) {
        return productTitle;
      }
      return metaTitle;
    },
  );

export const getBenefit = (id: BasketItemId) =>
  createSelector(
    [hasFirstClassGabFields(id), getGabProductBenefit(id), getMetaBenefit(id)],
    (hasGabProduct, productBenefit, metaBenefit) => {
      if (hasGabProduct) {
        return productBenefit;
      }
      return metaBenefit;
    },
  );

export const getBody = (id: BasketItemId) =>
  createSelector(
    [hasFirstClassGabFields(id), getGabProductBody(id), getMetaBody(id)],
    (hasGabProduct, productBody, metaBody) => {
      if (hasGabProduct) {
        return productBody;
      }
      return metaBody;
    },
  );

const getGabProductImages = (id: BasketItemId) =>
  createSelector([getGabProduct(id)], (gabProduct) => {
    const mobile = r.prop('desktopImage', gabProduct);
    const tablet = r.prop('tabletImage', gabProduct);
    const desktop = r.prop('desktopImage', gabProduct);

    return [mobile, tablet, desktop];
  });

const getMetaImages = (id: BasketItemId) =>
  createSelector(
    [
      makeAttributeGetter('gab.mobileImage')(id),
      makeAttributeGetter('gab.tabletImage')(id),
      makeAttributeGetter('gab.desktopImage')(id),
    ],
    (mobile, tablet, desktop) => [mobile, tablet, desktop],
  );

export const getImages = (id: BasketItemId) =>
  createSelector(
    [hasFirstClassGabFields(id), getGabProductImages(id), getMetaImages(id)],
    (hasGabProduct, productImages, metaImages) => {
      if (hasGabProduct) {
        return productImages;
      }
      return metaImages;
    },
  );

const getGabProductReasons = (id: BasketItemId) =>
  createSelector([getGabProduct(id)], (gabProduct) => {
    const reasons: GabProduct['reasons'] = r.propOr([], 'reasons', gabProduct);
    const result = reasons.map((reason) => ({
      glyph: reason.glyph,
      text: reason.reason,
    }));

    return result;
  });

const getMetaReasons = (id: BasketItemId) =>
  createSelector([getProductAttributes(id)], (attributes) => {
    const initial: {
      [key: string]: {
        glyph: string;
        text: string;
      };
    } = {};
    const result = r.reduce(
      (acc, row) => {
        const match = row.attributeName.match(/gab\.reasons\.(\d)\.(glyph|text)/);
        if (!match) {
          return acc;
        }
        const [, index, key] = match;
        return r.assocPath([index, key], row.attributeValue, acc);
      },
      initial,
      attributes,
    );

    return r.values(result);
  });

export const getReasons = (id: BasketItemId) =>
  createSelector(
    [hasFirstClassGabFields(id), getGabProductReasons(id), getMetaReasons(id)],
    (hasGab, productReasons, metaReasons) => {
      if (hasGab) {
        return productReasons;
      }
      return metaReasons;
    },
  );

const getPendingState = (id: number) =>
  quickSelector(
    [getState],
    r.path<ProductStatus>(['pendingStates', id]),
  );
const getParentBoltOnStatus = (id: number) => (state: RootState) => {
  const gab = getGab(id)(state);
  const parentId = r.prop('boltOnReferencedItemId', gab);
  return getBoltOnStatus(parentId)(state);
};
export const getStatus = (id: number) =>
  createSelector([getPendingState(id), getParentBoltOnStatus(id)], (status, boltOnStatus) => {
    return status || boltOnStatus || ProductStatus.DEFAULT;
  });

export const isInBasket = (id: number) => createSelector([getAllBasketItemIds], r.includes(id));

export const allInBasket = (id: number) =>
  createSelector(
    [getBasketItemIds(id), (state: RootState) => (basketItemId: number) => isInBasket(basketItemId)(state)],
    (allIds: number[], isInBasket) => r.all(isInBasket, allIds),
  );

export const getSuggestionIds = createSelector(
  [getBasketItemIds, (state: RootState) => (id: number) => isInBasket(id)(state)],
  (allIds, isInBasket) => {
    return r.reject(isInBasket, allIds);
  },
);

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  getGabProduct,
  getProductAttributes,
  makeAttributeGetter,
  hasFirstClassGabFields,
  getGabProductReasons,
  getMetaReasons,
};
