import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import * as rk from '@ux/reactive-knowledge';

import { RemoveVoucher } from '../../../core/vouchers';
import * as signals from '../../signals/vouchers';
import * as messages from '../../../domain/messages/vouchers';
import { handleAuth, serverErrorToIntlId } from '../utils';

const removeEpic = (removeVoucher: RemoveVoucher) =>
  createEpic({
    signal: signals.REMOVE,
    pending: (action: signals.Remove) => messages.removing({ code: action.payload.code }),
    process: (action) => {
      const {
        payload: { code },
      } = action;
      return removeVoucher({ code });
    },
    onSuccess: (data, action) => {
      const {
        payload: { code },
      } = action;

      return messages.removed({ code });
    },
    onFailure: handleAuth((err, action) => {
      return messages.removeFailure(err, action.payload.code);
    }),
  });

const failedEpic = () =>
  createEpic({
    signal: messages.REMOVE_FAILURE,
    onSuccess: ({ payload: err }) =>
      rk.signals.add({
        type: rk.KnowledgeType.ERK,
        message: serverErrorToIntlId('uop.basket.remove.failed')(err),
      }),
  });

export default (removeVoucher: RemoveVoucher) => combineEpics([removeEpic(removeVoucher), failedEpic()]);
