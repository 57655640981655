import { createSelector as quickSelector } from '@ux/fabric';
import { createSelector } from 'reselect';
import { BoltOn } from 'core/bolt-ons';

import { State as RootState } from '../../reducers';
import { getProduct } from '../products';
import { ProductStatus } from '../../constants/product';

const getState = (state: RootState) => state.uop.boltOns;

const getBoltOns = createSelector(
  [getState, (state: RootState) => (id: number) => getProduct(id)(state)],
  (state, getProduct) => {
    if (!state.boltOns || Object.keys(state.boltOns).length === 0) {
      return null;
    }
    return Object.keys(state.boltOns).map((boltOn) => {
      const product = getProduct((state.boltOns[boltOn].product as unknown) as number);
      return {
        ...state.boltOns[boltOn],
        product,
      };
    });
  },
);

export const getBoltOn = (basketItemId: number) =>
  createSelector<RootState, BoltOn[], BoltOn>([getBoltOns], (boltons) =>
    boltons?.find((boltons) => boltons.basketItemId === basketItemId),
  );

export const isFetching = quickSelector([getState], (state) => state.fetching);

export const getPendingStates = quickSelector([getState], (state) => state?.pendingStates);

const getPendingState = (id: number) => quickSelector([getPendingStates], (pendingStates) => pendingStates?.[id]);

export const getStatus = (id: number) =>
  createSelector([getPendingState(id)], (pending) => {
    return pending || ProductStatus.DEFAULT;
  });
