import { createAction } from 'redux-actions';

import { RequiredFieldSets } from '../../core/details';
import { ProductId } from '../../core/basket';

// Action Types
const prefix = '@@uop/details/M_';
// -- Fetch
export const FETCHING = `${prefix}FETCHING`;
export const FETCHED = `${prefix}FETCHED`;
export const FETCH_FAILED = `${prefix}FETCH_FAILED`;
export const FORM_POPULATED = `${prefix}FORM_POPULATED`;
// -- Help
export const HELP_OPENED = `${prefix}HELP_OPENED`;
// -- Validate
export const VALIDATING = `${prefix}VALIDATING`;
export const VALIDATE_SUCCEEDED = `${prefix}VALIDATE_SUCCEEDED`;
export const VALIDATE_FAILURE = `${prefix}VALIDATE_FAILURE`;
// -- Submit
export const SUBMITTING = `${prefix}SUBMITTING`;
export const SUBMIT_SUCCESS = `${prefix}SUBMIT_SUCCESS`;
export const SUBMIT_FAILURE = `${prefix}SUBMIT_FAILURE`;

// Action Creators
// -- Fetch
export const fetching = createAction(FETCHING);
export const fetched = createAction<{
  requiredFields: RequiredFieldSets;
}>(FETCHED);
export const fetchFailed = createAction(FETCH_FAILED);
export const formPopulated = createAction(FORM_POPULATED);

// -- Help
export const helpOpened = createAction<{
  field: string;
  productId: ProductId;
}>(HELP_OPENED);

// -- Validate
export const validating = createAction(VALIDATING);
export const validateSucceeded = createAction(VALIDATE_SUCCEEDED);
export const validateFailure = createAction(VALIDATE_FAILURE);
// -- Submit
export const submitting = createAction(SUBMITTING);
export const submitSuccess = createAction(SUBMIT_SUCCESS);
export const submitFailure = createAction(SUBMIT_FAILURE);

// Action Payloads
// -- Fetch
export type Fetching = ReturnType<typeof fetching>;
export type Fetched = ReturnType<typeof fetched>;
export type FetchFailed = ReturnType<typeof fetchFailed>;
export type FormPopulated = ReturnType<typeof formPopulated>;
// -- Help
export type HelpOpened = ReturnType<typeof helpOpened>;
// -- Validate
export type Validating = ReturnType<typeof validating>;
export type ValidateSucceeded = ReturnType<typeof validateSucceeded>;
export type ValidateFailure = ReturnType<typeof validateFailure>;
// -- Submit
export type Submitting = ReturnType<typeof submitting>;
export type SubmitSuccess = ReturnType<typeof submitSuccess>;
export type SubmitFailure = ReturnType<typeof submitFailure>;
