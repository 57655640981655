import { Indexer } from '@uxdev/types';
import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import {
  FETCHED,
  FETCHING,
  PREFETCHED,
  FETCH_FAILED,
  FETCH_EMPTY,
  REMOVING,
  REMOVED,
  REMOVE_FAILURE,
  TERM_CHANGING,
  TERM_CHANGED,
  TERM_FAILURE,
  REMOVED_GAB,
  REMOVE_GAB_FAILURE,
  REMOVING_GAB,
  ADDING_GAB,
  ADDED_GAB,
  ADD_GAB_FAILURE,
  Fetched,
  Prefetched,
  FetchFailed,
  Removing,
  Removed,
  RemoveFailure,
  TermChanging,
  TermChanged,
  TermFailure,
  RemoveGabFailure,
  RemovedGab,
  RemovingGab,
  AddingGab,
  AddedGab,
  AddGabFailure,
  ASSIGNED,
} from '../../messages/basket';
import { Basket, BasketItem } from '../../../core/basket';
import { ProductStatus } from '../../constants/product';

export interface State {
  fetching: boolean;
  prefetched: boolean;
  error: any;
  fetched: boolean;
  assigned: boolean;
  basket: Basket;
  basketItems: Indexer<{}, BasketItem>;
  gabs: Indexer<{}, BasketItem>;
  pendingStates: Indexer<{}, ProductStatus>;
}

const initialState: State = {
  fetching: false,
  prefetched: false,
  fetched: false,
  assigned: false,
  error: null,
  basket: null as Basket,
  basketItems: {},
  gabs: {},
  pendingStates: {},
};

const calculatePendingStates = (pendingStates: State['pendingStates']) => {
  const staleStatuses = [ProductStatus.ADDED, ProductStatus.REMOVED, ProductStatus.UPDATED];

  return Object.keys(pendingStates).reduce((pendingStates, key) => {
    const value = pendingStates[key];
    // once we've fetched the basket we want to remove
    // any pending states that are considered "done"
    if (staleStatuses.includes(value)) {
      return pendingStates;
    }
    return r.assoc(key, value, pendingStates);
  }, {} as State['pendingStates']);
};

const calculateGabs = (basketItems: State['basketItems'], gabs: State['gabs']) => {
  const basketItemsIds = Object.keys(basketItems) ?? [];
  // when updating a basket item (change term) a new basket item is created.
  // so we need to remove all gabs that have a referenced basket item id that no longer exist
  const filteredGabs = Object.keys(gabs)
    .filter((key) => basketItemsIds.includes(`${gabs[key].boltOnReferencedItemId}`))
    .reduce((acc, key) => {
      acc[key] = gabs[key];
      return acc;
    }, {} as Indexer<{}, BasketItem>);

  return basketItemsIds.reduce((gabs, key) => {
    // we want to go through all of our basket items and check if any of them are
    // auto added bolt ons
    const item = basketItems[key];
    const isBoltOn = item?.boltOnReferencedItemId != null;

    // just your bog standard basket item
    if (!isBoltOn) {
      return gabs;
    }

    const ids = r.keys(gabs);
    const alreadyInList = r.contains(key, ids);

    // only do stuff if we haven't already got this gab registered
    if (alreadyInList) {
      return gabs;
    }

    const isGab = item?.autoAddedBoltOn === true;

    if (isGab) {
      // add this basket item to the gabs list
      return r.assoc(key, item, gabs);
    }

    // next we need to check if a gab has changed basket item id
    // this could happen if you've removed a gab and re-added it as it gets a new basket item id
    // luckily you can only have one gab per basket item, so we can use the parent item id
    // as our unique key
    const parentIds = Object.keys(gabs).map((key) => gabs[key].boltOnReferencedItemId);
    if (r.contains(item.boltOnReferencedItemId, parentIds)) {
      // find and replace the existing gab with this new one
      return Object.keys(gabs).reduce((result, key2) => {
        const gab = gabs[key2];
        if (gab.boltOnReferencedItemId === item.boltOnReferencedItemId) {
          return r.assoc(key, item, result);
        }
        return r.assoc(key2, gab, result);
      }, {} as Indexer<{}, BasketItem>);
    }

    return gabs;
  }, filteredGabs);
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: (state) => {
    return {
      ...state,
      fetching: true,
    };
  },
  [PREFETCHED]: (state, action: Prefetched) => {
    if (state.fetched) {
      return state;
    }
    return {
      ...state,
      prefetched: true,
      basket: action.payload.basket,
      basketItems: action.payload.basketItems,
      error: null,
    };
  },
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: false,
      prefetched: true,
      fetched: true,
      basket: action.payload.basket,
      basketItems: action.payload.basketItems,
      error: null,
      pendingStates: calculatePendingStates(state.pendingStates),
      gabs: calculateGabs(action.payload.basketItems, state.gabs),
    };
  },
  [ASSIGNED]: (state) => {
    return {
      ...state,
      assigned: true,
    };
  },
  [FETCH_FAILED]: (state, action: FetchFailed) => {
    return {
      ...state,
      fetching: false,
      prefetched: false,
      fetched: false,
      error: action.payload,
      basket: {} as Basket,
      basketItems: {},
    };
  },
  [FETCH_EMPTY]: (state) => {
    return {
      ...state,
      fetching: false,
      prefetched: true,
      fetched: true,
      error: null,
      basket: {} as Basket,
      basketItems: {},
    };
  },
  [REMOVING]: (state, action: Removing) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.REMOVING,
      },
    };
  },
  [REMOVED]: (state, action: Removed) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.REMOVED,
      },
    };
  },
  [REMOVE_FAILURE]: (state, action: RemoveFailure) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.meta.basketItemId]: ProductStatus.ERROR,
      },
    };
  },

  [REMOVING_GAB]: (state, action: RemovingGab) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.productId]: ProductStatus.REMOVING,
      },
    };
  },
  [REMOVED_GAB]: (state, action: RemovedGab) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.productId]: ProductStatus.REMOVED,
      },
    };
  },
  [REMOVE_GAB_FAILURE]: (state, action: RemoveGabFailure) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.meta.productId]: ProductStatus.ERROR,
      },
    };
  },

  [ADDING_GAB]: (state, action: AddingGab) => {
    return {
      ...state,
      pendingStates: r.reduce(
        (acc, id) => {
          return {
            ...acc,
            [action.payload.productId]: ProductStatus.ADDING,
            [id]: ProductStatus.ADDING,
          };
        },
        state.pendingStates,
        action.payload.basketItemIds,
      ),
    };
  },
  [ADDED_GAB]: (state, action: AddedGab) => {
    return {
      ...state,
      pendingStates: r.reduce(
        (acc, id) => {
          return {
            ...acc,
            [action.payload.productId]: ProductStatus.ADDED,
            [id]: ProductStatus.ADDED,
          };
        },
        state.pendingStates,
        action.payload.basketItemIds,
      ),
    };
  },
  [ADD_GAB_FAILURE]: (state, action: AddGabFailure) => {
    return {
      ...state,
      pendingStates: r.reduce(
        (acc, id) => {
          return {
            ...acc,
            [action.payload.productId]: ProductStatus.ERROR,
            [id]: ProductStatus.ERROR,
          };
        },
        state.pendingStates,
        action.payload.basketItemIds,
      ),
    };
  },

  [TERM_CHANGING]: (state, action: TermChanging) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.UPDATING,
      },
    };
  },
  [TERM_CHANGED]: (state, action: TermChanged) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.UPDATED,
      },
    };
  },
  [TERM_FAILURE]: (state, action: TermFailure) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.meta.basketItemId]: ProductStatus.ERROR,
      },
    };
  },
});
