import React, { ComponentType } from 'react';
import { useStyles } from '@team-griffin/stylr';
import { withProps } from '@team-griffin/rehook';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import { Message } from '@ux/message';
import Steptracker from '@ux/steptracker';
import { useTheme, Theme, gu2px } from '@ux/theme-utils';
import { percentage } from '@ux/style-utils';
import { trackInteraction } from '@ux/tagmg';
import { withBreakpointsHook } from '@ux/responsive';
import { Container } from '@ux/grid';
import Transition from 'react-inline-transition-group';
import cssSides, { Side } from '@team-griffin/css-sides';
import * as r from 'ramda';

const TrackedStepTrackerContainer = trackInteraction({
  source: 'stepTrackerClicked',
})(('div' as unknown) as ComponentType<never>);

export interface OuterProps {
  showSteptracker: boolean;
  currentStep: string;
  steps: string[];
}

const useStylesheet = ({ theme: { palette, spacing } }: { theme: Theme }) => {
  const { isMinMd, isMinLg } = withBreakpointsHook({});

  const { styles } = useStyles(
    {
      default: {
        root: {
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
        },
        transition: {},
        content: {
          flex: 1,
          flexBasis: 'auto', // IE11
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: palette.grey12,
          position: 'relative',
        },
        steptracker: {
          ...cssSides('padding', Side.Y, gu2px(2, spacing)),
          backgroundColor: '#fff',
        },
      },

      isMinMd: {
        steptracker: {
          paddingTop: gu2px(3, spacing),
          ...cssSides('padding', Side.X, gu2px(2, spacing)),
        },
      },

      isMinLg: {
        steptracker: {
          paddingTop: gu2px(4, spacing),
          paddingBottom: gu2px(3, spacing),
          maxWidth: percentage(60),
          ...cssSides('margin', Side.X, 'auto'),
        },
      },
    },
    [{ isMinMd }, { isMinLg }],
  );

  return {
    styles: {
      ...styles,
      transitionItem: {
        base: {
          maxHeight: 0,
          opacity: 0,
          overflow: 'hidden',
        },
        appear: {
          opacity: 1,
          maxHeight: 120,
        },
        enter: {
          maxHeight: 120,
          opacity: 1,
          transition: 'max-height 0.6s ease-in-out, opacity 0.6s ease-in-out',
        },
        leave: {
          maxHeight: 0,
          opacity: 0,
          transition: 'max-height 0.6s ease-in-out, opacity 0.6s ease-in-out',
        },
      },
    } as any,
  };
};

const enhance = puree<OuterProps>('Steptracker')(
  withProps((props) => {
    const active = r.pipe(r.indexOf(props.currentStep), r.inc)(props.steps);
    const showSteptracker = r.and(r.gte(active, 1), props.showSteptracker);

    return {
      active,
      showSteptracker,
    };
  }),
  withProps(useTheme),
  withProps((props) => useStylesheet(props)),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureSteptracker = ({ theme, showSteptracker, steps, active, styles }: InnerProps) => (
  <Transition style={styles.transition} childrenStyles={styles.transitionItem} key={steps.length}>
    {r.when(r.equals(true), () => (
      <div key="steptracker">
        <Container>
          <div style={styles.steptracker}>
            <TrackedStepTrackerContainer>
              <Steptracker
                theme={theme}
                steps={steps.length}
                active={active}
                showLabels={['sm', 'md', 'lg', 'xl']}
                labels={r.map((step) => <Message key={step} id={`uop.steptracker.${step}`} />, steps) as any}
              />
            </TrackedStepTrackerContainer>
          </div>
        </Container>
      </div>
    ))(showSteptracker)}
  </Transition>
);

export default enhance(PureSteptracker);
