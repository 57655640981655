import { createEpic, createStructuredSelector } from '@ux/fabric';
import { messages as wlMessages } from '@ux/whitelabel';
import { bootstrap } from '@pex/upm';

import { getTealiumCategories, getCookiesToPreserve } from '../../../domain/selectors/upm';

const upmEpic = () =>
  createEpic({
    signal: wlMessages.APP_CONFIG_SUCCESS,
    selector: createStructuredSelector({
      categories: getTealiumCategories,
    }),
    onSuccess: (data, action, { categories }) =>
      bootstrap({
        categories: categories,
        cookiesToPreserve: getCookiesToPreserve(),
      }),
  });

export default upmEpic;
