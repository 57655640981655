import { schema, normalize } from 'normalizr';
import * as r from 'ramda';
import { PaymentMethods } from 'domain/constants/payment';

import { parseDate } from './utils';
import { Card, Sepa, PaymentTokenV2 as RawToken, PaymentTokens } from '../../core/payment';

export const cardSchema = new schema.Entity(
  'cards',
  {},
  {
    processStrategy: (token: RawToken): Card => {
      const expires = parseDate(token.metaData.expiryDate || '');
      const expired = token.state !== 'ACTIVE';
      return {
        id: `v2Token_${token.id}`,
        type: String(token.type).toLowerCase(),
        ending: token.metaData.lastFour,
        provider: PaymentMethods.ADYEN_CARD,
        expires,
        expired,
      };
    },
  },
);

export const sepaSchema = new schema.Entity(
  'sepa',
  {},
  {
    processStrategy: (token: RawToken): Sepa => {
      const added = new Date(token.addedDate);
      return {
        id: `v2Token_${token.id}`,
        added,
        provider: PaymentMethods.ADYEN_SEPA,
        mandateId: token.metaData.mandateId,
      };
    },
  },
);

export const paymentTokenSchema = new schema.Union(
  {
    card: cardSchema,
    sepa: sepaSchema,
  },
  (item) => {
    switch (item.type) {
      case 'SEPA':
        return 'sepa';
      case 'CARD':
        return 'card';
      default:
        return null;
    }
  },
);

const normalizePaymentTokens = (paymentTokens: RawToken[]): PaymentTokens => {
  return r.pipe(
    normalize,
    r.prop('entities'),
    r.merge({
      cards: {},
      sepa: {},
    }),
  )(paymentTokens, [paymentTokenSchema]);
};

export default normalizePaymentTokens;
