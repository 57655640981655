import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import {
  TOKENS_FETCHED_V2,
  TOKENS_FETCHING_V2,
  TOKENS_FETCH_FAILED_V2,
  TokensFetchedV2,
  TokensFetchFailedV2,
} from '../../messages/payment';
import { PaymentTokens } from '../../../core/payment';

export interface State {
  fetching: boolean;
  fetched: boolean;
  tokens: PaymentTokens;
  error: any;
}

const initialState: State = {
  fetching: false,
  fetched: false,
  tokens: {
    cards: {},
    debits: {},
    sepa: {},
  },
  error: null,
};

export default createReducer<State, any>(initialState, {
  [TOKENS_FETCHING_V2]: r.assoc('fetching', true),
  [TOKENS_FETCHED_V2]: (state, action: TokensFetchedV2) => {
    return {
      ...state,
      fetching: false,
      fetched: true,
      tokens: action.payload.tokens,
      error: null,
    };
  },
  [TOKENS_FETCH_FAILED_V2]: (state, action: TokensFetchFailedV2) => {
    return {
      ...state,
      fetching: false,
      fetched: false,
      tokens: {
        cards: {},
        debits: {},
        sepa: {},
      },
      error: action.payload,
    };
  },
});
