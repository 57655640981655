import { Core } from 'core';
import { combineEpics } from 'redux-most';

import rootEpics from './root';
import crossSells from './cross-sells';
import accountCredits from './account-credits';
import basket from './basket';
import payment from './payment';
import vouchers from './vouchers';
import confirmation from './confirmation';
import details from './details';
import followUps from './follow-ups';
import ads from './ads';
import customer from './customer';
import login from './login';
import boltOns from './bolt-ons';
import upsells from './upsells';

export default (deps: Core) =>
  combineEpics([
    crossSells(deps),
    accountCredits(deps),
    basket(deps),
    vouchers(deps),
    payment(deps),
    confirmation(deps),
    rootEpics(deps),
    details(deps),
    followUps(deps),
    ads(deps),
    customer(deps),
    login(deps),
    boltOns(deps),
    upsells(deps),
  ]);
