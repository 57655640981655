import { Indexer } from '@uxdev/types';
import { createReducer } from 'redux-create-reducer';

import {
  FETCHED,
  FETCHING,
  FETCH_FAILED,
  ADDING,
  ADDED,
  ADD_FAILED,
  Fetched,
  FetchFailed,
  Adding,
  Added,
  AddFailed,
} from '../../messages/cross-sells';
import { CrossSell } from '../../../core/cross-sells';
import { ProductStatus } from '../../constants/product';

export interface State {
  fetching: boolean;
  fetched: boolean;
  error: any;
  crossSells: Indexer<{}, CrossSell>;
  pendingStates: Indexer<{}, ProductStatus>;
}

const initialState: State = {
  fetching: false,
  fetched: false,
  crossSells: {},
  pendingStates: {},
  error: null,
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: (state) => {
    return {
      ...state,
      fetching: true,
    };
  },
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: false,
      fetched: true,
      crossSells: action.payload.crossSells,
      error: null,
    };
  },
  [FETCH_FAILED]: (state, action: FetchFailed) => {
    return {
      ...state,
      fetching: false,
      fetched: false,
      crossSells: {},
      error: action.payload,
    };
  },

  [ADDING]: (state, action: Adding) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.productId]: ProductStatus.ADDING,
      },
    };
  },
  [ADDED]: (state, action: Added) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.productId]: ProductStatus.ADDED,
      },
    };
  },
  [ADD_FAILED]: (state, action: AddFailed) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.meta.productId]: ProductStatus.ERROR,
      },
    };
  },
});
