import { createAction } from 'redux-actions';
import { Indexer } from '@uxdev/types';
import { Product } from '@ux/product';
import { BoltOn } from 'core/bolt-ons';
import { BasketItemId, ProductId } from 'core/basket';

const prefix = '@@uop/bolt-ons/M_';

export const FETCHING = `${prefix}FETCHING`;
export const FETCHED = `${prefix}FETCHED`;
export const FETCH_FAILED = `${prefix}FETCH_FAILED`;
export const ADDING = `${prefix}ADDING`;
export const ADDED = `${prefix}ADDED`;
export const ADD_FAILED = `${prefix}ADD_FAILED`;
export const REMOVING = `${prefix}REMOVING`;
export const REMOVED = `${prefix}REMOVED`;
export const REMOVE_FAILED = `${prefix}REMOVE_FAILED`;

export const fetching = createAction(FETCHING);
export const fetched = createAction<{
  boltOns: Indexer<{}, BoltOn>;
  products: Indexer<{}, Product>;
}>(FETCHED);
export const fetchFailed = createAction(FETCH_FAILED);
export const adding = createAction<{
  basketItemId: BasketItemId;
  productId: ProductId;
}>(ADDING);
export const added = createAction<{
  basketItemId: BasketItemId;
  productId: ProductId;
}>(ADDED);
export const addFailed = createAction(
  ADD_FAILED,
  (err) => err,
  (
    err,
    payload: {
      basketItemId: BasketItemId;
      productId: ProductId;
    },
  ) => payload,
);
export const removing = createAction<{
  basketItemId: BasketItemId;
  parentId: BasketItemId;
}>(REMOVING);
export const removed = createAction<{
  basketItemId: BasketItemId;
  parentId: BasketItemId;
}>(REMOVED);
export const removeFailed = createAction<{
  basketItemId: BasketItemId;
  parentId: BasketItemId;
}>(REMOVE_FAILED);

export type Fetching = ReturnType<typeof fetching>;
export type Fetched = ReturnType<typeof fetched>;
export type FetchFailed = ReturnType<typeof fetchFailed>;
export type Adding = ReturnType<typeof adding>;
export type Added = ReturnType<typeof added>;
export type AddFailed = ReturnType<typeof addFailed>;
export type Removing = ReturnType<typeof removing>;
export type Removed = ReturnType<typeof removed>;
export type RemoveFailed = ReturnType<typeof removeFailed>;
