import { schema, normalize } from 'normalizr';
import * as r from 'ramda';
import { Basket, BasketItem, BasketResponse, BasketItemResponse } from 'core/basket';

import { voucherSchema } from './vouchers';
import { productSchema } from './products';

const basketItemSchema = new schema.Entity<BasketItem>(
  'basketItems',
  { product: productSchema },
  {
    processStrategy: (item: BasketItemResponse) => {
      return {
        ...item,
        productKeywords: item?.product?.keywords,
      };
    },
  },
);

const basketSchema = new schema.Entity<Basket>(
  'basket',
  {
    basketItems: [basketItemSchema],
    vouchers: [voucherSchema],
  },
  {
    processStrategy: (basket: BasketResponse) => {
      const { items, basketAdjustments, ...rest } = basket;
      return {
        basketItems: (items as unknown) as Array<number>,
        vouchers: (basketAdjustments as unknown) as Array<string>,
        ...rest,
      };
    },
  },
);

export default (view: BasketResponse) => {
  const { result, entities } = normalize(view, basketSchema);

  return r.pipe(
    r.over(r.lensProp('basket'), r.prop(result)),
    r.mergeDeepRight({
      vouchers: {},
      basketItems: {},
      products: {},
    }),
  )(entities);
};
