import { reducer as form } from 'redux-form';
import { reducer as pageLoader, REDUCER_MOUNT_POINT as PAGE_LOADER_MP } from '@team-griffin/redux-page-loader';
import { reducer as modal, REDUCER_MOUNT_POINT as MODAL_MP } from '@team-griffin/redux-modal-router';
import { reducer as auth, REDUCER_MOUNT_POINT as AUTH_MP, State as Auth } from '@ux/auth/idp';
import { reducer as whitelabel, REDUCER_MOUNT_POINT as WL_MP, State as WhiteLabel } from '@ux/whitelabel';
import { reducer as reactiveKnowledge } from '@ux/reactive-knowledge';
import { reducer as language, REDUCER_MOUNT_POINT as LNG_MP, State as Language } from '@ux/language';
import { reducer as currency, REDUCER_MOUNT_POINT as CUR_MP, State as Currency } from '@ux/currency';
import { reducer as accountsClient, REDUCER_MOUNT_POINT as ACP_MP, State as AccountsClient } from '@ux/accounts-client';
import { reducer as upm, REDUCER_MOUNT_POINT as UPM_MP } from '@pex/upm';

interface UpmState {
  ribbon: {
    dismissed: boolean;
  };
  config: {};
}

export interface State {
  form: any;
  pageLoader: any;
  modal: any;
  intlLoader: any;
  reactiveKnowledge: any;
  auth: Auth;
  whitelabel: WhiteLabel;
  language: Language;
  currency: Currency;
  accountsClient: AccountsClient;
  upm: UpmState;
}

export default {
  form,
  [PAGE_LOADER_MP]: pageLoader,
  [AUTH_MP]: auth,
  [MODAL_MP]: modal,
  [WL_MP]: whitelabel,
  [LNG_MP]: language,
  [CUR_MP]: currency,
  [ACP_MP]: accountsClient,
  reactiveKnowledge,
  [UPM_MP]: upm,
};
