import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import ux, { State as Ux } from './ux';
import uop, { State as Uop } from './uop';

export interface State extends Ux {
  commands: any;
  router: RouterState;
  uop: Uop;
}

export default (history: History) =>
  combineReducers({
    ...ux,
    router: connectRouter(history),
    uop,
  });
