import { createAction } from 'redux-actions';

// Action Types
const prefix = '@@uop/customer/S_';
export const FETCH = `${prefix}FETCH`;

// Action Creators
export const fetch = createAction(FETCH);

// Action Payloads
export type Fetch = ReturnType<typeof fetch>;
