import { createAction } from 'redux-actions';

import { ProductId, BasketItemId, View, DomainType } from '../../core/basket';

const prefix = '@@uop/basket/M_';
// -- Fetch
export const FETCHING = `${prefix}FETCHING`;
export const PREFETCHED = `${prefix}PREFETCHED`;
export const FETCHED = `${prefix}FETCHED`;
export const FETCH_FAILED = `${prefix}FETCH_FAILED`;
export const FETCH_EMPTY = `${prefix}FETCH_EMPTY`;
// -- Add
export const ADDING = `${prefix}ADDING`;
export const ADDED = `${prefix}ADDED`;
export const ADD_FAILURE = `${prefix}ADD_FAILURE`;
// -- Remove
export const REMOVING = `${prefix}REMOVING`;
export const REMOVED = `${prefix}REMOVED`;
export const REMOVE_FAILURE = `${prefix}REMOVE_FAILURE`;
// Add Gab
export const ADDING_GAB = `${prefix}ADDING_GAB`;
export const ADDED_GAB = `${prefix}ADDED_GAB`;
export const ADD_GAB_FAILURE = `${prefix}ADD_GAB_FAILURE`;
// Remove Gab
export const REMOVING_GAB = `${prefix}REMOVING_GAB`;
export const REMOVED_GAB = `${prefix}REMOVED_GAB`;
export const REMOVE_GAB_FAILURE = `${prefix}REMOVE_GAB_FAILURE`;
// -- Terms
export const TERM_CHANGING = `${prefix}TERM_CHANGING`;
export const TERM_CHANGED = `${prefix}TERMS_CHANGED`;
export const TERM_FAILURE = `${prefix}TERM_FAILURE`;

export const ASSIGNED = `${prefix}ASSIGNED`;

// -- Fetch
export const fetching = createAction(FETCHING);
export const prefetched = createAction<View>(PREFETCHED);
export const fetched = createAction<View>(FETCHED);
export const fetchFailed = createAction(FETCH_FAILED);
export const fetchEmpty = createAction(FETCH_EMPTY);
// -- Add
export const adding = createAction<{ productId: ProductId }>(ADDING);
export const added = createAction<{ productId: ProductId, term?: number; paymentInterval?: number; }>(ADDED);
export const addFailure = createAction(
  ADD_FAILURE,
  (err: any) => err,
  (err: any, productId: ProductId) => ({ productId }),
);

// -- Remove
export const removing = createAction<{ basketItemId: BasketItemId }>(REMOVING);
export const removed = createAction<{ basketItemId: BasketItemId }>(REMOVED);
export const removeFailure = createAction(
  REMOVE_FAILURE,
  (err: any) => err,
  (err: any, basketItemId: BasketItemId) => ({ basketItemId }),
);
// -- Add Gab
export const addingGab = createAction<{
  productId: number;
  basketItemIds: BasketItemId[];
}>(ADDING_GAB);
export const addedGab = createAction<{
  productId: number;
  basketItemIds: BasketItemId[];
}>(ADDED_GAB);
export const addGabFailure = createAction<{
  productId: number;
  basketItemIds: BasketItemId[];
}>(ADD_GAB_FAILURE);
// -- Remove Gab
export const removingGab = createAction<{
  productId: number;
  basketItemIds: BasketItemId[];
}>(REMOVING_GAB);
export const removedGab = createAction<{
  productId: number;
  basketItemIds: BasketItemId[];
}>(REMOVED_GAB);
export const removeGabFailure = createAction(
  REMOVE_GAB_FAILURE,
  (err: any) => err,
  (err: any, productId: number, basketItemIds: BasketItemId[]) => ({
    productId,
    basketItemIds,
  }),
);
// -- Term
export const termChanging = createAction<
  | {
      skuId: number;
      basketItemId: BasketItemId;
      term: number;
      paymentInterval: number;
      quantity?: number;
    }
  | {
      domainName: string;
      type: DomainType;
      basketItemId: BasketItemId;
      term: number;
      paymentInterval: number;
      quantity?: number;
    }
>(TERM_CHANGING);
export const termChanged = createAction<
  | {
      skuId: number;
      basketItemId: BasketItemId;
      term: number;
      paymentInterval: number;
      quantity?: number;
    }
  | {
      domainName: string;
      type: DomainType;
      basketItemId: BasketItemId;
      term: number;
      paymentInterval: number;
      quantity?: number;
    }
>(TERM_CHANGED);
export const termFailure = createAction(
  TERM_FAILURE,
  (err: any) => err,
  (err: any, args: any) => args,
);

export const assigned = createAction(ASSIGNED);

// -- Fetch
export type Fetching = ReturnType<typeof fetching>;
export type Prefetched = ReturnType<typeof prefetched>;
export type Fetched = ReturnType<typeof fetched>;
export type FetchFailed = ReturnType<typeof fetchFailed>;
export type FetchEmpty = ReturnType<typeof fetchEmpty>;
// -- Add
export type Adding = ReturnType<typeof adding>;
export type Added = ReturnType<typeof added>;
export type AddFailure = ReturnType<typeof addFailure>;
// -- Remove
export type Removing = ReturnType<typeof removing>;
export type Removed = ReturnType<typeof removed>;
export type RemoveFailure = ReturnType<typeof removeFailure>;
// -- Add Gab
export type AddingGab = ReturnType<typeof addingGab>;
export type AddedGab = ReturnType<typeof addedGab>;
export type AddGabFailure = ReturnType<typeof addGabFailure>;
// -- Remove Gab
export type RemovingGab = ReturnType<typeof removingGab>;
export type RemovedGab = ReturnType<typeof removedGab>;
export type RemoveGabFailure = ReturnType<typeof removeGabFailure>;
// -- Term
export type TermChanging = ReturnType<typeof termChanging>;
export type TermChanged = ReturnType<typeof termChanged>;
export type TermFailure = ReturnType<typeof termFailure>;

export type Assigned = ReturnType<typeof assigned>;
