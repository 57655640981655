import { createAction } from 'redux-actions';
import { OrderResponse } from 'core/basket';
import { PaymentMethods } from 'domain/constants/payment';

// Action Types
const prefix = '@@uop/payment/S_';
export const FETCH_TOKENS = `${prefix}FETCH_TOKENS`;
export const FETCH_PROVIDER = `${prefix}FETCH_PROVIDER`;
export const VALIDATE = `${prefix}VALIDATE`;
export const SUBMIT = `${prefix}SUBMIT`;
export const REDIRECT = `${prefix}REDIRECT`;
export const INLINE_TRANSACTION = `${prefix}INLINE_TRANSACTION`;
export const SECURE_TRANSACTION = `${prefix}SECURE_TRANSATION`;
export const COMPLETE_TRANSACTION = `${prefix}COMPLETE_TRANSACTION`;

// Action Creators
export const fetchTokens = createAction(FETCH_TOKENS);
export const fetchProvider = createAction<{ method: PaymentMethods; identifier?: boolean }>(FETCH_PROVIDER);

export const validate = createAction(
  VALIDATE,
  ({ values }: { values: object }) => ({ values }),
  ({
    resolve,
    reject,
  }): {
    resolve: Function;
    reject: Function;
  } => ({
    resolve,
    reject,
  }),
);
export const submit = createAction<{ method: PaymentMethods }>(SUBMIT);
export const redirect = createAction<{ method: PaymentMethods; providerId?: string; transactionId?: string }>(REDIRECT);
export const inlineTransaction = createAction<{
  tokenId: string;
  type: string;
}>(INLINE_TRANSACTION);
export const secureTransaction = createAction<{ orderResponse: OrderResponse }>(SECURE_TRANSACTION);
export const completeTransaction = createAction<{ orderId: string }>(COMPLETE_TRANSACTION);

// Action Payloads
export type FetchTokens = ReturnType<typeof fetchTokens>;
export type FetchProvider = ReturnType<typeof fetchProvider>;
export type Validate = ReturnType<typeof validate>;
export type Submit = ReturnType<typeof submit>;
export type Redirect = ReturnType<typeof redirect>;
export type InlineTransaction = ReturnType<typeof inlineTransaction>;
export type SecureTransaction = ReturnType<typeof secureTransaction>;
export type CompleteTransaction = ReturnType<typeof completeTransaction>;
