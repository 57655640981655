import * as yup from 'yup';
import * as r from 'ramda';
import { EligibleFieldsByRegistrantType } from 'domain/selectors/details/registrarUkUtils';

import * as domain from './domain';
import getBasicSchema from '../basic';

const hasFieldset = (fieldSet: string) => r.pipe(r.defaultTo([]), r.contains(fieldSet));

export const fieldSchemas = { domain, basic: getBasicSchema('domain.basic.') };

// the data values contain a fieldSets array which is a list of
// groups that must be present in the values list
// so if fieldSets = [ 'domain.group.uk' ] we add the uk schema to the
// product schema

const productSchema = (eligibleFieldsByRegistrantType: EligibleFieldsByRegistrantType, shouldUseAccount: boolean) =>
  yup.object().shape({
    domain: yup.object().shape({
      basic: shouldUseAccount
        ? yup.object()
        : yup.object().when('$fieldSets', {
            is: hasFieldset('domain.basic'),
            then: fieldSchemas.basic,
            otherwise: yup.object(),
          }),
      group: yup.object().shape({
        uk: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.uk'),
          then: yup.object().shape(fieldSchemas.domain.group.uk(eligibleFieldsByRegistrantType)),
          otherwise: yup.object(),
        }),
        us: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.us'),
          then: yup.object().shape(fieldSchemas.domain.group.us),
          otherwise: yup.object(),
        }),
        es: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.es'),
          then: yup.object().shape(fieldSchemas.domain.group.es),
          otherwise: yup.object(),
        }),
        nl: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.nl'),
          then: yup.object().shape(fieldSchemas.domain.group.nl),
          otherwise: yup.object(),
        }),
        afnic: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.afnic'),
          then: yup.object().shape(fieldSchemas.domain.group.afnic),
          otherwise: yup.object(),
        }),
        de: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.de'),
          then: yup.object().shape(fieldSchemas.domain.group.de),
          otherwise: yup.object(),
        }),
        it: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.it'),
          then: yup.object().shape(fieldSchemas.domain.group.it),
          otherwise: yup.object(),
        }),
        lv: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.lv'),
          then: yup.object().shape(fieldSchemas.domain.group.lv),
          otherwise: yup.object(),
        }),
        im: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.im'),
          then: yup.object().shape(fieldSchemas.domain.group.im),
          otherwise: yup.object(),
        }),
        cat: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.cat'),
          then: yup.object().shape(fieldSchemas.domain.group.cat),
          otherwise: yup.object(),
        }),
        scot: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.scot'),
          then: yup.object().shape(fieldSchemas.domain.group.scot),
          otherwise: yup.object(),
        }),
        eu: yup.object().when('$fieldSets', {
          is: hasFieldset('domain.group.eu'),
          then: yup.object().shape(fieldSchemas.domain.group.eu),
          otherwise: yup.object(),
        }),
      }),
      transfer: yup.object().when('$fieldSets', {
        is: hasFieldset('domain.transfer'),
        then: yup.object().shape(fieldSchemas.domain.transfer),
        otherwise: yup.object(),
      }),
    }),
  });

export default productSchema;
