import { createEpic } from '@ux/fabric';
import { push } from 'connected-react-router';
import { signals as rkSignals } from '@ux/reactive-knowledge';
import { combineEpics } from 'redux-most';
import { setSubmitFailed } from 'redux-form';
import { getModifiedDomainDataForPartialProfile } from 'domain/selectors/details/registrarUk';
import { isAccountFormSectionPristine, getAccountFormValues, getMappedAccountData } from 'domain/selectors/customer';
import { hasFieldSets } from 'domain/selectors/details';
import { UpdateAccountDetails } from 'core/customer';

import { SaveDetails } from '../../../core/details';
import { fetch as fetchCustomer } from '../../signals/customer';
import { SUBMIT } from '../../../application/signals/details';
import {
  SUBMIT_FAILURE,
  SUBMIT_SUCCESS,
  submitFailure,
  submitSuccess,
  SubmitFailure,
} from '../../../domain/messages/details';
import { getNextUrl } from '../../../domain/selectors/common';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../../../application/epics/utils';

export const submitEpic = (saveDetails: SaveDetails, updateAccountDetails: UpdateAccountDetails) =>
  createEpic({
    signal: SUBMIT,
    process: async (action, state) => {
      const submitAccountDetails = !isAccountFormSectionPristine(state);
      const accountDetailsFormValues = getAccountFormValues(state);
      const account = getMappedAccountData(accountDetailsFormValues)(state);

      if (submitAccountDetails) {
        await updateAccountDetails(account);
      }
      if (hasFieldSets(state)) {
        return saveDetails({
          values: getModifiedDomainDataForPartialProfile(state),
        });
      }
      return Promise.resolve();
    },
    onSuccess: submitSuccess,
    onFailure: handleEsocketError(handleAuth(submitFailure)),
  });

export const successEpic = createEpic({
  signal: SUBMIT_SUCCESS,
  selector: (state: any) => ({
    url: getNextUrl(state),
  }),
  onSuccess: (data, action, state) => {
    const { url } = state;
    return [push(url), fetchCustomer()];
  },
});

export const failureEpic = createEpic({
  signal: SUBMIT_FAILURE,
  onSuccess: (action: SubmitFailure) => [
    rkSignals.add({
      type: 'ERK',
      message: serverErrorToIntlId('uop.details.submit.failed')(action.payload),
    }),
    setSubmitFailed('uop.requiredInformation'),
  ],
});

export default (saveDetails: SaveDetails, updateAccountDetails: UpdateAccountDetails) =>
  combineEpics([submitEpic(saveDetails, updateAccountDetails), successEpic, failureEpic]);
