import { createEpic } from '@ux/fabric';
import { selectors as authSelectors } from '@ux/auth/idp';

import { FETCH } from '../../signals/account-credits';
import { fetchFailed, fetched, fetching } from '../../../domain/messages/account-credits';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';
import { FetchAccountCredits } from '../../../core/account-credits';

const fetchEpic = (fetchAccountCredits: FetchAccountCredits) =>
  createEpic({
    signal: FETCH,
    filter: (action, state) => authSelectors.isAuthenticated(state),
    pending: () => fetching(),
    process: () => fetchAccountCredits(),
    // @ts-ignore
    onSuccess: (data) => fetched({ credits: data }),
    onFailure: handleEsocketError(
      handleAuth((err) => fetchFailed(serverErrorToIntlId('uop.accountCredits.load.failed')(err))),
    ),
  });

export default fetchEpic;
