import React, { useState } from 'react';
import { useTheme } from '@ux/theme-utils';
import { ModalRoute } from '@team-griffin/redux-modal-router';
import { Modal } from '@ux/modal';
import { PrivacyRibbon } from '@pex/upm';
import { useSelector } from 'react-redux';
import { getTealiumCategories, getPrivacyPolicyUrl, getCookiesToPreserve } from 'domain/selectors/upm';
import { getMarket } from 'domain/selectors/details';

import AnimationContext from '../contexts/animation';
import { Screen } from '../../core/common';
import FieldExplanationModal from './requiredInformation/FieldExplanationModal';
import Core from './Core';
export interface OuterProps {
  routes: { [key: string]: string };
  screens: Screen[];
  firstUrl: string;
  homeUrl: string;
  showAuthPage: boolean;
}

const App = ({ ...props }: OuterProps) => {
  const [animations, setAnimations] = useState({});
  const animationValue: any = [
    animations,
    (key: string) => {
      // @ts-ignore
      animations[key] = true;
      setAnimations({ ...animations });
    },
  ];
  const { theme } = useTheme();
  return (
    <AnimationContext.Provider value={animationValue}>
      <Core {...props} />
      <PrivacyRibbon
        theme={theme}
        categories={useSelector(getTealiumCategories)}
        privacyPolicyUrl={useSelector(getPrivacyPolicyUrl)}
        cookiesToPreserve={getCookiesToPreserve()}
        market={getMarket}
      />
      <ModalRoute
        path="FIELD_EXPLANATION"
        render={() => (
          <Modal isOpen={true}>
            <FieldExplanationModal />
          </Modal>
        )}
      />
    </AnimationContext.Provider>
  );
};

App.displayName = 'App';

export default App;
