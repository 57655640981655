import decode from 'jwt-decode';
import { getCookie } from '@uds/cookies';
import { createSelector as quickSelector } from '@ux/fabric';
import { selectors as whitelabelSelectors } from '@ux/whitelabel';
import { selectors as prodSelectors } from '@ux/product';
import { selectors as currencySelectors } from '@ux/currency';
import { BASKET_COOKIE } from 'domain/constants/basket';
import { State as RootState } from 'domain/reducers';

export const getCurrency = currencySelectors.getCurrency;

export const includeTax = prodSelectors.shouldIncludeTax;

export const getUrl = whitelabelSelectors.getUrl;

export const getBasketId = () => {
  const basket = getCookie(BASKET_COOKIE);
  if (basket == null) {
    return null;
  }

  try {
    return decode<{ basketId: string }>(basket)?.basketId;
  } catch {
    return basket;
  }
};

export const getLanguageCookie = () => {
  return getCookie('ux-language-preferred');
};

export const getAds = quickSelector([whitelabelSelectors.getAppConfig('uop')], (config) => {
  return config?.ads ?? [];
});

export const getFollowUps = quickSelector([whitelabelSelectors.getAppConfig('uop')], (config) => {
  return config?.followUps ?? {};
});

type GetStateCopy = () => RootState;
const createGetStateCopy = (window: Window): GetStateCopy => () => {
  return JSON.parse(window.sessionStorage.getItem('post-transaction-state'));
};
const getStateCopy = createGetStateCopy(window);

export const getBasketKeywords = quickSelector([getStateCopy], (state) => {
  const basketItems = state?.uop?.basket?.basketItems;

  if (basketItems == null) {
    return [];
  }

  const items = Object.values(basketItems);

  const allKeywords = items.reduce((acc, item) => {
    const keywords = item.productKeywords;

    if (keywords != null) {
      return [...acc, keywords];
    }

    return acc;
  }, []);

  return Array.from(new Set(allKeywords));
});
