import { createAction } from 'redux-actions';

// Action Types
const prefix = '@@uop/login/M_';
export const IFRAME_LOADED = `${prefix}IFRAME_LOADED`;
export const IFRAME_LOAD_FAILED = `${prefix}IFRAME_LOAD_FAILED`;
export const LOGGED_IN = `${prefix}LOGGED_IN`;

// Action Creators
export const iframeLoaded = createAction(IFRAME_LOADED);
export const iframeLoadFailed = createAction(IFRAME_LOAD_FAILED);
export const loggedIn = createAction(LOGGED_IN);

// Action Payloads
export type IframeLoaded = ReturnType<typeof iframeLoaded>;
export type IframeLoadFailed = ReturnType<typeof iframeLoadFailed>;
export type LoggedIn = ReturnType<typeof loggedIn>;
