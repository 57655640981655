import { initialize, getFormValues } from 'redux-form';
import { combineEpics } from 'redux-most';
import { replace } from 'connected-react-router';
import { createEpic } from '@ux/fabric';
import { selectors as authSelectors } from '@ux/auth/idp';
import { FETCH } from 'application/signals/customer';
import { fetchFailed, fetched, fetching, FETCHED } from 'domain/messages/customer';
import { State as RootState } from 'domain/reducers';
import { FetchCustomer } from 'core/customer';
import { getIsPartialProfile } from 'domain/selectors/customer';
import { getScreenUrl, getCurrentScreen } from 'domain/selectors/common';
import { AccountType } from 'domain/constants/customer';

import { handleAuth, handleEsocketError } from '../utils';

const fetchCustomerEpic = (fetchCustomer: FetchCustomer) =>
  createEpic({
    signal: FETCH,
    filter: (action, state) => authSelectors.isAuthenticated(state),
    pending: () => fetching(),
    process: () => fetchCustomer(),
    onSuccess: (data) => fetched({ customer: data }),
    onFailure: handleEsocketError(handleAuth(fetchFailed)),
  });

const initFormEpic = createEpic({
  signal: FETCHED,
  onSuccess: (_, action, state: RootState) => {
    const customer = action.payload.customer ?? {};
    const formValues = getFormValues('uop.requiredInformation')(state);
    const isPartialProfile = getIsPartialProfile(state);

    const values = {
      accountDetails: {
        forename: customer.firstName,
        lastname: customer.lastName,
        telephone: customer.telephone,
        address: {
          line1: customer.address.address1,
          line2: customer.address.address2,
          city: customer.address.city,
          state: customer.address.region,
          postcode: customer.address.postcode,
          countryCode: customer.address.country,
        },
        email: customer.email,
        companyName: customer.companyName,
        companyNumber: customer.companyNumber,
        companyTaxNumber: customer.companyTaxNumber,
      },
      isCompany: customer.type === AccountType.COMPANY,
      isPartialProfile: isPartialProfile,
    };

    return initialize(
      'uop.requiredInformation',
      {
        ...formValues,
        customer: values,
        // eslint-disable-next-line camelcase
        __invalid_account_details__: false,
      },
      { keepDirty: false },
    );
  },
});

export const skipEpic = createEpic({
  signal: FETCHED,
  filter: (action, state: any) => {
    // customer doesn't have a full profile and
    // we are about to enter the checkout payment page
    const isPartialProfile = getIsPartialProfile(state);
    const currentScreen = getCurrentScreen(state);

    if (currentScreen?.key === 'payment' && isPartialProfile) {
      return true;
    }

    return false;
  },
  // ...go to the details page
  onSuccess: (data, action, state) => {
    const detailsUrl = getScreenUrl('details')(state);
    return replace(detailsUrl);
  },
});

export default (fetchCustomer: FetchCustomer) =>
  combineEpics([fetchCustomerEpic(fetchCustomer), initFormEpic, skipEpic]);
