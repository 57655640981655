import { Http } from '@ux/drivers';
import { FetchPaymentTokens } from 'core/payment';
import normalize from 'domain/transformers/payment-tokens';
import { getBasketId } from 'domain/selectors/common';

const fetchTokens = (json: Http): FetchPaymentTokens => ({ excludePaypal }) => {
  const request = {
    url: `/api/app/v3/queries/payment/tokens/my`,
    data: {
      basketId: getBasketId(),
      excludePaypal,
    },
  };

  return json(request).then(({ data }) => {
    return normalize(data);
  });
};

export default fetchTokens;
