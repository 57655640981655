import * as most from 'most';
import * as mA from 'most-adjunct';
import * as mostc from 'mostc';
import { select, combineEpics } from 'redux-most';
import * as r from 'ramda';
import * as rA from 'ramda-adjunct';

import { Event } from '../../../domain/constants/events';
import { REMOVE as REMOVE_FIELD, Remove as RemoveField, fetch } from '../../../application/signals/details';
import {
  remove as removeBasketItem,
  Remove as RemoveBasketItem,
  Fetch as FetchBasket,
} from '../../../application/signals/basket';
import { REMOVED as REMOVED_BASKET_ITEM } from '../../../domain/messages/basket';
import { getProductsByField } from '../../../domain/selectors/details';
import { mapEvent } from '../../../domain/transformers/events';
import { State as RootState } from '../../../domain/reducers';

// TODO: use createEpic
export const removeEpic = (
  actions$: most.Stream<RemoveField | RemoveBasketItem>,
  store: {
    getState: () => RootState;
  },
) =>
  r.pipe(
    select(REMOVE_FIELD),
    mA.switchMap((action: RemoveField) => {
      const state = store.getState();
      const {
        payload: { field, productId },
      } = action;

      const actions = r.pipe(
        r.ifElse(r.always(rA.isNilOrEmpty(productId)), getProductsByField(field), r.always([productId])),
        r.map(removeBasketItem),
      )(state);

      return most.from(actions);
    }),
  )(actions$);

// TODO: use createEpic
export const removeSuccessEpic = (dispatch: Function) => (
  actions$: most.Stream<RemoveField | FetchBasket>,
  store: {
    getState: () => RootState;
  },
) =>
  r.pipe(
    select(REMOVE_FIELD),
    mA.switchMap((action: RemoveField) => {
      const state = store.getState();
      const {
        payload: { field },
      } = action;

      const ids = getProductsByField(field)(state);

      const streams = r.map((id) => {
        return r.pipe(
          select(REMOVED_BASKET_ITEM),
          mostc.filter(r.pathEq(['payload', 'basketItemId'], id)),
          mostc.map(r.T),
        )(actions$);
      }, ids);

      return most.combineArray(() => {
        dispatch(
          mapEvent({
            event: Event.FIELD_HELP_REMOVE,
            field,
          }),
        );
        return fetch();
      }, streams);
    }),
  )(actions$);

export default (dispatch: Function) =>
  combineEpics([
    // @ts-ignore
    removeEpic,
    // @ts-ignore
    removeSuccessEpic(dispatch),
  ]);
