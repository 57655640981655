import { Product } from '@ux/product';
import { ProductId } from 'core/basket';
import { UpsellProduct as Upsell } from 'core/upsells/index';

export const getProduct = (id: ProductId) => (state: any) => {
  return state.uop.products?.products[id] as Product;
};

export const getUpsellProduct = (id: ProductId) => (state: any) => {
  return state.uop.products?.upsells[id] as Upsell;
};

export const getSkuId = (id: ProductId) => (state: any) => {
  const productId = getProduct(id)(state);
  return productId?.defaultSkuId;
};

export const getUpsellSkuId = (id: ProductId) => (state: any) => {
  const productId = getUpsellProduct(id)(state);
  return productId?.skuId;
};
