import React from 'react';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import { useStyles } from '@team-griffin/stylr';
import { withProps } from '@team-griffin/rehook';
import { useTheme, gu2px, Theme } from '@ux/theme-utils';
import { WubLoader } from '@ux/penders';

interface OuterProps {}

const useStylesheet = (props: { theme: Theme }) => {
  const {
    theme: { palette },
  } = props;

  return useStyles({
    default: {
      root: {
        backgroundColor: palette.grey12,
        flex: 1,
        position: 'relative',
        minHeight: 100,
      },
      loader: {
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  });
};

const enhance = puree<OuterProps>('Pending')(withProps(useTheme), withProps(useStylesheet));

type InnerProps = GetInnerProps<typeof enhance>;

const PurePending = ({ styles, theme }: InnerProps) => (
  <div style={styles.root}>
    <div style={styles.loader}>
      <WubLoader theme={theme} color={theme.palette.primary} size={gu2px(6, theme.spacing)} />
    </div>
  </div>
);

export default enhance(PurePending);
