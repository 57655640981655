import { createReducer } from 'redux-create-reducer';

import { FETCHED, FETCHING, Fetched, FETCH_FAILED, FetchFailed } from '../../messages/requiredValues';
import { RequiredValues } from '../../../core/details';

export interface State {
  fetching: boolean;
  fetched: boolean;
  error: any;
  requiredValues: RequiredValues;
}

const initialState: State = {
  fetching: false,
  fetched: false,
  error: null,
  requiredValues: null as RequiredValues,
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: (state) => {
    return {
      ...state,
      fetching: true,
    };
  },
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: false,
      fetched: true,
      error: null,
      ...action.payload,
    };
  },
  [FETCH_FAILED]: (state, action: FetchFailed) => {
    return {
      ...state,
      fetching: false,
      fetched: false,
      error: action.payload,
    };
  },
});
