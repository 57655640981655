import { Indexer } from '@uxdev/types';
import { createReducer } from 'redux-create-reducer';
import { Product } from '@ux/product';

import { FETCHED as BASKET_FETCHED, Fetched as BasketFetched } from '../../messages/basket';
import { FETCHED as CROSS_SELLS_FETCHED, Fetched as CrossSellsFetched } from '../../messages/cross-sells';
import { FETCHED as BOLT_ONS_FETCHED, Fetched as BoltOnsFetched } from '../../messages/bolt-ons';
import { FETCHED as UP_SELLS_FETCHED, Fetched as UpSellsFetched } from '../../messages/upsells';

export interface State {
  products: Indexer<{}, Product>;
  upsells: Indexer<{}, Product>;
}

const initialState: State = {
  products: {},
  upsells: {},
};

export default createReducer<State, any>(initialState, {
  [BASKET_FETCHED]: (state, action: BasketFetched) => {
    return {
      ...state,
      products: {
        ...action.payload.products,
        ...state.products,
      },
    };
  },
  [CROSS_SELLS_FETCHED]: (state, action: CrossSellsFetched) => {
    return {
      ...state,
      products: {
        ...state.products,
        ...action.payload.products,
      },
    };
  },
  [BOLT_ONS_FETCHED]: (state, action: BoltOnsFetched) => {
    return {
      ...state,
      products: {
        ...state.products,
        ...action.payload.products,
      },
    };
  },
  [UP_SELLS_FETCHED]: (state, action: UpSellsFetched) => {
    return {
      ...state,
      upsells: {
        ...state.upsells,
        ...action.payload.products,
      },
    };
  },
});
