import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { mush, GetInnerProps, withHooks } from '@ux/pure-enhance';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import AsyncComponent from '@ux/async-component';
import { withProps, tap } from '@team-griffin/rehook';
import useEnsureStartUrl from 'presentation/hooks/useEnsureStartUrl';

import Pending from './Pending';
import { isLoginScreen } from '../../../domain/selectors/pages/login';

const importLogin = () =>
  import(
    /* webpackChunkName: 'offers' */
    '../login'
  );

const enhance = mush('LoginPage')(
  withHooks(
    withProps(() => {
      return {
        isLoginScreen: useSelector(isLoginScreen),
      };
    }),
    tap(() => useEnsureStartUrl()),
  ),
);
type InnerProps = GetInnerProps<typeof enhance>;

export const PureLoginPage = ({ isLoginScreen }: InnerProps) => {
  const intl = useIntl();

  return (
    <Fragment>
      <If condition={isLoginScreen}>
        <Helmet>
          <title>
            {intl.formatMessage(
              {
                id: 'brand.metaTitle.template.structure',
              },
              {
                page: intl.formatMessage({
                  id: 'uop.metaTitle.login',
                }),
                brand: intl.formatMessage({
                  id: 'brand.metaTitle.template.brand',
                }),
              },
            )}
          </title>
        </Helmet>
      </If>
      <AsyncComponent Pending={Pending} component={importLogin} />
    </Fragment>
  );
};

export default enhance(PureLoginPage);
