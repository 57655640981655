import { createSelector } from 'reselect';
import { Status } from 'domain/constants/details';

import { hasFetched as getHasFetchedBasket, hasFetchFailed as getHasFetchBasketFailed } from '../basket';
import {
  hasFetchedFields as getHasFetchedFields,
  hasFetchFieldsFailed as getHasFetchFieldsFailed,
  hasFetchedValues as getHasFetchedValues,
  hasFetchValuesFailed as getHasFetchValuesFailed,
  hasFieldSets as getHasFieldSets,
} from '../details';
import { getIsPartialProfile, hasFetched as getHasFetchedCustomer } from '../customer';

export const getStatus = createSelector(
  [
    getHasFetchedBasket,
    getHasFetchBasketFailed,
    getHasFetchedFields,
    getHasFetchFieldsFailed,
    getHasFetchedValues,
    getHasFetchValuesFailed,
    getHasFieldSets,
    getIsPartialProfile,
    getHasFetchedCustomer,
  ],
  (
    hasFetchedBasket,
    hasFetchBasketFailed,
    hasFetchedFields,
    hasFetchFieldsFailed,
    hasFetchedValues,
    hasFetchValuesFailed,
    hasFieldSets,
    isPartialProfile,
    hasFetchedCustomer,
  ) => {
    if (hasFetchBasketFailed || hasFetchFieldsFailed || hasFetchValuesFailed) {
      return Status.FAILED;
    }
    if (hasFetchedBasket && hasFetchedFields && hasFetchedCustomer) {
      // customer has partial profile
      if (isPartialProfile) {
        if (!hasFieldSets) {
          return Status.READY;
        }
        if (hasFetchedValues) {
          return Status.READY;
        }
      }
      // customer has full profile
      if (!hasFieldSets) {
        return Status.NONE_REQUIRED;
      }
      if (hasFetchedValues) {
        return Status.READY;
      }
    }
    return Status.REQUESTING;
  },
);

export const showPage = createSelector([getStatus], (state) => state !== Status.REQUESTING);
