import { createEpic, createStructuredSelector } from '@ux/fabric';
import { FetchAds, Ad } from 'core/ads';
import { FETCHED, Fetched } from 'domain/messages/confirmation';
import { getBasketKeywords, getAds } from 'domain/selectors/common';
import { fetchFailed, fetched, fetching } from 'domain/messages/ads';

import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';

const selector = createStructuredSelector({
  basketKeywords: getBasketKeywords,
  ads: getAds,
});

const fetchEpic = (fetch: FetchAds) =>
  createEpic({
    signal: FETCHED,
    selector,
    pending: fetching,
    process: (action: Fetched, { ads, basketKeywords }) => fetch({ ads, basketKeywords }),
    onSuccess: (data: Array<Ad>) => fetched(data),
    onFailure: handleEsocketError(
      handleAuth((err) => {
        return fetchFailed(serverErrorToIntlId('uop.ads.load.failed')(err));
      }),
    ),
  });

export default fetchEpic;
