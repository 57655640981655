import { selectors as auth } from '@ux/auth/idp';
import { createSelector } from 'reselect';

import { Status } from '../../constants/basket';
import * as domainUrgency from '../domainUrgency';
import * as vouchers from '../vouchers';
import * as basket from '../basket';
import * as accountCredits from '../account-credits';

export const showDomainUrgency = domainUrgency.showDomainUrgency('basket');

export const showVoucherInput = vouchers.showVoucherInput('basket');

export const getBasketItemIds = createSelector([basket.getBasketItems], (basketItems) => {
  if (basketItems == null) {
    return [];
  }

  return basketItems.reduce((acc, item) => {
    if (item.boltOnReferencedItemId == null) {
      return [...acc, item.id];
    }

    return acc;
  }, []);
});

export const getStatus = createSelector(
  [basket.isFetching, basket.hasPrefetched, accountCredits.hasFetched, basket.hasFetchFailed, auth.isAuthenticated],
  (fetching, basket, credits, basketFailed, authenticated) => {
    if (basketFailed) {
      if (fetching) {
        return Status.REQUESTING;
      }
      return Status.FAILED;
    }
    if (basket) {
      // credits are required but only if you're logged in
      if (credits || !authenticated) {
        return Status.READY;
      }
    }
    return Status.REQUESTING;
  },
);

export const showPage = createSelector([getStatus], (state) => state !== Status.REQUESTING);
