import { createSelector as quickSelector } from '@ux/fabric';
import { ProductStatus } from 'domain/constants/product';

import { getPendingStates as getBasketPendingStates, isFetching as getIsBasketFetching } from '../basket';
import { getPendingStates as getBoltOnsPendingStates } from '../bolt-ons';
import { getPendingStates as getCrossSellsPendingStates } from '../cross-sells';
import { getPendingStates as getVouchersPendingStates } from '../vouchers';
import { getPendingStates as getUpsellsPendingStates } from '../upsells';

export const getIsBasketUpdating = quickSelector(
  [
    getBasketPendingStates,
    getBoltOnsPendingStates,
    getCrossSellsPendingStates,
    getUpsellsPendingStates,
    getVouchersPendingStates,
    getIsBasketFetching,
  ],
  (
    basketPendingStates,
    boltOnsPendingStates,
    crossSellsPendingStates,
    upsellsPendingStates,
    vouchersPendingStates,
    isBasketFetching,
  ) => {
    const statuses = [ProductStatus.ADDING, ProductStatus.REMOVING, ProductStatus.UPDATING];

    const allPendingStatuses = [
      ...Object.values(basketPendingStates ?? {}),
      ...Object.values(boltOnsPendingStates ?? {}),
      ...Object.values(crossSellsPendingStates ?? {}),
      ...Object.values(upsellsPendingStates ?? {}),
      ...Object.values(vouchersPendingStates ?? {}),
    ];
    if (isBasketFetching) {
      return true;
    }
    if (statuses.some((status) => allPendingStatuses.includes(status))) {
      return true;
    }

    return false;
  },
);
