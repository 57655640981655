import { selectors } from '@ux/product';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { getLanguage } from 'domain/selectors/routes';
import { getEnRoute } from 'domain/selectors/routes/utils';
import { getBasket, getBasketItems, getCurrency } from 'domain/selectors/basket';
import { getCrossSells } from 'domain/selectors/cross-sells';
import { View } from 'core/events';
import { BasketItem } from 'core/basket';
import { dispatchGA4Event } from 'infrastructure/ga4';

const { getPromotionalPrice, getRetailPrice, shouldIncludeTax } = selectors;

export enum PageType {
  DETAILS = 'Details',
  CROSSSELLS = 'Offers',
  BASKET = 'Basket',
  LOGIN = 'Login',
  PAYMENT = 'Payment',
  COMPLETE = 'Checkout',
}

const defaultData = {
  'page.financeCategory': '',
  'page.productCategory': '',
  'page.productSubCategory': '',
  'page.siteSection': 'Order Process',
};

const pageData = (page: PageType) => {
  switch (page) {
    case PageType.COMPLETE:
      return {
        'page.name': page,
        'page.siteSubSection': 'Confirmation Page',
        'page.type': 'Transaction Page',
      };
    default:
      return {
        'page.name': page,
        'page.siteSubSection': page,
        'page.type': `${page} Page`,
      };
  }
};

//TODO: make state great again
const createGaBasket = (state: any) => {
  const basket = getBasket(state);
  const items = getBasketItems(state);
  if (items.length === 0) {
    return {};
  }
  const currency = getCurrency(state);
  const includeTax = shouldIncludeTax(state);
  const count = items?.length ?? 0;
  const emptyArr = new Array(count).fill('');
  const mappedItems = {
    'basket.products.category': [],
    'basket.products.extension_id': [],
    'basket.products.id': [],
    'basket.products.name': [],
    'basket.products.numOfMonths': [],
    'basket.products.quantity': [],
    'basket.products.price': [],
    'basket.products.sale_price': [],
    'basket.products.subCategory': [],
  } as { [key: string]: Array<string | number> };
  items.forEach((item) => {
    const {
      product,
      product: { categoryName, id, name },
      term,
      quantity,
      paymentInterval,
    } = item;
    mappedItems['basket.products.category'].push(categoryName);
    mappedItems['basket.products.extension_id'].push('');
    mappedItems['basket.products.id'].push(id);
    mappedItems['basket.products.name'].push(name);
    mappedItems['basket.products.numOfMonths'].push(term);
    mappedItems['basket.products.quantity'].push(quantity);
    mappedItems['basket.products.price'].push(
      getRetailPrice({
        product: product,
        interval: paymentInterval,
        term: term,
        includeTax,
      }),
    );
    mappedItems['basket.products.sale_price'].push(
      getPromotionalPrice({
        product: product,
        interval: paymentInterval,
        term: term,
        includeTax,
      }),
    );
    mappedItems['basket.products.subCategory'].push('');
  });
  return {
    'basket.checkoutSavings': basket?.basketPrices?.basketAdjustments?.net ?? 0,
    'basket.currency': currency,
    'basket.id': basket.id,
    'basket.noOfProducts': count,
    ...mappedItems,
    'basket.products.subCategory': emptyArr,
    'basket.subTotal': basket?.subtotal,
    'basket.tax': basket?.basketPrices?.total?.tax,
    'basket.total': basket?.basketPrices?.total?.gross,
    'basket.voucher': null as null,
  };
};

const formatDate = (date: Date) => {
  const y = date.getFullYear();
  const m = ((date.getMonth() + 1) / 10).toFixed(1).replace('.', '');
  const d = (date.getDate() / 10).toFixed(1).replace('.', '');
  return `${y}-${m}-${d}`;
};

//TODO: make state great again
const createGaTransaction = (state: any) => {
  const { affiliation, orderId, adjustments, currency, items, subtotal, tax, total, voucher, includeTax } = state;
  // @ts-ignore
  const quantities = items.map((item) => item.quantity);
  if (items.length === 0) {
    return {};
  }
  // @ts-ignore
  const quantity = quantities.reduce((a, b) => a + b, 0);
  const date = formatDate(new Date());
  const mappedItems = {
    'transaction.date': [],
    'transaction.products.category': [],
    'transaction.products.id': [],
    'transaction.products.monthYears': [],
    'transaction.products.mungedName': [],
    'transaction.products.name': [],
    'transaction.products.numOfMonths': [],
    'transaction.products.price': [],
    'transaction.products.quantity': [],
    'transaction.size': [],
  } as {
    [key: string]: Array<number | string>;
  };

  const itemsLength = items.length;
  items.forEach((item: BasketItem) => {
    const {
      product,
      product: { categoryName, name, displayName },
      skuId,
      term,
      paymentInterval,
      quantity,
    } = item;
    mappedItems['transaction.date'].push(date);
    mappedItems['transaction.products.category'].push(categoryName);
    mappedItems['transaction.products.id'].push(skuId);
    mappedItems['transaction.products.monthYears'].push(`${term} months`);
    mappedItems['transaction.products.mungedName'].push(name);
    mappedItems['transaction.products.name'].push(displayName);
    mappedItems['transaction.products.numOfMonths'].push(term);
    mappedItems['transaction.products.price'].push(
      selectors.getPrice({
        product: product,
        includeTax,
        term: term,
        interval: paymentInterval,
      }),
    );
    mappedItems['transaction.products.quantity'].push(quantity);
    mappedItems['transaction.size'].push(itemsLength);
  });
  return {
    'totalQuantity': quantity,
    'transaction.affiliation': affiliation,
    'transaction.checkoutSavings': adjustments,
    'transaction.currency': currency,
    ...mappedItems,
    'transaction.id': orderId,
    'transaction.noOfProducts': itemsLength,
    // not sure where we get this from or how we would map it to string like Credit Balance
    'transaction.paymentType': '',
    'transaction.products': '',
    // @ts-ignore
    'transaction.products.extension_id': [],
    // @ts-ignore
    'transaction.products.sale_price': [],
    // @ts-ignore
    'transaction.products.subCategory': [],
    'transaction.shipping': 0,
    'transaction.subTotal': subtotal,
    'transaction.tax': tax,
    'transaction.total': total,
    'transaction.voucher': voucher,
  };
};

const createGA4BasketData = (state: any) => {
  const basket = getBasket(state);
  const brandId = wlSelectors.getBrandId(state);
  const items = getBasketItems(state);
  if (items.length === 0) {
    return {};
  }
  const currency = getCurrency(state);
  const includeTax = shouldIncludeTax(state);
  const ecommerceItems = items.map((item) => {
    const product = item.product;

    return {
      'item_name': product?.name,
      'item_id': item.skuId,
      'item_group_id': item.skuId,
      'item_brand': brandId,
      'item_category': product?.categoryName,
      'item_variant': `${product?.name} ${item.term}`,
      'currency': currency,
      'price': product == null ? undefined : selectors.getPrice({
        product,
        interval: item.paymentInterval,
        term: item.term,
        includeTax,
      }),
      'quantity': item.quantity,
    }
  });

  return {
    value: basket?.basketPrices?.total?.gross,
    currency,
    items: ecommerceItems,
  }
};

const createGA4CrossSellData = (state: any) => {
  const items = getCrossSells(state);
  const brandId = wlSelectors.getBrandId(state);
  if (items.length === 0) {
    return {};
  }
  const currency = getCurrency(state);
  const includeTax = shouldIncludeTax(state);
  const ecommerceItems = items.map((item) => {
    const product = item.product;

    return {
      'item_name': product?.name,
      'item_id': item.skuId,
      'item_group_id': item.skuId,
      'item_brand': brandId,
      'item_category': product?.categoryName,
      'item_variant': `${product?.name} ${product?.defaultPrice?.term}`,
      'currency': currency,
      'price': product == null ? undefined : selectors.getPrice({
        product,
        interval: 1,
        term: product.defaultPrice?.term,
        includeTax,
      }),
      'quantity': 1,
    }
  });

  return {
    items: ecommerceItems,
  }
};

const extraData = (state: any, page: PageType) => {
  switch (page) {
    case PageType.BASKET:
      return createGaBasket(state);
    case PageType.COMPLETE:
      return createGaTransaction(state);
    default:
      return {};
  }
};

export const pageView = (state: any, window: Window, view: View, page: PageType) => {
  const language = getLanguage(state);
  const url = getEnRoute(window.location, language);
  const data = {
    ...defaultData,
    ...pageData(page),
    ...extraData(state, page),
    'location': url,
    'page.url': url,
    'dom.url': url,
  };

  view(data);
  if (page === PageType.BASKET) {
    const ga4BasketData = createGA4BasketData(state);
    dispatchGA4Event('view_cart', ga4BasketData);
  }
  if (page === PageType.CROSSSELLS) {
    const ga4CrossSellData = createGA4CrossSellData(state);
    dispatchGA4Event('view_item', ga4CrossSellData);
  }
};
