import { Http, Method } from '@ux/drivers';
import { createSequentialQueue } from '@ux/queue';
import { getBasketId, getLanguageCookie } from 'domain/selectors/common';

import { AddToBasket } from '../../core/basket';

const addToBasket = (driver: Http): AddToBasket => {
  const action: AddToBasket = ({ items }) => {
    const basketId = getBasketId();
    const languageCookie = getLanguageCookie();

    return driver({
      url: `/api/basket/api/v2/baskets/${basketId}/items?localeCode=${languageCookie}`,
      method: Method.POST,
      data: {
        items,
      },
    });
  };

  return createSequentialQueue(action);
};

export default addToBasket;
