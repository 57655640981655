import { createSelector } from 'reselect';
import * as r from 'ramda';

import { getUrl, getScreens } from '../common';
import { State as RootState } from '../../reducers';

declare const APP_NAME: string;

const getState = (state: RootState) => state.uop.login;

export const isLoading = createSelector([getState], r.prop('loading'));

export const hasLoaded = createSelector([getState], r.prop('loaded'));

export const getLoginUrl = getUrl('sso.login');

const getSafeApp = r.always(encodeURIComponent(APP_NAME));
const getSafePath = createSelector([getScreens], (screens) => {
  const i = r.findIndex(r.propEq('key', 'login'), screens);
  const screen = screens[i + 1];
  const url = r.path<string>(['path', '0'], screen);
  return encodeURIComponent(url);
});
export const getIframeUrl = createSelector([getSafeApp, getSafePath, getLoginUrl], (app, path, url) => {
  return `${url}?iframe=true&app=${app}&path=${path}`;
});
