import { combineEpics } from 'redux-most';

import { Core } from '../../../core';
import fetch from './fetch';
import add from './add';
import remove from './remove';

export default (deps: Core) =>
  combineEpics([
    fetch(),
    add(deps.vouchers.redeemVoucher, deps.events.dispatchGAEvent),
    remove(deps.vouchers.removeVoucher),
  ]);
