import { createSelector } from 'reselect';
import * as r from 'ramda';

import { getBasketItems, hasPrefetched as hasBasketPrefetched } from '../basket';
import { showPage as showOffersPage } from '../pages/offers';
import { getCurrentScreen, getScreens } from './screens';

const isBasketEmpty = createSelector(
  [getCurrentScreen, getBasketItems, hasBasketPrefetched],
  (currentScreen, items, loaded) =>
    r.allPass([
      r.always(r.propEq('key', 'basket', currentScreen)),
      r.always(r.propEq('length', 0, items)),
      r.always(r.equals(loaded, true)),
    ])(null),
);

const isScreenValid = createSelector([getCurrentScreen, getScreens], (currentScreen, screens) =>
  r.find(r.eqProps('key', currentScreen), screens),
);

// TODO
// determine if the current screen has loaded
const isScreenLoaded = createSelector(
  [getCurrentScreen, showOffersPage, hasBasketPrefetched],
  (screen, showOffers, hasBasketPrefetched) =>
    r.cond([
      [r.propEq('key', 'offers'), r.always(showOffers)],
      [r.propEq('key', 'basket'), r.always(hasBasketPrefetched)],
      [r.propEq('key', 'confirmation'), r.F],
      [r.T, r.T],
    ])(screen),
);

export const showSteptracker = createSelector(
  [isBasketEmpty, isScreenValid, isScreenLoaded],
  (basketEmpty, screenValid, loaded) => Boolean(!basketEmpty && screenValid && loaded),
);
