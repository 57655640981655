import { createEpic } from '@ux/fabric';

import { Event } from '../../../domain/constants/events';
import { mapEvent } from '../../../domain/transformers/events';
import { FOLLOW_UP_CLICKED } from '../../signals/confirmation';

const followUpClickedEpic = (dispatch: Function) =>
  createEpic({
    signal: FOLLOW_UP_CLICKED,
    process: () => [
      dispatch(
        mapEvent({
          event: Event.FOLLOW_UP_CLICKED,
        }),
      ),
    ],
  });

export default followUpClickedEpic;
