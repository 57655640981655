import { createContext } from 'react';

type ContextSig = [{ [key: string]: boolean }, (key: string) => void];

const AnimationContext = createContext<ContextSig>(null);

const { Provider, Consumer } = AnimationContext;

export { Provider, Consumer };

export default AnimationContext;
