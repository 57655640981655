import { useEffect } from 'react';
import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getNextUrl, getLocation } from '../../domain/selectors/common';

// when you land on the home page (/) we want to redirect you to the first step's url.
// However, we don't want to do this until we know for sure what the first step is.
// This hook waits until the current page has determined whether or not to show.

const useEnsureStartUrl = (loading: boolean = false) => {
  const nextUrl = useSelector(getNextUrl);
  const { pathname } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (pathname === '/') {
      dispatch(replace({ pathname: nextUrl }));
    }
  }, [loading, pathname, dispatch, nextUrl]);
};

export default useEnsureStartUrl;
