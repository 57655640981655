import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import * as rk from '@ux/reactive-knowledge';
import { selectors as product } from '@ux/product';

import { Event } from '../../../domain/constants/events';
import { mapEvent } from '../../../domain/transformers/events';
import { DispatchGAEvent, Ecommerce } from '../../../core/events';
import { State as RootState } from '../../../domain/reducers';
import { RemoveFromBasket } from '../../../core/basket';
import { getProduct } from '../../../domain/selectors/products';
import * as signals from '../../signals/basket';
import * as messages from '../../../domain/messages/basket';
import { handleAuth, handleEsocketError } from '../utils';

const removeGab = (removeFromBasket: RemoveFromBasket) =>
  createEpic({
    signal: signals.REMOVE_GAB,
    pending: (action) => messages.removingGab(action.payload),
    process: ({ payload: { basketItemIds } }) => {
      return removeFromBasket({
        itemIdsToBeDeleted: basketItemIds,
      });
    },
    onSuccess: (x, action: signals.RemoveGab) => {
      return [
        messages.removedGab(action.payload),
        rk.signals.add({
          type: rk.KnowledgeType.SMIRK,
          message: 'uop.basket.removed',
        }),
      ];
    },
    onFailure: handleEsocketError(
      handleAuth((err, { payload: { productId, basketItemIds } }) => {
        return [
          messages.removeGabFailure(err, productId, basketItemIds),
          rk.signals.add({
            type: rk.KnowledgeType.ERK,
            message: 'uop.basket.remove.failed',
          }),
        ];
      }),
    ),
  });

const analyticsEpic = (dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  createEpic({
    signal: messages.REMOVED_GAB,
    process: (action: messages.RemovedGab, state: RootState) => {
      const o = {
        product: getProduct(action.payload.productId)(state),
      };
      const name = product.getName(o);
      const category = product.getCategory(o);
      const sku = action.payload.productId;
      const list = 'bolt-on (offers)';

      ecommerce([
        [
          'addProduct',
          {
            id: sku,
            name: `${name} (auto-added)`,
          },
        ],
        [
          'setAction',
          'remove',
          {
            list,
            category,
          },
        ],
      ]);

      dispatch(
        mapEvent({
          event: Event.REMOVE_ALL_GABS,
          sku,
        }),
      );
    },
  });

export default (removeFromBasket: RemoveFromBasket, dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  combineEpics([removeGab(removeFromBasket), analyticsEpic(dispatch, ecommerce)]);
