import { Http } from '@ux/drivers';
import normalize from 'domain/transformers/basket';
import { getBasketId, getLanguageCookie } from 'domain/selectors/common';
import { FetchBasket } from 'core/basket';

const fetchBasket = (driver: Http): FetchBasket => ({ brandId }) => {
  const basketId = getBasketId();
  const languageCookie = getLanguageCookie();
  const basketHeader = basketId == null ? undefined : { 'X-Basket': basketId };

  return driver({
    url: `/api/basket/api/v2/baskets/my`,
    data: {
      includeProductData: true,
      localeCode: languageCookie,
    },
    headers: {
      'X-Tenant': brandId,
      ...basketHeader,
    },
  }).then(({ data }) => normalize(data));
};

export default fetchBasket;
