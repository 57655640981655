import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { replace } from 'connected-react-router';
import * as r from 'ramda';
import * as a from 'ramda-adjunct';
import { isEmptyBasketError } from 'domain/logic/basket';
import * as messages from 'domain/messages/basket';
import { getCurrentScreen } from 'domain/selectors/common';

// if the basket doesn't contain any items, we want to always
// just show the empty basket page, regardless of what page we're on
const emptyEpic = () =>
  createEpic({
    signal: [messages.FETCHED, messages.FETCH_EMPTY],
    selector: (state: any) => ({
      screen: getCurrentScreen(state),
    }),
    filter: (action: messages.Fetched, state) => {
      if (r.pathEq(['screen', 'key'], 'confirmation', state)) {
        return false;
      }
      return r.pathSatisfies(a.isNilOrEmpty, ['payload', 'basketItems'], action);
    },
    onSuccess: () => replace('/basket'),
  });

// if we get some sort of "basket not found" error we want to act as if we've received a success
// response with an empty basket
const fetchFailedEpic = () =>
  createEpic({
    signal: messages.FETCH_FAILED,
    filter: (action: messages.FetchFailed) => {
      return isEmptyBasketError(action.payload.code);
    },
    onSuccess: () => messages.fetchEmpty(),
  });

export default () => combineEpics([emptyEpic(), fetchFailedEpic()]);

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  emptyEpic,
  fetchFailedEpic,
};
