import { combineEpics } from 'redux-most';
import { Core } from 'core';

import captureLanding from './captureLanding';
import captureCheckout from './captureCheckout';
import fetch from './fetch';
import initialize from './initialize';
import loadUpm from './loadUpm';

export default (deps: Core) =>
  combineEpics([captureLanding(), captureCheckout(deps.events.dispatchFBEvent), fetch(), initialize(), loadUpm()]);
