export enum Status {
  REQUESTING,
  READY,
  NONE_REQUIRED,
  FAILED,
}

export const FieldSets = {
  'accountDetails': [
    'companyName',
    'companyNumber',
    'companyTaxNumber',
    'forename',
    'lastname',
    'address.line1',
    'address.line2',
    'address.city',
    'address.state',
    'address.postcode',
    'address.countryCode',
    'telephone',
  ],
  'domain.basic': [
    'forename',
    'lastname',
    'address.line1',
    'address.line2',
    'address.city',
    'address.state',
    'address.postcode',
    'address.countryCode',
    'telephone',
    'email',
  ],
  'domain.group.uk': [
    'registrantType',
    'organisation',
    'companyNumber',
    'charityNumber',
    'schoolNumber',
    'partnerNames',
  ],
  'domain.group.cat': ['language', 'state'],
  'domain.group.us': ['appPurpose', 'category', 'organisation'],
  'domain.group.es': ['idType', 'idNumber', 'contactType', 'organisation'],
  'domain.group.nl': ['legalType', 'organisation', 'legalTypeRegistrationNumber'],
  'domain.group.afnic': [
    'registrantType',
    'birthDate',
    'birthCountryCode',
    'birthCity',
    'birthPostCode',
    'organisation',
  ],
  'domain.group.scot': ['intendedUse'],
  'domain.group.de': ['contactType'],
  'domain.group.it': ['nationality', 'entityType', 'regCode'],
  'domain.group.lv': ['registrationNumber'],
  'domain.group.im': ['registrantType'],
  'domain.transfer': ['transfer'],
  'domain.group.eu': ['countryOfCitizenship'],
};
