import { createSelector } from 'reselect';
import * as r from 'ramda';

import { Screen } from '../../../core/common';
import { showLogin } from '../pages/login';
import { getStatus as getOfferStatus } from '../pages/offers';
import { Status as OfferStatus } from '../../constants/cross-sells';
import { getStatus as getDetailsStatus } from '../pages/details';
import { Status as DetailsStatus } from '../../constants/details';

const filterScreens = createSelector(
  [showLogin, getOfferStatus, getDetailsStatus],
  (showLogin, offerStatus, detailsStatus) => (screens: Screen[]): Screen[] =>
    r.filter(
      r.cond([
        [r.propEq('key', 'offers'), r.always(offerStatus !== OfferStatus.HAS_NONE)],
        [r.propEq('key', 'login'), r.always(showLogin)],
        [r.propEq('key', 'details'), r.always(detailsStatus !== DetailsStatus.NONE_REQUIRED)],
        [r.T, r.T],
      ]),
      screens,
    ),
);

export default filterScreens;
