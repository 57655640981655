import { EligibleFieldsByRegistrantType } from 'domain/selectors/details/registrarUkUtils';
import * as r from 'ramda';
import accountDetailsSchema from 'domain/schemas/accountDetails';

import productSchema from '../../schemas/requiredInformation';

const defaultProductObject = {
  domain: {
    basic: {},
    group: {
      uk: {},
      us: {},
      es: {},
      nl: {},
      afnic: {},
      de: {},
      it: {},
      lv: {},
      cat: {},
    },
    transfer: {},
  },
};

const defaultAccountObject = {
  accountDetails: {},
};

const validateProducts = (
  validateAccountDetails: boolean,
  allValues: any,
  eligibleFieldsByRegistrantType: EligibleFieldsByRegistrantType,
) => {
  const products = allValues.products ?? {};
  const customer = allValues.customer ?? {};
  const productValidationPromises = Object.keys(products).map((product) => {
    const values = r.mergeDeepRight(defaultProductObject, products[product]);
    const shouldUseAccount = values?.shouldUseAccount;
    const schema = productSchema(eligibleFieldsByRegistrantType, shouldUseAccount);

    return schema
      .validate(values, {
        abortEarly: false,
        context: values,
        strict: false,
      })
      .then(() => null)
      .catch((errors) => {
        const errs = errors?.inner ?? [];
        return errs.map((err: { path: string }) => {
          err.path = `products.${product}.${err.path}`;
          return err;
        });
      });
  });
  const accountDetailsValues = r.mergeDeepRight(defaultAccountObject, customer);

  const accountDetailsValidationPromise = validateAccountDetails
    ? accountDetailsSchema
        .validate(accountDetailsValues, {
          abortEarly: false,
          context: accountDetailsValues,
          strict: false,
        })
        .then(() => null)
        .catch((errors) => {
          const errs = errors?.inner ?? [];
          return errs.map((err: { path: string }) => {
            err.path = `customer.${err.path}`;
            return err;
          });
        })
    : Promise.resolve(null);

  const validationPromise = Promise.all([accountDetailsValidationPromise, ...productValidationPromises]).then(
    r.reject(r.isNil),
  );
  return validationPromise.then((result) => {
    return r.ifElse(
      r.pipe(r.length, r.lte(r.__, 0)),
      r.always(Promise.resolve()),
      r.pipe(r.flatten, r.assoc('inner', r.__, {}), (error) => Promise.reject(error)),
    )(result);
  });
};

const validate = (
  validateAccountDetails: boolean,
  allValues: Object,
  eligibleFieldsByRegistrantType: EligibleFieldsByRegistrantType,
) => validateProducts(validateAccountDetails, allValues, eligibleFieldsByRegistrantType);

export default validate;
