import { Indexer } from '@uxdev/types';
import { createReducer } from 'redux-create-reducer';
import * as messages from 'domain/messages/upsells';
import { ProductStatus } from 'domain/constants/product';
import { Upsell } from 'core/upsells';

export interface State {
  fetching: boolean;
  prefetched: boolean;
  fetched: boolean;
  error: any;
  upsells: Indexer<{}, Upsell>;
  pendingStates: Indexer<{}, ProductStatus>;
}

const initialState: State = {
  fetching: false,
  prefetched: false,
  fetched: false,
  error: null,
  upsells: {},
  pendingStates: {},
};

const STALE_STATUSES = [ProductStatus.ADDED, ProductStatus.REMOVED, ProductStatus.UPDATED];

const isObjectNilOrEmpty = (givenObject: object) => {
  if (!givenObject || typeof givenObject !== 'object') {
    return true;
  }
  return Object.keys(givenObject).length === 0;
};

export default createReducer<State, any>(initialState, {
  [messages.FETCHING]: (state) => {
    return {
      ...state,
      fetching: true,
      //if we're fetching we disregard previous upsells
      upsells: {},
    };
  },
  [messages.PREFETCHED]: (state, action: messages.Prefetched) => {
    return {
      ...state,
      prefetched: true,
      upsells: state.fetching ? action.payload.upsells : state.upsells,
      fetching: isObjectNilOrEmpty(action.payload.upsells) ? false : state.fetching,
      fetched: isObjectNilOrEmpty(action.payload.upsells) || state.fetched,
    };
  },
  [messages.FETCHED]: (state, action: messages.Fetched) => {
    return {
      ...state,
      fetching: false,
      //technically we may have not prefetched but
      //we can consider we did as fetched is the
      //ultimate source of truth
      prefetched: true,
      fetched: true,
      upsells: action.payload.upsells,
      pendingStates: Object.entries(state.pendingStates).reduce((pendingStates, [key, value]) => {
        // once we've fetched the upsells we want to remove
        // any pending states that are considered "done"
        if (STALE_STATUSES.includes(value)) {
          return pendingStates;
        }
        return {
          ...pendingStates,
          [key]: value,
        };
      }, {}),
    };
  },
  [messages.FETCH_FAILED]: (state, action: messages.FetchFailed) => {
    return {
      ...state,
      fetching: false,
      prefetched: false,
      fetched: false,
      upsells: {},
      error: action.payload,
    };
  },
  [messages.ADDING]: (state, action: messages.Adding) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.ADDING,
      },
    };
  },
  [messages.ADDED]: (state, action: messages.Added) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.ADDED,
      },
    };
  },
  [messages.ADD_FAILED]: (state, action: messages.AddFailed) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.meta.basketItemId]: ProductStatus.ERROR,
      },
    };
  },
});
