import { createAction } from 'redux-actions';

import { ProductId } from '../../core/basket';

// Action Types
const prefix = '@@uop/cross-sells/S_';
export const FETCH = `${prefix}FETCH`;
export const ADD = `${prefix}ADD`;

// Action Creators
export const fetch = createAction(FETCH);
export const add = createAction<{
  productId: ProductId;
  term?: number;
  paymentInterval?: number;
  quantity?: number;
  index: number;
}>(ADD);

// Action Payloads
export type Fetch = ReturnType<typeof fetch>;
export type Add = ReturnType<typeof add>;
