import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import {
  PROCESSING_TRANSACTION,
  TRANSACTION_PROCESSED,
  TRANSACTION_FAILED,
  TransactionFailed,
} from '../../messages/payment';

export interface State {
  processing: boolean;
  processed: boolean;
  error: any;
}

const initialState: State = {
  processing: false,
  processed: false,
  error: null,
};

export default createReducer<State, any>(initialState, {
  [PROCESSING_TRANSACTION]: r.assoc('processing', true),
  [TRANSACTION_PROCESSED]: (state) => {
    return {
      ...state,
      processing: false,
      processed: true,
      error: null,
    };
  },
  [TRANSACTION_FAILED]: (state, action: TransactionFailed) => {
    return {
      ...state,
      processing: false,
      processed: false,
      error: action.payload,
    };
  },
});
