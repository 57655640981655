import { Navigate, FullyQualifyPath, GetQuery } from 'core/navigation';
import * as r from 'ramda';
import qs from 'qs';

export const navigate = (global: Window): Navigate => (url, newTab = false) => {
  r.ifElse(
    r.equals(true),
    () => window.open(url, '_blank'),
    () => {
      global.location.href = url;
    },
  )(newTab);
};

export const fullyQualifyPath = (global: Window): FullyQualifyPath => (url) => {
  return global.location.origin + url;
};

export const getQuery = (
  global: Window,
  // @ts-ignore
): GetQuery => () => qs.parse(global.location.search, { ignoreQueryPrefix: true });

export type AddScript = (options: { url?: string; content?: string; location?: string }) => Promise<any>;
export const addScript = (window: Object, document: Document): AddScript => (options = {}) => {
  const script = document.createElement('script');
  const parent = document.querySelector(options.location || 'head');

  if (options.url) {
    script.src = options.url;
  }
  if (options.content) {
    script.text = options.content;
  }

  return new Promise((res, rej) => {
    script.onload = res;
    script.onerror = rej;

    if (parent != null) {
      parent.appendChild(script);
    }
  });
};

export default (window: Window) => {
  return {
    navigate: navigate(window),
    fullyQualifyPath: fullyQualifyPath(window),
    getQuery: getQuery(window),
  };
};
