import { Http, Method } from '@ux/drivers';
import { PostPayment } from 'core/payment';
import { getBasketId } from 'domain/selectors/common';

const postPayment = (json: Http): PostPayment => ({ operationId, orderId, transactionId, type }) => {
  const basketId = getBasketId();
  const request = {
    url: `/api/app/v3/commands/baskets/my/postPayment${type}?basketId=${basketId}`,
    method: Method.POST,
    data: {
      operationId,
      orderId,
      transactionId,
    },
  };
  return json(request).then(({ data }) => data);
};

export default postPayment;
