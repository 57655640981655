import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@ux/language';

// because we have several things all tied together, we don't want to pass locale into the intl provider
// as soon as it changes. We have to wait for messages to be fetched and any locale data to be loaded in
// therefore we cache the locale value and only update it when all of the steps are finished
const useLocale = () => {
  const nextLocale = useSelector(selectors.getLanguage);
  const messagesFetched = useSelector(selectors.areMessagesFetched);
  const [locale, setLocale] = useState(nextLocale);

  useEffect(() => {
    if (nextLocale !== locale && messagesFetched) {
      setTimeout(() => {
        setLocale(nextLocale);
      }, 0);
    }
  }, [nextLocale, messagesFetched]);

  return locale;
};

export default useLocale;
