import { createEpic } from '@ux/fabric';
import { DispatchFBEvent } from 'core/events';
import { LOCATION_CHANGE } from 'connected-react-router';

import { State } from '../../../domain/reducers';
import { getCurrentScreen } from '../../../domain/selectors/common';

const selector = (state: State) => ({ screen: getCurrentScreen(state) });

const captureCheckoutEpic = (dispatchFBEvent: DispatchFBEvent) =>
  createEpic({
    signal: LOCATION_CHANGE,
    selector,
    filter: (action, state) => state.screen.key === 'payment',
    process: () => {
      //as soon as we are on payment, we initiate checkout
      dispatchFBEvent('InitiateCheckout', {});
    },
  });

export default captureCheckoutEpic;
