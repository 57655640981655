import { puree, Hook } from '@ux/pure-enhance';
import { withHandlers, withProps } from '@team-griffin/rehook';
import { connect } from '@ux/fabric';
import { createStructuredSelector } from 'reselect';

import { getHelpField, getHelpProductId, getProducts } from '../../../../domain/selectors/details';
import { remove, closeHelp } from '../../../../application/signals/details';
import FieldExplanationModal, { OuterProps as InnerProps } from './FieldExplanationModal';

interface OuterProps {}

const mapStateToProps = createStructuredSelector({
  field: getHelpField,
  productId: getHelpProductId,
  products: getProducts,
});

const mapDispatchToProps = {
  remove,
  closeHelp,
};

const enhance = puree<OuterProps, InnerProps>('ConnectedFieldExplanationModal')(
  withProps(connect(mapStateToProps, mapDispatchToProps)) as Hook<OuterProps, any>,
  withHandlers({
    onCloseClick: (ownerProps: any) => () => {
      ownerProps.actions.closeHelp();
    },
    onRemoveClick: ({ actions, field, productId }: any) => () => {
      actions.remove({
        field,
        productId,
      });
      actions.closeHelp();
    },
  }),
);

export default enhance(FieldExplanationModal);
