import * as r from 'ramda';
import * as c from '@team-griffin/capra';
import { createSelector } from 'reselect';
import { createSelector as quickSelector } from '@ux/fabric';
import { State as RootState } from 'domain/reducers';
import { State } from 'domain/reducers/uop/confirmation';
import { Confirmation } from 'core/confirmation';

const getState: (state: RootState) => State = r.path(['uop', 'confirmation']);
const getLocationState: (state: RootState) => State = r.path(['router', 'location']);
const getWhitelabelState: (state: RootState) => State = r.path(['whitelabel']);

export const hasFetched = quickSelector([getState], r.prop('fetched'));

export const hasFetchFailed = quickSelector([getState], r.propSatisfies(c.isNotNilOrEmpty, 'error'));

export const isLoaded = createSelector(
  [hasFetched, hasFetchFailed],
  (hasFetched, hasFetchFailed) => !r.and(hasFetched, !hasFetchFailed),
);

export const getTotalPrice = createSelector(
  [getState],
  r.pipe<State, State['confirmation'], Confirmation>(r.pathOr(0, ['confirmation', 'totalPrice'])),
);

export const getOrderNumber = createSelector(
  [getState],
  r.pipe<State, State['confirmation'], Confirmation>(r.pathOr('', ['confirmation', 'orderNumber'])),
);

export const getOrderIdParam = createSelector(
  [getLocationState],
  r.pipe(r.propOr('', 'search'), r.replace('?order=', '')),
);

export const getError = quickSelector([getState], r.prop('error'));

export const getCurrency = quickSelector([getState], r.path(['confirmation', 'basket', 'currency']));

export const getBrandId = quickSelector([getWhitelabelState], r.path(['brand', 'id']));
