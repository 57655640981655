import { fetch, json, xray, Http } from '@ux/drivers';
import { map, mapRej } from '@ux/promise';
import * as r from 'ramda';

import { handleAuthRequestValidation } from './utils';

// right now if you try to reach an endpoint that doesn't exist, it falls back to the legacy
// jsonrpc api, which will just give you a 200 response and a jsonrpc style object
const jsonRpcGuard = () => (http: Http): Http =>
  r.pipe(
    http,
    map(
      r.when(r.path(['data', 'jsonrpc']), () => {
        throw new Error('unknown api endpoint');
      }),
    ),
  );

const authGuard = () => (http: Http): Http => r.pipe(http, mapRej(handleAuthRequestValidation));

export default () => r.pipe(xray(), json(), jsonRpcGuard(), authGuard())(fetch);
