export default {
  login: '/login',
  offers: '/offers',
  basket: '/basket',
  details: '/checkout/information',
  payment: '/checkout/payment',
  transaction: '/checkout/transaction',
  failedTransaction: '/checkout/failed',
  completeTransaction: '/checkout/complete',
  confirmation: '/confirmation',
};
