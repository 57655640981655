import { Http, Method } from '@ux/drivers';
import * as r from 'ramda';
import { OrderBasket } from 'core/basket';
import { FullyQualifyPath } from 'core/navigation';
import { getBasketId } from 'domain/selectors/common';

const orderBasket = (driver: Http, fullyQualifyPath: FullyQualifyPath): OrderBasket => ({
  currency,
  type,
  tokenId,
  successUrl,
  failureUrl,
}) => {
  const basketId = getBasketId();

  const request = {
    url: `/api/app/v3/commands/baskets/my/order?basketId=${basketId}`,
    method: Method.POST,
    data: {
      type,
      // NOTE currency is currently required by api but will be removed in
      // future in favour of the currency cookie
      currency,
      tokenId,
      secure3d: {
        baseUrl: fullyQualifyPath(''),
        successUrl: fullyQualifyPath(successUrl),
        failureUrl: fullyQualifyPath(failureUrl),
      },
    },
  };

  return driver(request).then<any>(r.prop('data'));
};

export default orderBasket;
