import { EligibleFieldsByRegistrantType } from 'domain/selectors/details/registrarUkUtils';
import * as yup from 'yup';

import { required } from '../../../basic/messages';

//This is for organisation and partnerNames.
//A valid field must be between 1 and 255 characters long and can contain letters
//and numbers, along with a set of symbols
// - ' \ , . + @ _ ! ( & ~ ) / : ; ` " $ % * = ? ^ } { | and space (" ")
// eslint-disable-next-line
export const nameRegex = /^[ \-,.+@_!(&~):;`"$%*=?^}{|\/'0-9a-zA-Z]{1,255}$/;

//This validation is for company, charity and school number.
//A valid field must be between 1 and 40 characters long and can only contain
//letters, numbers, underscore and hyphen
export const numberRegex = /^[-_0-9a-zA-Z]{1,40}$/;

export default (eligibleFieldsByRegistrantType: EligibleFieldsByRegistrantType) => {
  const { eligibleUkComps = [], eligibleUkOrgs = [] } = eligibleFieldsByRegistrantType;

  const validateName = (message: string) =>
    yup
      .string()
      .matches(nameRegex, {
        message: `uop.requiredInformation.validation.domain.group.uk.${message}.regex`,
      })
      .required(required);

  const validateNumber = (message: string) =>
    yup
      .string()
      .matches(numberRegex, {
        message: `uop.requiredInformation.validation.domain.group.uk.${message}.regex`,
      })
      .required(required);

  const registrantType = yup.string().label('domain.group.uk.registrantType').required(required);

  const organisation = yup
    .string()
    .label('domain.group.uk.organisation')
    .when('registrantType', {
      is: (v) => v && eligibleUkOrgs.includes(v),
      then: validateName('organisation'),
      otherwise: yup.string().nullable(true),
    });

  const partnerNames = yup
    .string()
    .label('domain.group.uk.partnerNames')
    .when('registrantType', {
      is: (v) => v && v === 'PTNR',
      then: validateName('partnerNames'),
      otherwise: yup.string().nullable(true),
    });

  const companyNumber = yup
    .string()
    .label('domain.group.uk.companyNumber')
    .when('registrantType', {
      is: (v) => v && eligibleUkComps.includes(v),
      then: validateNumber('companyNumber'),
      otherwise: yup.string().nullable(true),
    });

  const charityNumber = yup
    .string()
    .label('domain.group.uk.charityNumber')
    .when('registrantType', {
      is: (v) => v && v === 'RCHAR',
      then: validateNumber('charityNumber'),
      otherwise: yup.string().nullable(true),
    });

  const schoolNumber = yup
    .string()
    .label('domain.group.uk.schoolNumber')
    .when('registrantType', {
      is: (v) => v && v === 'SCH',
      then: validateNumber('schoolNumber'),
      otherwise: yup.string().nullable(true),
    });
  return {
    registrantType,
    organisation,
    partnerNames,
    companyNumber,
    charityNumber,
    schoolNumber,
  };
};
