import { createSelector } from 'reselect';
import { selectors as productSelectors } from '@ux/product';
import * as r from 'ramda';

import {
  hasFetched as hasFetchedCrossSells,
  hasCrossSells,
  isFetching as crossSellsFetching,
  hasFailed as hasOffersFailed,
} from '../cross-sells';
import {
  hasPrefetched as hasPrefetchedBasket,
  getLastBasketItem,
  isFetching as basketFetching,
  hasFetched as hasBasketFetched,
  hasFetchFailed as hasBasketFailed,
} from '../basket';
import { hasAutoBoltOns } from '../auto-bolt-ons';
import { getFirstScreen } from '../common';
import { Status } from '../../constants/cross-sells';

const { getName, getCategory } = productSelectors;

export const getStatus = createSelector(
  [
    hasFetchedCrossSells,
    hasCrossSells,
    hasPrefetchedBasket,
    hasAutoBoltOns,
    crossSellsFetching,
    basketFetching,
    hasOffersFailed,
    hasBasketFailed,
  ],
  (
    hasFetchedCrossSells,
    hasCrossSells,
    hasPrefetchedBasket,
    hasAutoBoltOns,
    crossSellsFetching,
    basketFetching,
    hasOffersFailed,
    hasBasketFailed,
  ) => {
    if (hasCrossSells && hasAutoBoltOns) {
      return Status.HAS_BOTH;
    }
    if (hasCrossSells) {
      return Status.HAS_OFFERS;
    }
    if (hasAutoBoltOns) {
      return Status.HAS_AUTO_BOLT_ONS;
    }
    if (crossSellsFetching || basketFetching) {
      return Status.REQUESTING;
    }
    if (hasOffersFailed || hasBasketFailed) {
      return Status.HAS_NONE;
    }
    if (hasFetchedCrossSells && hasPrefetchedBasket) {
      return Status.HAS_NONE;
    }

    return Status.REQUESTING;
  },
);

export const showPage = createSelector([getStatus], (status) => status !== Status.REQUESTING);

export const hasLoaded = createSelector(
  [hasPrefetchedBasket, hasFetchedCrossSells, hasBasketFailed, hasOffersFailed],
  (hasPrefetchedBasket, hasFetchedCrossSells, hasBasketFailed, hasOffersFailed) => {
    if ((hasBasketFailed || hasPrefetchedBasket) && (hasOffersFailed || hasFetchedCrossSells)) {
      return true;
    }
    return false;
  },
);

export const getProductInBasket = createSelector(
  [getLastBasketItem],
  // @ts-ignore
  (basketItem = {}) => {
    return {
      name: r.prop('domainName', basketItem) || getName(basketItem),
      category: getCategory(basketItem),
    };
  },
);

export const showBasketAffirmation = createSelector(
  [
    // avoiding recursive dependencies
    (state) => getFirstScreen(state),
    getLastBasketItem,
    hasBasketFetched,
  ],
  (firstScreen, productInBasket, hasBasketFetched) => {
    return r.propEq('key', 'offers', firstScreen) && productInBasket != null && hasBasketFetched;
  },
);
