import { createSelector } from 'reselect';
import * as r from 'ramda';
import * as rA from 'ramda-adjunct';
import dotize from 'dotize';
import { formValueSelector, getFormValues as reduxFormFormValues } from 'redux-form';

import { allEqual, undotize } from '../../../crosscutting/ramda';
import { State as RootState } from '../../reducers';
import { getFieldValues, getFieldSets, hasFieldValues } from './fields';
import { RequiredFieldSets, RequiredValues } from '../../../core/details';
import { getCustomerDetails } from '../../../domain/selectors/customer';
import { Customer, CustomerFormData } from '../../../core/customer';
import { getModifiedDomainData } from './registrarUk';
import { getAccountFormValues } from '../customer';

export const getRawFormValues = reduxFormFormValues('uop.requiredInformation');

export const getFormValues = createSelector(
  getRawFormValues,
  r.pipe(r.prop('products'), r.omit(['GROUPED']), r.map(r.omit(['fieldSets']))),
);

export const isGrouped = (state: RootState) => formValueSelector('uop.requiredInformation')(state, 'group');

// inverts the field sets object so we can get all product ids for a set
// { productId: [ 'domain.basic' ] } -> { 'domain.basic': [ productId ]}
export const getSetFields = createSelector([getFieldSets], (fieldSets: RequiredFieldSets) => {
  return r.reduce(
    (setFields, productId) => {
      return r.reduce(
        (setFields: any, fieldSet: string) => {
          const ids = setFields[fieldSet] || [];
          return r.assoc(fieldSet, r.append(productId, ids), setFields);
        },
        setFields,
        fieldSets[productId],
      );
    },
    {},
    r.keys(fieldSets),
  );
});

// returns if any of the fields have different values between products
export const hasConflicts = createSelector(
  [
    // use getModifiedDomainData instead of getFormValues to not conflict
    // when values are different but hidden
    // example: user picks school and adds school number but changes
    // back to individual in UK registrantType
    getModifiedDomainData,
    getSetFields,
    getAccountFormValues,
  ],
  (formValues: RequiredValues, setFields: RequiredFieldSets, accountFormValues: CustomerFormData) => {
    // loop through each field set, get the products that use that field set
    // then compare all of the values for those products' fields
    // if any of them don't match up, we return true
    return r.any((fieldSet: string) => {
      // @ts-ignore
      const ids: string[] = setFields[fieldSet];
      const path = fieldSet.split('.');
      // { productId: { domain: { basic: { forename: 'jim' }}}} -> [ { forename: 'jim' } ]
      const allValues: Object[] = r.pipe(
        r.pick(ids),
        (requiredValues: RequiredValues) => {
          return Object.keys(requiredValues ?? {}).reduce((acc, key) => {
            const requiredValue = requiredValues[(key as unknown) as number] ?? {};
            if (requiredValue.shouldUseAccount) {
              return r.assoc(
                key,
                r.mergeDeepRight(requiredValue, { domain: { basic: accountFormValues?.accountDetails } }),
                acc,
              );
            }
            return r.assoc(key, requiredValue, acc);
          }, {});
        },
        r.map(r.path(path)),
        r.map(dotize.convert),
        r.values,
      )(formValues);

      // [ { forename: 'jim' } ] -> [ 'forename' ]
      const fieldNames: string[] = (r.pipe(r.map(r.keys), r.flatten, r.uniq)(allValues) as unknown) as string[];

      // loop through all of the values for this field set and return false if any of them
      // differ between products
      const hasConflict = fieldNames.some((field: string): boolean => {
        const values = allValues.map((atom: { [key: string]: string }) => {
          if (atom) {
            return atom[field] || '';
          }
        });
        return !allEqual(values);
      });

      if (hasConflict) {
        return true;
      }
    }, r.keys(setFields));
  },
);

export const getCDInitialValues = createSelector(
  getCustomerDetails,
  getFieldSets,
  (values: any, fieldSets: RequiredFieldSets) =>
    r.pipe(
      r.map(
        r.always(
          r.pipe(
            r.pick(['firstName', 'lastName', 'telephone', 'email']),
            // @ts-ignore
            r.mergeLeft(values.address),
            rA.renameKeys({
              firstName: 'forename',
              lastName: 'lastname',
              address1: 'address.line1',
              address2: 'address.line2',
              country: 'address.countryCode',
              city: 'address.city',
              region: 'address.region',
              postcode: 'address.postcode',
            }),

            // @ts-ignore
            rA.renameKeysWith(r.concat('domain.basic.', r.__)),
          )(values),
        ),
      ),
      // @ts-ignore
    )(fieldSets),
);

// gets the values from the api, but also does some magic
// like adding the GROUPED option, and putting fieldSets in there
export const getInitialValues = createSelector(
  getFieldValues,
  getFieldSets,
  getCDInitialValues,
  hasFieldValues,
  (values: RequiredValues, fieldSets: RequiredFieldSets, cdValues: Customer, hasFieldValues: boolean) => {
    const selectedValues = hasFieldValues ? values : cdValues;
    return r.pipe(
      r.values,
      r.reduce(r.mergeDeepRight, {}),
      r.assoc('GROUPED', r.__, selectedValues),
      dotize.convert,
      r.merge(
        r.pipe(
          r.keys,
          r.map((product: string) => [`${product}.fieldSets`, fieldSets[product]]),
          r.fromPairs,
        )(values),
      ),
      r.assoc(
        'GROUPED.fieldSets',
        r.pipe(
          r.values,
          r.flatten,
          // @ts-ignore
          r.uniq,
          // @ts-ignore
          r.reject(r.equals('domain.transfer')),
        )(fieldSets),
      ),
      undotize,
    )(selectedValues);
  },
);

export const getShouldUseAccount = (productId: string) =>
  createSelector<any, any, boolean>(getRawFormValues, r.path(['products', `${productId}`, 'shouldUseAccount']));
