import { Http, Method } from '@ux/drivers';
import { getBasketId } from 'domain/selectors/common';

import { AssignBasket } from '../../core/basket';

const assignBasket = (driver: Http): AssignBasket => ({ brandId }) => {
  const basketId = getBasketId();

  return driver({
    url: `/api/basket/api/v2/baskets/${basketId}/customer`,
    method: Method.POST,
    headers: {
      'X-Tenant': brandId,
    },
  });
};

export default assignBasket;
