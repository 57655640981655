export enum Status {
  REQUESTING,
  READY,
  FAILED,
}
export enum FetchStatus {
  PREFETCH = 'PREFETCH',
  FETCHED = 'FETCHED',
  FINISHED = 'FINISHED',
}
export const BASKET_COOKIE = 'basket';

export enum LabelStrategy {
  HIDDEN = '-1',
  OPTION = '1',
  CHOICE = '2',
  OPTION_AND_CHOICE = '0',
}
