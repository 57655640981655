import { createAction } from 'redux-actions';
import { Indexer } from '@uxdev/types';
import { Product } from '@ux/product';

import { CrossSell } from '../../core/cross-sells';
import { ProductId } from '../../core/basket';

const prefix = '@@uop/cross-sells/M_';
export const FETCHING = `${prefix}FETCHING`;
export const FETCHED = `${prefix}FETCHED`;
export const FETCH_FAILED = `${prefix}FETCH_FAILED`;
export const ADDING = `${prefix}ADDING`;
export const ADDED = `${prefix}ADDED`;
export const ADD_FAILED = `${prefix}ADD_FAILED`;

export const fetching = createAction(FETCHING);

export const fetched = createAction<{
  crossSells: Indexer<{}, CrossSell>;
  products: Indexer<{}, Product>;
}>(FETCHED);
export const fetchFailed = createAction(FETCH_FAILED);
export const adding = createAction<{
  productId: ProductId;
  index: number;
}>(ADDING);
export const added = createAction<{
  productId: ProductId;
  index: number;
}>(ADDED);
export const addFailed = createAction(
  ADD_FAILED,
  (err: any) => err,
  (err: any, productId: ProductId) => ({ productId }),
);

export type Fetching = ReturnType<typeof fetching>;
export type Fetched = ReturnType<typeof fetched>;
export type FetchFailed = ReturnType<typeof fetchFailed>;
export type Adding = ReturnType<typeof adding>;
export type Added = ReturnType<typeof added>;
export type AddFailed = ReturnType<typeof addFailed>;
