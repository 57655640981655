import { combineEpics } from 'redux-most';

import { Core } from '../../../core';
import fetch from './fetch';
import adClicked from './adClicked';
import followUpClicked from './followUpClicked';

export default (deps: Core) =>
  combineEpics([
    fetch(deps.confirmation.fetchConfirmation),
    adClicked(deps.events.dispatchGAEvent),
    followUpClicked(deps.events.dispatchGAEvent),
  ]);
