import { createEpic } from '@ux/fabric';
import { signals as modalSignals } from '@team-griffin/redux-modal-router';

import { Event } from '../../../domain/constants/events';
import { mapEvent } from '../../../domain/transformers/events';
import { HELP, Help } from '../../signals/details';
import { helpOpened, HelpOpened } from '../../../domain/messages/details';

const helpEpic = (dispatch: Function) =>
  createEpic({
    signal: HELP,
    process: (action: HelpOpened) => [
      dispatch(
        mapEvent({
          event: Event.FIELD_HELP,
          field: action.payload.field,
        }),
      ),
    ],
    onSuccess: (data: any, action: Help) => [
      modalSignals.open({
        route: 'FIELD_EXPLANATION',
      }),
      helpOpened({
        field: action.payload.field,
        productId: action.payload.productId,
      }),
    ],
  });

export default helpEpic;
