import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { authGuard, Pending } from '@ux/auth/idp';
import AsyncComponent from '@ux/async-component';
import AsyncPending from './Pending';

const importTransactionComplete = () =>
  import(
    /* webpackChunkName: 'checkout' */
    '../transactionComplete/ConnectedTransactionComplete'
  );

const PurePage = ({ theme }) => (
  <AsyncComponent Pending={AsyncPending} component={importTransactionComplete} theme={theme} />
);

const enhance = compose(setDisplayName('TransactionCompletePage'), authGuard());

export default enhance(PurePage);
