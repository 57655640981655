import React, { Fragment } from 'react';
import { mush, withHooks, GetInnerProps } from '@ux/pure-enhance';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import AsyncComponent from '@ux/async-component';
import { withProps } from '@team-griffin/rehook';
import { useTheme } from '@ux/theme-utils';
import { withBreakpointsHook } from '@ux/responsive';
import { authGuard } from '@ux/auth/idp';

import AsyncPending from './Pending';

const importConfirmation = () =>
  import(
    /* webpackChunkName: 'confirmation' */
    '../confirmation'
  );

interface OuterProps {}

export const enhance = mush<OuterProps>('ConfirmationPage')(
  authGuard(),
  withHooks(withProps(useTheme), withBreakpointsHook),
);

type InnerProps = GetInnerProps<typeof enhance>;

export const PureConfirmationPage = ({ theme, isMinSm, isMinMd, isMinLg }: InnerProps) => {
  const intl = useIntl();

  return (
    <Fragment>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'brand.metaTitle.template.structure',
            },
            {
              page: intl.formatMessage({
                id: 'uop.metaTitle.confirmation',
              }),
              brand: intl.formatMessage({
                id: 'brand.metaTitle.template.brand',
              }),
            },
          )}
        </title>
      </Helmet>
      <AsyncComponent
        Pending={AsyncPending}
        component={importConfirmation}
        theme={theme}
        isMinSm={isMinSm}
        isMinMd={isMinMd}
        isMinLg={isMinLg}
      />
    </Fragment>
  );
};

export default enhance(PureConfirmationPage);
