import { Http, Method } from '@ux/drivers';
import { getBasketId, getLanguageCookie } from 'domain/selectors/common';

import { UpdateBasketItem } from '../../core/basket';

const updateBasketItem = (driver: Http): UpdateBasketItem => ({ basketItemId, quantity = 1, ...args }) => {
  const basketId = getBasketId();
  const languageCookie = getLanguageCookie();

  return driver({
    url: `/api/basket/api/v2/baskets/${basketId}/items/${basketItemId}?localeCode=${languageCookie}`,
    method: Method.PUT,
    data: {
      ...args,
      quantity,
    },
  });
};

export default updateBasketItem;
