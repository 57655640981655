import { createSelector } from 'reselect';
import { createSelector as quickSelector } from '@ux/fabric';
import * as r from 'ramda';
import * as rA from 'ramda-adjunct';

import { State as RootState } from '../../reducers';
import { State } from '../../reducers/uop/account-credits';
import { getPreCreditGrossTotal } from '../basket';

const getState: (state: RootState) => State = r.path(['uop', 'accountCredits']);

export const hasFetched = quickSelector([getState], r.prop('fetched'));

export const getTotalCredits = createSelector<RootState, State, number>(
  [getState],
  r.pathOr(0, ['credits', 'available']),
);

export const getCredits = createSelector<RootState, number, number, number>(
  [getTotalCredits, (state) => getPreCreditGrossTotal(state)],
  r.ifElse(r.gt, r.nthArg(1), r.nthArg(0)),
);

export const getError = quickSelector([getState], r.prop('error'));

export const hasFetchFailed = r.pipe(getError, rA.isNotNil);
