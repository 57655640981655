import { Link, View, DispatchGAEvent, DispatchFBEvent } from 'core/events';
import { Cookies } from 'core/cookies';
import { combineEpics } from 'redux-most';
import { push } from 'connected-react-router';
import { createEpic, createStructuredSelector } from '@ux/fabric';
import { selectors as product } from '@ux/product';
import * as mA from 'most-adjunct';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { BASKET_COOKIE } from 'domain/constants/basket';
import { State as RootState } from 'domain/reducers';
import { getCredits } from 'domain/selectors/account-credits';
import { getCurrency } from 'domain/selectors/common';
import { getLanguage, getRoutes } from 'domain/selectors/routes';
import { Event } from 'domain/constants/events';
import { mapEvent } from 'domain/transformers/events';
import * as basketSelectors from 'domain/selectors/basket';
import * as voucherSelectors from 'domain/selectors/vouchers';
import * as signals from 'application/signals/payment';
import * as messages from 'domain/messages/payment';

import { PageType, pageView } from '../pageViewConstructor';
import { getGAInfoNcEc } from '../confirmation/utils';

// because this is post-transaction and the basket no longer exists
// we ignore the redux state and use a temporary copy of the state instead
type GetState = () => RootState;
const createGetState = (window: Window): GetState => () => {
  return JSON.parse(window.sessionStorage.getItem('post-transaction-state'));
};

const waitForBasket = (getState: GetState) =>
  mA.waitUntil(() => {
    try {
      getState();
      return true;
    } catch (e) {
      return false;
    }
  }, 100);

const completeEpic = (getState: GetState) =>
  createEpic({
    signal: signals.COMPLETE_TRANSACTION,
    process: () => {
      return waitForBasket(getState);
    },
    onSuccess: (x, action: signals.CompleteTransaction) => {
      return messages.transactionComplete({
        orderId: action.payload.orderId,
      });
    },
  });

export const transactionCompleteEpic = (
  link: Link,
  dispatchFBEvent: DispatchFBEvent,
  getState: GetState,
  cookies: Cookies,
) =>
  createEpic({
    signal: messages.TRANSACTION_COMPLETE,
    selector: () =>
      createStructuredSelector({
        total: basketSelectors.getTotal,
        tax: basketSelectors.getTotalTax,
        items: basketSelectors.getBasketItems,
        brandId: wlSelectors.getBrandId,
        currency: getCurrency,
        includeTax: product.shouldIncludeTax,
        isNewCustomer: basketSelectors.getIsNewCustomer,
      })(getState()),
    process: (action: messages.TransactionComplete, state) => {
      const {
        payload: { orderId },
      } = action;
      const { brandId, currency, items, tax, total, includeTax, isNewCustomer } = state;

      cookies.eraseCookie(BASKET_COOKIE);

      /**
       * Sending event for tealium for confirmation page
       */
      /* eslint-disable camelcase */
      const { conversion_label } = getGAInfoNcEc(brandId, isNewCustomer);

      /**
       * Google "Analytics" value for New and Existing customers.
       */
      let customerTypeForAnalytics = isNewCustomer ? 'New' : 'Existing';

      if (typeof isNewCustomer !== 'boolean') {
        customerTypeForAnalytics = 'Unknown';
      }

      const customer_type = customerTypeForAnalytics;

      const data = {
        'tealium_event': 'purchase',
        'currency': currency,
        'transaction.id': orderId,
        'transaction.total': `${total}`,
        'transaction.tax': `${tax}`,
        'transaction.currency': currency,
        'transaction.product.id': items?.map((item) => item.skuId),
        // @ts-ignore
        'transaction.product.name': items?.map((item) => item.name),
        'transaction.product.brand': items?.map(() => brandId),
        // @ts-ignore
        'transaction.product.category': items?.map((item) => item?.product?.categoryName),
        'transaction.product.price': items.map((item) => {
          const price = product.getPrice({
            product: item.product,
            includeTax,
            term: item.term,
            interval: item.paymentInterval,
          });
          return `${price}`;
        }), //r.map(r.pipe(
        'transaction.product.quantity': items.map((item) => item.quantity),
        'transaction.product.term': items.map((item) => item.term),
        'transaction.voucher': '',

        'transaction.product.paymentInterval': items.map((item) => item.paymentInterval),
        'user.customertype': 'Customer',
        customer_type,
        // GA4 product variant title
        'transaction.product.variant': items?.map((item) => `${item.name} ${item.term}`),
      } as any;

      /**
       * Send Google Adwords event only if the 'conversion_id' and 'conversion_label'
       * for that brand are not falsy. (More details inside the 'getGAInfoNcEc' function.)
       * This will avoid empty GA function call.
       *
       * null|undefined could be possible values and we should skip them as it
       * might be related to anonymous users.
       */
      if (typeof isNewCustomer === 'boolean' && conversion_label) {
        data['transaction.conversion_value'] = total;
        data['transaction.conversion_label'] = conversion_label;
      }

      dispatchFBEvent('Purchase', {
        /* eslint-disable camelcase */
        // @ts-ignore
        content_name: items.map((item) => item.name),
        content_ids: items.map((item) => item.skuId),
        content_type: items.map((item) => item.product?.categoryName),
        currency: currency,
        num_items: items.map((item) => item.quantity).reduce((previous, current) => previous + current, 0),
        value: `${total > 0 ? total.toFixed(2) : (0).toFixed(2)}`,
        new_customer: isNewCustomer,
        order_id: orderId,
        /* eslint-enable camelcase */
      });
      link(data);
    },
  });

const pageViewEpic = (view: View, window: Window, getState: GetState) =>
  createEpic({
    signal: messages.TRANSACTION_COMPLETE,
    selector: () =>
      createStructuredSelector({
        subtotal: basketSelectors.getSubtotal,
        tax: basketSelectors.getTotalTax,
        total: basketSelectors.getTotal,
        items: basketSelectors.getBasketItems,
        currency: getCurrency,
        adjustments: basketSelectors.getAdjustments,
        voucher: voucherSelectors.getVoucherTotal,
        includeTax: product.shouldIncludeTax,
        language: getLanguage,
      })(getState()),
    process: (action, state) => {
      const {
        payload: { orderId },
      } = action;
      pageView(
        {
          ...state,
          affiliation: window.location.host,
          orderId,
        },
        window,
        view,
        PageType.COMPLETE,
      );
    },
  });

const trackCreditsEpic = (dispatchGAEvent: DispatchGAEvent, getState: GetState) =>
  createEpic({
    signal: messages.TRANSACTION_COMPLETE,
    selector: () =>
      createStructuredSelector({
        credits: getCredits,
      })(getState()),
    process: (action, state) => {
      const { credits } = state;
      if (credits) {
        dispatchGAEvent(
          mapEvent({
            event: Event.PAYMENT_CREDITS_USED,
            value: `${credits * 100}`,
          }),
        );
      }
    },
  });

const redirectEpic = (getState: GetState) =>
  createEpic({
    signal: messages.TRANSACTION_COMPLETE,
    selector: () => {
      const state = getState();
      const routes = getRoutes(state);
      const { confirmation: url } = routes;

      return { url };
    },
    onSuccess: (x, action: messages.TransactionComplete, state: { url: string }) => {
      return push(`${state.url}?order=${action.payload.orderId}`);
    },
  });

export default (
  link: Link,
  view: View,
  dispatchFBEvent: DispatchFBEvent,
  dispatchGAEvent: DispatchGAEvent,
  cookies: Cookies,
  window: Window,
) => {
  const getState = createGetState(window);
  return combineEpics([
    completeEpic(getState),
    transactionCompleteEpic(link, dispatchFBEvent, getState, cookies),
    pageViewEpic(view, window, getState),
    trackCreditsEpic(dispatchGAEvent, getState),
    redirectEpic(getState),
  ]);
};
