import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import * as rk from '@ux/reactive-knowledge';
import { selectors as product } from '@ux/product';

import { PurchaseBoltOns } from '../../../core/bolt-ons';
import { DispatchGAEvent, Ecommerce } from '../../../core/events';
import { Event } from '../../../domain/constants/events';
import { getProduct, getSkuId } from '../../../domain/selectors/products';
import { State as RootState } from '../../../domain/reducers';
import * as signals from '../../signals/bolt-ons';
import * as messages from '../../../domain/messages/bolt-ons';
import { mapEvent } from '../../../domain/transformers/events';
import { handleAuth, handleEsocketError } from '../utils';

const purchaseEpic = (purchase: PurchaseBoltOns) =>
  createEpic({
    signal: signals.PURCHASE_BOLT_ON,
    selector: (state: any, action) => ({
      skuId: getSkuId(action.payload.productId)(state),
    }),
    pending: (action: signals.PurchaseBoltOn) => messages.adding(action.payload),
    process: (action: signals.PurchaseBoltOn, { skuId }) => {
      return purchase({
        items: [
          {
            boltOnRefOrderItemId: action.payload.basketItemId,
            skuId: skuId,
            term: action.payload.term,
            paymentInterval: action.payload.paymentInterval,
            quantity: action.payload.quantity ?? 1,
          },
        ],
      });
    },
    onSuccess: (data, action) => [
      messages.added(action.payload),
      rk.signals.add({
        type: rk.KnowledgeType.SMIRK,
        message: 'uop.basket.item.boltOn.purchase.success',
      }),
    ],
    onFailure: handleEsocketError(
      handleAuth((err, action) => [
        messages.addFailed(err, action.payload),
        rk.signals.add({
          type: rk.KnowledgeType.ERK,
          message: 'uop.basket.add.failed',
        }),
      ]),
    ),
    join: true,
  });

const gabAnalyticsEpic = (dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  createEpic({
    signal: signals.PURCHASE_BOLT_ON,
    filter: (action: signals.PurchaseBoltOn) => {
      return action.payload.auto;
    },
    process: (action: signals.PurchaseBoltOn, state: RootState, actions$, store) => {
      const o = {
        product: getProduct(action.payload.productId)(state),
      };
      const name = product.getName(o);
      const category = product.getCategory(o);
      const sku = action.payload.productId;
      const list = 'bolt-on (offers)';

      return createEpic({
        signal: messages.ADDED,
        filter: (action2: messages.Added) => {
          return action.payload.basketItemId === action2.payload.basketItemId;
        },
        process: () => {
          ecommerce([
            [
              'addProduct',
              {
                id: sku,
                name: `${name} (auto-added)`,
              },
            ],
            [
              'setAction',
              'add',
              {
                list,
                category,
              },
            ],
          ]);

          dispatch(
            mapEvent({
              event: Event.ADD_GAB,
              sku,
            }),
          );
        },
      })(actions$, store);
    },
  });

export default (purchase: PurchaseBoltOns, dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  combineEpics([purchaseEpic(purchase), gabAnalyticsEpic(dispatch, ecommerce)]);

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  purchaseEpic,
};
