import { Http, Method } from '@ux/drivers';
import { createSequentialQueue } from '@ux/queue';
import { getBasketId, getLanguageCookie } from 'domain/selectors/common';

import { PurchaseBoltOns } from '../../core/bolt-ons';

const purchaseBoltOn = (driver: Http): PurchaseBoltOns => {
  // because PPE can't handle adding multiple items at once
  // we have to queue up each add request and send them one
  // at a time :(
  const action: PurchaseBoltOns = ({ items }) => {
    const basketId = getBasketId();
    const languageCookie = getLanguageCookie();

    return driver({
      url: `/api/basket/api/v2/baskets/${basketId}/items?localeCode=${languageCookie}`,
      method: Method.POST,
      data: {
        items,
      },
    });
  };

  return createSequentialQueue(action);
};

export default purchaseBoltOn;
