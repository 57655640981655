import { createSelector as quickSelector } from '@ux/fabric';
import { selectors as wlSelectors } from '@ux/whitelabel';
import deepExtend from 'deep-extend';

import { State as RootState } from '../../reducers';

const getState = (state: RootState) => state.uop.paymentTokens;
const getStateV2 = (state: RootState) => state.uop.paymentTokensV2;

//We only need one type of fetch to be done to be able to show something
export const hasFetched = quickSelector([getState, getStateV2], (a, b) => a.fetched || b.fetched);

export const getTokens = quickSelector([getState, getStateV2], (a, b) => {
  return deepExtend({}, a.tokens, b.tokens);
});

export const getCards = quickSelector([getTokens], (tokens) => {
  return Object.values(tokens.cards).sort((a, b) => {
    const isAExpired = a.expired ? 1 : -1;
    const isBExpired = b.expired ? 1 : -1;
    return isAExpired - isBExpired;
  });
});

export const getPaypal = quickSelector([getTokens], (tokens) => {
  const vals = Object.values(tokens.paypal);
  const valsLength = vals.length;
  if (!valsLength) return null;
  return vals[vals.length - 1];
});

export const getDirectDebit = quickSelector([getTokens], (tokens) => {
  const vals = Object.values(tokens.debits);
  const valsLength = vals.length;
  if (!valsLength) return null;
  return vals[vals.length - 1];
});

export const getSepas = quickSelector([getTokens], (tokens) => {
  return Object.values(tokens.sepa).sort((a, b) => {
    const isAAddedd = a.addeded ? 1 : -1;
    const isBAddeded = b.addeded ? 1 : -1;
    return isAAddedd - isBAddeded;
  });
});

export const getSepa = quickSelector([getSepas], (sepas) => {
  const sepasLength = sepas.length;
  if (sepasLength === 0) return null;
  return sepas[sepasLength - 1];
});

export const getError = quickSelector([getState, getStateV2], (a, b) => {
  const legacyError = a.error;
  const error = b.error;
  if (legacyError && error) {
    return legacyError;
  }
});

export const hasFetchFailed = quickSelector([getError], (error) => Boolean(error));

//we just need something that's not in the normal
//brand config for some reason, possibly not updated
interface ExtendedBrandConfig {
  [key: string]: any;
  payment?: {
    alwaysNewPaypalToken?: boolean;
    adyen?: boolean;
  };
}

export const getAlwaysNewPaypal = quickSelector<any, ExtendedBrandConfig, boolean>(
  [wlSelectors.getBrandConfig],
  (brand: ExtendedBrandConfig) => brand?.payment?.alwaysNewPaypalToken || false,
);

export const isAdyenProvider = quickSelector([wlSelectors.getBrandConfig], (brand: ExtendedBrandConfig) => {
  return brand?.payment?.adyen === true;
});
