import { Http } from '@ux/drivers';

import _fetchTokens from './fetchTokens';
import _postPayment from './postPayment';
import _fetchTokensV2 from './fetchTokensV2';
import _fetchProvider from './fetchProviderToken';
import _process3DSecure from './process3DSecure';

export default (json: Http, document: Document) => {
  return {
    postPayment: _postPayment(json),
    fetchPaymentTokens: _fetchTokens(json),
    fetchPaymentTokensV2: _fetchTokensV2(json),
    fetchProviderToken: _fetchProvider(json),
    process3DSecure: _process3DSecure(document),
  };
};
