export enum ProductStatus {
  DEFAULT = 'DEFAULT',
  REQUESTING = 'REQUESTING',
  UPDATING = 'UPDATING',
  UPDATED = 'UPDATED',
  ADDING = 'ADDING',
  ADDED = 'ADDED',
  REMOVING = 'REMOVING',
  REMOVED = 'REMOVED',
  ERROR = 'ERROR',
}
