import React, { useEffect, lazy, Suspense } from 'react';
import { withProps, tap } from '@team-griffin/rehook';
import { mush, withHooks, GetInnerProps, Hoc } from '@ux/pure-enhance';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { replace } from 'connected-react-router';
import { connect } from '@ux/fabric';
import { authGuard } from '@ux/auth/idp';
import { createStructuredSelector } from 'reselect';

import { getNextUrl } from '../../../domain/selectors/common';
import * as selectors from '../../../domain/selectors/pages/details';
import { Status } from '../../../domain/constants/details';
import useEnsureStartUrl from '../../hooks/useEnsureStartUrl';
import AsyncPending from './Pending';

const Details = lazy(
  () =>
    import(
      /* webpackChunkName: 'details' */
      '../requiredInformation'
    ),
);

const mapState = createStructuredSelector({
  status: selectors.getStatus,
  showPage: selectors.showPage,
  nextUrl: getNextUrl, // getScreenUrl('payment'),
});
const mapActions = { replace };

const enhance = mush('DetailsPage')(
  authGuard() as Hoc<{}, {}>,
  withHooks(
    withProps(connect(mapState, mapActions)),
    tap((props) => {
      const {
        nextUrl,
        status,
        actions: { replace },
      } = props;

      useEnsureStartUrl(status === Status.REQUESTING);

      useEffect(() => {
        if (status === Status.NONE_REQUIRED) {
          replace({ pathname: nextUrl });
        }
      }, [status, replace, nextUrl]);
    }),
  ),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureDetailsPage = ({ showPage }: InnerProps) => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'brand.metaTitle.template.structure',
            },
            {
              page: intl.formatMessage({
                id: 'uop.metaTitle.details',
              }),
              brand: intl.formatMessage({
                id: 'brand.metaTitle.template.brand',
              }),
            },
          )}
        </title>
      </Helmet>
      <Choose>
        <When condition={showPage}>
          <Suspense fallback={<AsyncPending />}>
            <Details />
          </Suspense>
        </When>
        <Otherwise>
          <AsyncPending />
        </Otherwise>
      </Choose>
    </>
  );
};

export default enhance(PureDetailsPage);
