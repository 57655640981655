import { createAction } from 'redux-actions';

import { SkuId, ProductId, BasketItemId, DomainType } from '../../core/basket';

// Action Types
const prefix = '@@uop/basket/S_';
export const FETCH = `${prefix}FETCH`;
export const ADD = `${prefix}ADD`;
export const REMOVE = `${prefix}REMOVE`;
export const ADD_GAB = `${prefix}ADD_GAB`;
export const REMOVE_GAB = `${prefix}REMOVE_GAB`;
export const CHANGE_TERM = `${prefix}CHANGE_TERM`;

// Action Creators
export const fetch = createAction(FETCH);
export const add = createAction<{
  productId: ProductId;
  term?: number;
  paymentInterval?: number;
  quantity?: number;
}>(ADD);
export const remove = createAction<{
  basketItemId: BasketItemId;
}>(REMOVE);
export const addGab = createAction<{
  productId: ProductId;
}>(ADD_GAB);
export const removeGab = createAction<{
  productId: ProductId;
  basketItemIds: BasketItemId[];
}>(REMOVE_GAB);
export const changeTerm = createAction<{
  basketItemId: BasketItemId;
  skuId: SkuId;
  term: number;
  paymentInterval: number;
  quantity?: number;
  addOnItems?: Array<{
    skuId: number;
    quantity: number;
  }>;
  optionValueIds?: Array<number>;
}>(CHANGE_TERM);

export const changeDomainTerm = createAction<{
  basketItemId: BasketItemId;
  domainName: string;
  type: DomainType;
  term: number;
  paymentInterval: number;
  quantity?: number;
  boltOnSkuIds?: Array<number>;
}>(CHANGE_TERM);

// Action Payloads
export type Fetch = ReturnType<typeof fetch>;
export type Add = ReturnType<typeof add>;
export type Remove = ReturnType<typeof remove>;
export type AddGab = ReturnType<typeof addGab>;
export type RemoveGab = ReturnType<typeof removeGab>;
export type ChangeTerm = ReturnType<typeof changeTerm | typeof changeDomainTerm>;
