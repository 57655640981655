import { Http } from '@ux/drivers';
import { FullyQualifyPath } from 'core/navigation';

import _fetchBasket from './fetchBasket';
import _addToBasket from './addToBasket';
import _removeFromBasket from './removeFromBasket';
import _updateBasketItem from './updateBasketItem';
import _orderBasket from './orderBasket';
import _assignBasket from './assignBasket';

export default (json: Http, fullyQualifyPath: FullyQualifyPath) => {
  const fetchBasket = _fetchBasket(json);
  const addToBasket = _addToBasket(json);
  const removeFromBasket = _removeFromBasket(json);
  const updateBasketItem = _updateBasketItem(json);
  const orderBasket = _orderBasket(json, fullyQualifyPath);
  const assignBasket = _assignBasket(json);

  return {
    fetchBasket,
    addToBasket,
    removeFromBasket,
    updateBasketItem,
    orderBasket,
    assignBasket,
  };
};
