import React, { Fragment, lazy, Suspense } from 'react';
import { mush, withHooks, GetInnerProps } from '@ux/pure-enhance';
import { withProps, tap } from '@team-griffin/rehook';
import { connect } from '@ux/fabric';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import * as selectors from '../../../domain/selectors/pages/basket';
import useEnsureStartUrl from '../../hooks/useEnsureStartUrl';
import Pending from './Pending';

const Basket = lazy(
  () =>
    import(
      /* webpackChunkName: 'basket' */
      '../basket'
    ),
);

interface OuterProps {}

const mapState = createStructuredSelector({
  status: selectors.getStatus,
  showPage: selectors.showPage,
});

const enhance = mush<OuterProps>('BasketPage')(
  withHooks(
    withProps(connect(mapState)),
    tap(() => useEnsureStartUrl()),
  ),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureBasketPage = ({ showPage }: InnerProps) => {
  const intl = useIntl();

  return (
    <Fragment>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'brand.metaTitle.template.structure',
            },
            {
              page: intl.formatMessage({
                id: 'uop.metaTitle.basket',
              }),
              brand: intl.formatMessage({
                id: 'brand.metaTitle.template.brand',
              }),
            },
          )}
        </title>
      </Helmet>
      <Choose>
        <When condition={showPage}>
          <Suspense fallback={<Pending />}>
            <Basket />
          </Suspense>
        </When>
        <Otherwise>
          <Pending />
        </Otherwise>
      </Choose>
    </Fragment>
  );
};

export default enhance(PureBasketPage);
