import * as most from 'most';
import * as mA from 'most-adjunct';
import { select, combineEpics } from 'redux-most';
import { getFormValues, getFormAsyncErrors, actionTypes, change } from 'redux-form';
import { yupErrorToReduxFormError } from '@ux/form-utils';
import * as r from 'ramda';
import { createEpic } from '@ux/fabric';
import * as mostc from 'mostc';
import { getEligibleFieldsByRegistrantType } from 'domain/selectors/details/registrarUk';
import { isGrouped as getIsGrouped } from 'domain/selectors/details/form';

import Scroller from '../../../core/external';
import { dotize } from '../../../crosscutting/ramda';
import { VALIDATE, Validate } from '../../../application/signals/details';
import {
  VALIDATE_SUCCEEDED,
  VALIDATE_FAILURE,
  validating,
  validateSucceeded,
  validateFailure,
  ValidateSucceeded,
  ValidateFailure,
} from '../../../domain/messages/details';
import validate from '../../../domain/logic/details/validate';
import { FieldSets } from '../../../domain/constants/details';
import { State as RootState } from '../../../domain/reducers';

const getRequiredInfoValues = getFormValues('uop.requiredInformation');

export const validateEpic = createEpic({
  signal: VALIDATE,
  pending: validating,
  process: (action, state) => {
    const allValues = getRequiredInfoValues(state);
    const eligibleFieldsByRegistrantType = getEligibleFieldsByRegistrantType(state);

    return validate(true, allValues, eligibleFieldsByRegistrantType);
  },
  onSuccess: validateSucceeded,
  onFailure: validateFailure,
});

// TODO: use createEpic

export const validateHandleEpic = (actions$: most.Stream<Validate | ValidateSucceeded | ValidateFailure>) =>
  r.pipe(
    select(VALIDATE),
    mA.switchMap((action: Validate) => {
      return most.merge(
        // @ts-ignore
        r.pipe(
          select(VALIDATE_SUCCEEDED),
          mostc.tap(() => action.meta.resolve()),
          mA.ignoreElements,
        )(actions$),
        r.pipe(
          select(VALIDATE_FAILURE),
          mostc.tap((err: ValidateFailure) => {
            action.meta.reject(yupErrorToReduxFormError(err.payload));
          }),
          mostc.map((action: ValidateFailure) => {
            const errors = action?.payload?.inner ?? [];
            // check if customer has invalid account details
            const hasInvalidAccountDetails =
              errors.filter((err: { path?: string }) => err.path?.includes('customer')).length > 0;
            return most.of(change('uop.requiredInformation', '__invalid_account_details__', hasInvalidAccountDetails));
          }),
          mostc.recoverWith(r.always(most.of({}))),
          most.switchLatest,
        )(actions$),
      );
    }),
  )(actions$);

export const scrollToFieldEpic = (scroller: Scroller) => (
  actions$: most.Stream<ValidateFailure>,
  store: {
    getState: () => RootState;
  },
) =>
  r.pipe(
    select(actionTypes.SET_SUBMIT_FAILED),
    mostc.tap(() => {
      setTimeout(() => {
        const state = store.getState();
        const errors = getFormAsyncErrors('uop.requiredInformation')(state);
        const isGrouped = getIsGrouped(state);
        const fields = r.pipe(
          r.propOr({}, 'products'),
          // @ts-ignore
          dotize,
          r.keys,
        )(errors);
        const fieldSets = r.keys(FieldSets);
        const customer = r.pipe(
          r.propOr({}, 'customer'),
          // @ts-ignore
          dotize,
          r.keys,
        )(errors);

        r.find(
          (fullFieldName: string): any => {
            if (fullFieldName.includes('accountDetails')) {
              return r.find((set): any => {
                const setFields = FieldSets[set];
                return r.find((setField: string) => {
                  const name = `${set}.${setField}`;
                  const scrollToElement = `customer.${name}`;
                  if (fullFieldName === name) {
                    scroller.scrollTo(scrollToElement, {
                      duration: 1000,
                      smooth: true,
                    });
                    return true;
                  }
                }, setFields);
              }, fieldSets);
            }

            const product = r.pipe(r.split('.'), r.head, r.defaultTo(''))(fullFieldName);

            return r.find((set): any => {
              const setFields = FieldSets[set];
              return r.find((setField: string) => {
                const name = `${product}.${set}.${setField}`;
                const scrollToElement = isGrouped
                  ? `products.GROUPED.${set}.${setField}`
                  : `products.${product}.${set}.${setField}`;

                if (fullFieldName === name) {
                  scroller.scrollTo(scrollToElement, {
                    duration: 1000,
                    smooth: true,
                  });
                  return true;
                }
              }, setFields);
            }, fieldSets);
          },
          [...customer, ...fields],
        );
      }, 0);
    }),
    mA.ignoreElements,
  )(actions$);

export default (scroller: Scroller) =>
  combineEpics([
    validateEpic,
    // @ts-ignore
    validateHandleEpic,
    // @ts-ignore
    scrollToFieldEpic(scroller),
  ]);
