import { createStore, applyMiddleware } from 'redux';
import createReducer from 'domain/reducers';
import { initialize } from 'application/signals/root';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
// @TODO: We probably only want to output in development
// redux-devtools-extension/developmentOnly
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createEpicMiddleware from 'application/epicMiddleware';
import createAppEpics from 'application/epics';
import { middleware as upmMiddleware } from '@pex/upm';

import createInfrastructure from './infrastructure/composite';

export default () => {
  const history = createBrowserHistory();
  const reducer = createReducer(history);
  const routerMiddlewareInstance = routerMiddleware(history);
  const infrastructure = createInfrastructure();
  // compose domain
  const epics = createAppEpics(infrastructure);
  // pass epics into epic middleware
  const epicMiddleware = createEpicMiddleware(epics);

  const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(epicMiddleware, upmMiddleware, routerMiddlewareInstance)),
  );

  return {
    store,
    history,
    init: () => store.dispatch(initialize()),
  };
};
