import * as r from 'ramda';
import * as most from 'most';
import { handleAuth as baseHandleAuth } from '@ux/auth/idp';
import { streamify } from '@ux/fabric';

import { fetch } from '../../application/signals/root';
import errorMap from '../../domain/transformers/error';
import * as Error from '../../domain/constants/error';

const authTest = r.either(r.propEq('code', Error.AUTH_ERROR), r.propEq('code', Error.AUTH_INVALID));

// if the api returns an auth error code, we want to redirect to sso
export const handleAuth = (fn: (...args: any[]) => any) => baseHandleAuth(fn, authTest);

// if the api returns an esocket timeout error we want to re-fetch the dataset to ensure we're
// not out of sync
export const handleEsocketError = (fn: (...args: any[]) => any) => (...args: any[]) => {
  const err = args[0];
  if (err && err.code && err.code === Error.ESOCKET_TIMEOUT) {
    const fetch$ = most.of(fetch());
    const fallback$ = streamify(fn(...args));
    return most.merge(fallback$, fetch$);
  }
  return fn(...args);
};

export const serverErrorToIntlObj = (
  fallback: string | Array<{ id: string }> = 'uop.errors.unknown',
  intlNamespace: string = '',
): ((err: any) => any) =>
  r.pipe(
    r.converge(r.ifElse(r.is(Function), r.call, r.identity), [
      r.pipe(r.prop('code'), r.prop(r.__, errorMap)),
      r.identity,
      r.always(intlNamespace),
    ]),
    r.when(r.isNil, r.always(fallback)),
    r.when(r.is(String), r.objOf('id')),
  );

export const serverErrorToIntlId = (
  fallback: string | Array<{ id: string }> = 'uop.errors.unknown',
  intlNamespace: string = '',
) =>
  r.pipe(
    serverErrorToIntlObj(fallback, intlNamespace),
    r.when(r.is(Object), r.prop('id')),
    // if the above object doesn't have an id, we need to fallback once again
    r.when(r.isNil, r.always(fallback)),
  );
