import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { authGuard, Pending } from '@ux/auth/idp';
import AsyncComponent from '@ux/async-component';
import AsyncPending from './Pending';

const importTransactionFailed = () =>
  import(
    /* webpackChunkName: 'checkout' */
    '../transactionFailed/ConnectedTransactionFailed'
  );

export const PureBasketPage = ({ theme }) => (
  <AsyncComponent Pending={AsyncPending} component={importTransactionFailed} theme={theme} />
);

export const enhance = compose(setDisplayName('TransactionFailedPage'), authGuard());

export default enhance(PureBasketPage);
