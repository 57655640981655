import { Indexer } from '@uxdev/types';
import { createSelector as quickSelector } from '@ux/fabric';
import { createSelector } from 'reselect';
import { selectors as prodSelectors } from '@ux/product';
import * as c from '@team-griffin/capra';
import * as r from 'ramda';
import { ProductId } from 'core/basket';
import { CrossSell } from 'core/cross-sells';

import { State as RootState } from '../../reducers';
import { State } from '../../reducers/uop/cross-sells';
import { ProductStatus } from '../../constants/product';
import { getProduct as getRawProduct } from '../products';

const getState: (state: RootState) => State = r.path(['uop', 'crossSells']);

export const hasFetched = quickSelector([getState], r.prop('fetched'));

export const hasFailed = quickSelector([getState], r.propSatisfies(c.isNotNilOrEmpty, 'error'));

export const isFetching = quickSelector([getState], r.prop('fetching'));

const getProduct = (state: State) => (id: number) => getRawProduct(id)(state);
export const getCrossSells = createSelector([getState, getProduct], (state, getProduct) =>
  r.pipe<State, Indexer<{}, CrossSell>, CrossSell[], CrossSell[]>(
    r.prop('crossSells'),
    r.values,
    r.map((item) => {
      const productId = (item.product as unknown) as number;
      const product = getProduct(productId);
      return r.assoc('product', product, item);
    }),
  )(state),
);

export const getCrossSellIds = createSelector([getCrossSells], r.map<CrossSell, number>(r.prop('skuId')));

export const getCrossSell = (id: ProductId) =>
  quickSelector<RootState, CrossSell[], CrossSell>([getCrossSells], r.find(r.propEq('skuId', id)));

export const getProductId = (id: number) => quickSelector([getCrossSell(id)], prodSelectors.getId);

export const getPrice = (id: ProductId) =>
  createSelector([getCrossSell(id), prodSelectors.shouldIncludeTax], ({ product }, includeTax) =>
    prodSelectors.getPrice({
      displayInterval: 1,
      includeTax,
      product: product,
    }),
  );

export const getCategory = (id: ProductId) =>
  createSelector([getCrossSell(id)], ({ product }) => prodSelectors.getCategory({ product }));

export const getName = (id: ProductId) =>
  createSelector([getCrossSell(id)], ({ product }) => prodSelectors.getName({ product }));

export const getSummary = (id: ProductId) => quickSelector([getCrossSell(id)], prodSelectors.getTagline);

const getKeywords = (id: ProductId) =>
  createSelector(
    [getCrossSell(id)],
    r.pipe(r.path(['product', 'keywords']), r.defaultTo(''), r.split(','), r.map(r.trim)),
  );

const illustrationMatrix = {
  'domain': 'bannerDomains',
  'vps': 'bannerServers',
  'security': 'bannerSecurity',
  'website-builder': 'bannerWebsiteBuilder',
  'email': 'bannerEmail',
  'server': 'bannerServers',
  'hosting': 'bannerHosting',
};
export const getIllustration = (id: ProductId) =>
  quickSelector(
    [getKeywords(id)],
    r.pipe<string[], string, string>(
      // @ts-ignore
      r.find(r.prop(r.__, illustrationMatrix)),
      r.prop(r.__, illustrationMatrix),
    ),
  );

export const getPendingStates = createSelector([getState], (state) => state.pendingStates);
const getPendingState = (id: ProductId) => quickSelector([getPendingStates], (pendingStates) => pendingStates?.[id]);

export const getStatus = (id: ProductId) =>
  createSelector([hasFetched, hasFailed, getPendingState(id)], (fetched, failed, pending) => {
    if (failed) {
      return ProductStatus.ERROR;
    }
    if (!fetched) {
      return ProductStatus.REQUESTING;
    }
    if (pending) {
      return pending;
    }
    return ProductStatus.DEFAULT;
  });

const getAllStatuses = createSelector([getCrossSellIds, getPendingStates], (ids, pendingStates) => {
  return ids.map((id) => pendingStates[id] || ProductStatus.DEFAULT);
});
export const hasCrossSells = quickSelector<any, any[], boolean>([getAllStatuses], c.hasLength);

// are there any cross sells that haven't already been added to the basket?
export const hasAvailableCrossSells = createSelector([getAllStatuses], r.any(r.equals(ProductStatus.DEFAULT)));
