import { createEpicMiddleware, combineEpics, Epic } from 'redux-most';
import { epic as authEpic } from '@ux/auth/idp';
import { epics as reactiveKnowledgeEpics } from '@ux/reactive-knowledge';
import { epics as orchestrationEpics } from '@ux/orchestration';
import { epic as modalEpics } from '@team-griffin/redux-modal-router';
import { epics as whitelabelEpics } from '@ux/whitelabel';
import { epic as pageLoaderEpic } from '@team-griffin/redux-page-loader';
import { epic as tagmgEpic } from '@ux/tagmg';
import { epic as languageEpics } from '@ux/language';
import { epic as scrollEpic } from '@ux/auto-scroll';
import { epic as currencyEpic } from '@ux/currency';

declare const APP_NAME: string;

export default (appEpics: Epic<any, any>) =>
  createEpicMiddleware(
    combineEpics([
      appEpics,
      authEpic(APP_NAME),
      whitelabelEpics(['brand', 'uop']),
      languageEpics(['uop', 'bks', 'ux', 'brand', 'country', 'pageTitles']),
      orchestrationEpics(),
      reactiveKnowledgeEpics(),
      modalEpics,
      pageLoaderEpic,
      tagmgEpic({ pageviewTest: () => false }),
      scrollEpic(),
      currencyEpic(),
    ]),
  );
