import React from 'react';
import { useStyles } from '@team-griffin/stylr';
import Message from '@ux/message';
import { ModalHeader } from '@ux/modal';
import { FormattedMessage } from 'react-intl';
import { Paragraph } from '@ux/dom';
import { Button, Kind } from '@ux/button';
import cssSides, { Side } from '@team-griffin/css-sides';
import { withBreakpointsHook } from '@ux/responsive';
import { Heading06 } from '@ux/typography';
import * as r from 'ramda';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import { withProps } from '@team-griffin/rehook';
import { useTheme, gu2px } from '@ux/theme-utils';

export interface OuterProps {
  onCloseClick: () => void;
  field: string;
  productId: number | string;
  products: string[];
}

const useStylesheet = ({ theme, isMinMd }: any) => {
  const { spacing } = theme;

  return useStyles(
    {
      default: {
        body: {
          ...cssSides('padding', Side.A, gu2px(3, spacing)),
        },
        title: {
          marginBottom: gu2px(2, spacing),
        },
      },

      isMinMd: {
        actions: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    [{ isMinMd }],
  );
};

export const enhance = puree<OuterProps>('FieldExplanationModal')(
  withProps(useTheme),
  withBreakpointsHook,
  withProps((props) => useStylesheet(props)),
);

type InnerProps = GetInnerProps<typeof enhance>;

export const PureFieldExplanationModal = ({ theme, styles, onCloseClick, field, products }: InnerProps) => (
  <div data-test-id="field_explanation_modal">
    <ModalHeader dismissable={true} onCloseClick={onCloseClick}>
      <FormattedMessage id={`uop.requiredInformation.explanation.${field}.title`} />
    </ModalHeader>
    <div style={styles.body}>
      <Heading06 theme={theme} style={styles.title} data-test-id="field_explanation_modal_products">
        {r.join(', ', products)}
      </Heading06>
      <Paragraph theme={theme}>
        <FormattedMessage id={`uop.requiredInformation.explanation.${field}.text`} />
      </Paragraph>
      <div style={styles.actions}>
        <Button kind={Kind.PRIMARY} onClick={onCloseClick} block={true} data-test-id="field_explanation_modal_confirm">
          <Message
            cascade={[
              {
                id: `uop.requiredInformation.explanation.${field}.confirm`,
              },
              {
                id: 'uop.requiredInformation.explanation.confirm',
              },
            ]}
          />
        </Button>
      </div>
    </div>
  </div>
);

export default enhance(PureFieldExplanationModal);
