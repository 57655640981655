import { createEpic, createStructuredSelector } from '@ux/fabric';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { selectors as langSelectors } from '@ux/language';
import { selectors as authSelectors } from '@ux/auth/idp';

import { FetchRequiredValues } from '../../../core/details';
import { FETCH } from '../../signals/requiredValues';
import { showRelatedProduct } from '../../../domain/selectors/details';
import { includeTax } from '../../../domain/selectors/common';
import { fetchFailed, fetched, fetching } from '../../../domain/messages/requiredValues';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';

const { getBrandId } = wlSelectors;
const { getLanguage } = langSelectors;
const { isAuthenticated } = authSelectors;

const selector = createStructuredSelector({
  brandId: getBrandId,
  locale: getLanguage,
  // @ts-ignore
  includeOffer: showRelatedProduct,
  tax: includeTax,
  isAuthenticated,
});

const fetchEpic = (fetch: FetchRequiredValues) =>
  createEpic({
    signal: FETCH,
    selector,
    filter: (action, state) => state.isAuthenticated,
    pending: fetching,
    process: () => fetch(),
    onSuccess: (data) => [fetched({ requiredValues: data })],
    onFailure: handleEsocketError(
      handleAuth((err) => {
        return fetchFailed(serverErrorToIntlId('uop.details.load.failed')(err));
      }),
    ),
  });

export default fetchEpic;
