import * as yup from 'yup';
import { createValidationMessage } from '@ux/form-utils';
import { isTrue } from '@team-griffin/capra';

const vMessage = createValidationMessage('uop.checkout.validation');

const schema = yup.object().shape({
  terms: yup.boolean().test('is-true', vMessage('enabled'), isTrue),
  paymentMethod: yup.string().required(vMessage('required')),
  identifier: yup.string(),
});

export default schema;
