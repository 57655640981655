import { Prices as ProductPrices } from '@ux/product';
import { Price } from 'core/basket';

export const transformPrice = (price: Price, currency: string): ProductPrices | null => {
  if (price == null) {
    return null;
  }

  return {
    net: {
      amount: price?.net,
      currency,
    },
    tax: {
      amount: price?.tax,
      currency,
    },
    gross: {
      amount: price?.gross,
      currency,
    },
    raw: {
      amount: price?.net,
      currency,
    },
  };
};
