import { selectors as wlSelectors } from '@ux/whitelabel';
import { selectors as authSelectors } from '@ux/auth/idp';
import { useSelector } from 'react-redux';
import { Screen } from 'core/common';

import { getFirstUrl, getScreens } from '../../domain/selectors/common';
import { getRoutes } from '../../domain/selectors/routes';
import App from './App';

const { getUrl } = wlSelectors;
const { isAuthenticated } = authSelectors;

const ConnectedApp = () => {
  const firstUrl = useSelector(getFirstUrl) as string;

  const screens = useSelector(getScreens) as Screen[];
  const homeUrl = useSelector(getUrl('storefront.homepage')) as string;
  const routes = useSelector(getRoutes) as { [key: string]: any };
  const showAuthPage = !useSelector(isAuthenticated);

  return <App firstUrl={firstUrl} screens={screens} homeUrl={homeUrl} routes={routes} showAuthPage={showAuthPage} />;
};

ConnectedApp.displayName = ConnectedApp;

export default ConnectedApp;
