import { createAction } from 'redux-actions';

import { ProductId, BasketItemId } from '../../core/basket';

// Action Types
const prefix = '@@uop/bolt-ons/S_';
export const FETCH = `${prefix}FETCH`;
export const PURCHASE_BOLT_ON = `${prefix}PURCHASE_BOLT_ON`;
export const REMOVE_BOLT_ON = `${prefix}REMOVE_BOLT_ON`;

// Action Creators
export const fetch = createAction(FETCH);
export const purchaseBoltOn = createAction<{
  basketItemId: BasketItemId;
  productId: ProductId;
  auto?: boolean;
  term?: number;
  paymentInterval?: number;
  quantity?: number;
}>(PURCHASE_BOLT_ON);
export const removeBoltOn = createAction<{
  basketItemId: BasketItemId;
  parentId: BasketItemId;
  auto?: boolean;
}>(REMOVE_BOLT_ON);

// Action Payloads
export type Fetch = ReturnType<typeof fetch>;
export type PurchaseBoltOn = ReturnType<typeof purchaseBoltOn>;
export type RemoveBoltOn = ReturnType<typeof removeBoltOn>;
