import { createEpic } from '@ux/fabric';
import { selectors as whitelabelSelectors } from '@ux/whitelabel';
import { FetchUpsells, View } from 'core/upsells';
import { PREFETCH, fetch as postFetch } from 'application/signals/upsells';
import { isFetching, isEnabled } from 'domain/selectors/upsells';
import * as upsellMessages from 'domain/messages/upsells';

const fetchEpic = (fetch: FetchUpsells) =>
  createEpic({
    signal: PREFETCH,
    selector: (state: any) => ({
      isFetching: isFetching(state),
      isEnabled: isEnabled(state),
      brandId: whitelabelSelectors.getBrandId(state),
    }),
    filter: (action, state) => !state.isFetching && state.isEnabled,
    pending: upsellMessages.fetching,
    process: (_, state) => fetch({ brandId: state?.brandId }),
    onSuccess: (data: View) => [upsellMessages.prefetched(data.payload), postFetch()],
    onFailure: () => upsellMessages.fetchFailed(),
  });

export default fetchEpic;
