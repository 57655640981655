import { createReducer } from 'redux-create-reducer';

import { FETCHED, FETCHING, Fetched, HELP_OPENED, HelpOpened, FETCH_FAILED, FetchFailed } from '../../messages/details';
import { RequiredFieldSets } from '../../../core/details';

export interface State {
  fetching: boolean;
  fetched: boolean;
  error: any;
  requiredFields: RequiredFieldSets;
  helpField?: string;
  productId?: number;
}

const initialState: State = {
  fetching: false,
  fetched: false,
  error: null,
  requiredFields: null as RequiredFieldSets,
  helpField: null,
  productId: null,
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: (state) => {
    return {
      ...state,
      fetching: true,
    };
  },
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: false,
      fetched: true,
      error: null,
      ...action.payload,
    };
  },
  [FETCH_FAILED]: (state, action: FetchFailed) => {
    return {
      ...state,
      fetching: false,
      fetched: false,
      error: action.payload,
    };
  },
  [HELP_OPENED]: (state, action: HelpOpened) => {
    return {
      ...state,
      helpField: action.payload.field,
      productId: action.payload.productId,
    };
  },
});
