import { createAction } from 'redux-actions';

const prefix = '@@uop/confirmation/S_';
export const FETCH = `${prefix}FETCH`;
export const AD_CLICKED = `${prefix}AD_CLICKED`;
export const FOLLOW_UP_CLICKED = `${prefix}FOLLOW_UP_CLICKED`;

export const fetch = createAction(FETCH);
export const adClicked = createAction<{
  index: number;
}>(AD_CLICKED);
export const followUpClicked = createAction(FOLLOW_UP_CLICKED);

export type Fetch = ReturnType<typeof fetch>;
export type AdClicked = ReturnType<typeof adClicked>;
export type FollowUpClicked = ReturnType<typeof followUpClicked>;
