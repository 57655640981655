import { Http, Method } from '@ux/drivers';
import { SaveDetails } from 'core/details';
import { getBasketId } from 'domain/selectors/common';

const saveDetails = (driver: Http): SaveDetails => ({ values }) => {
  const basketId = getBasketId();

  return driver({
    url: `/api/app/v3/commands/baskets/my/provisioning-fields-data?basketId=${basketId}`,
    method: Method.POST,
    data: {
      values,
    },
  });
};

export default saveDetails;
