import { createStructuredSelector } from 'reselect';
import { withProps } from '@team-griffin/rehook';
import { connect } from '@ux/fabric';
import { puree } from '@ux/pure-enhance';
import * as r from 'ramda';

import { getScreens, getCurrentScreen, showSteptracker } from '../../../domain/selectors/common';
import Steptracker, { OuterProps as InnerProps } from './Steptracker';

interface OuterProps {}

const mapState = createStructuredSelector({
  steps: r.pipe(getScreens, r.map(r.prop('key'))),
  currentStep: r.pipe(getCurrentScreen, r.prop('key')),
  showSteptracker,
});

const enhance = puree<OuterProps, InnerProps>('ConnectedSteptracker')(withProps(connect(mapState)));

export default enhance(Steptracker);
