import { FetchAds, Ad } from 'core/ads';

const fetchAds = (): FetchAds => ({ ads = [], basketKeywords = [] }) => {
  let filteredAds = ads.reduce((acc, ad: Ad) => {
    if (ad.keywords.some((keyword) => basketKeywords.includes(keyword))) {
      return [...acc, ad];
    }
    return acc;
  }, []);

  if (filteredAds.length < 3) {
    filteredAds = filteredAds.concat(ads.slice(0, 3));
  }

  return Promise.resolve(Array.from(new Set(filteredAds)).slice(0, 3));
};

export default fetchAds;
