import { getCategories } from '@pex/upm';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { createSelector } from 'reselect';

export const getTealiumCategories = createSelector(getCategories, (categories: Array<string>) => {
  return categories?.map((category: string) => (category === 'advertising' ? 'marketing' : category));
});

export const getPrivacyPolicyUrl = createSelector(
  wlSelectors.getUrl('legal.privacy'),
  (privacyUrl: string) => privacyUrl ?? '/privacy-policy',
);

export const getCookiesToPreserve = () => [
  'brand_id',
  'basket',
  'market',
  'auth_idp',
  'info_idp',
  'ux-language-preferred',
];
