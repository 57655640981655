import { Http } from '@ux/drivers';
import * as r from 'ramda';

import { FetchConfirmation, FetchConfirmationParams } from '../../core/confirmation';

const fetchConfirmation = (json: Http): FetchConfirmation => (params: FetchConfirmationParams) => {
  const { orderId } = params;

  const request = {
    url: `/api/app/v3/queries/order/${orderId}`,
  };

  return json(request).then<any>(r.prop('data'));
};

export default fetchConfirmation;
