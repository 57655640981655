import { Http } from '@ux/drivers';

import _fetchRequiredFields from './fetchRequiredFields';
import _fetchRequiredValues from './fetchRequiredValues';
import _saveDetails from './saveDetails';

export default (json: Http) => {
  return {
    fetchRequiredFields: _fetchRequiredFields(json),
    fetchRequiredValues: _fetchRequiredValues(json),
    saveDetails: _saveDetails(json),
  };
};
