import { createEpic } from '@ux/fabric';
import { selectors as whitelabelSelectors } from '@ux/whitelabel';
import { FetchUpsells, View } from 'core/upsells';
import { isFetching, hasUpsells } from 'domain/selectors/upsells';
import * as upsellMessages from 'domain/messages/upsells';
import { FETCH } from 'application/signals/upsells';

const fetchEpic = (fetch: FetchUpsells) =>
  createEpic({
    signal: FETCH,
    selector: (state: any) => ({
      isFetching: isFetching(state),
      hasUpsells: hasUpsells(state),
      brandId: whitelabelSelectors.getBrandId(state),
    }),
    filter: (action, state) => state.isFetching && state.hasUpsells,
    pending: upsellMessages.fetching,
    process: (_, state) => fetch({ brandId: state?.brandId }),
    onSuccess: (data: View) => {
      return upsellMessages.fetched(data.payload);
    },
    onFailure: () => upsellMessages.fetchFailed(),
  });

export default fetchEpic;
