import * as most from 'most';
import { createEpic } from '@ux/fabric';
import { Action } from 'redux';
import { combineEpics } from 'redux-most';
import * as r from 'ramda';
import { actionTypes, change } from 'redux-form';

import { getProductIds, isGrouped as isGroupedMode, getProductFields } from '../../../domain/selectors/details';
import { Change } from '../../../core/details';

const getFieldParts = (action: Change) => r.pipe(r.split('.'), r.remove(0, 1))(action.meta.field);

const getProduct = (action: Change) => r.pipe(getFieldParts, r.head)(action);

const getField = (action: Change) => r.pipe(getFieldParts, r.remove(0, 1), r.join('.'))(action);

const isForThisForm = r.both(
  r.pathEq(['meta', 'form'], 'uop.requiredInformation'),
  r.pipe(getField, r.complement(r.isEmpty)),
);

const isGroupedField = (action: Change) => r.pipe(getProduct, r.equals('GROUPED'))(action);

export const groupChangeEpic = () =>
  createEpic({
    signal: actionTypes.CHANGE,
    selector: (state: any) => {
      return {
        grouped: isGroupedMode(state),
        productIds: getProductIds(state),
        getProductFields: (id: number) => getProductFields(id)(state),
      };
    },
    filter: (
      action: Action & Change,
      state: {
        grouped: boolean;
        productIds: number[];
        getProductFields: (id: number) => string[];
      },
    ) => {
      if (!isForThisForm(action)) {
        return false;
      }
      if (!isGroupedField(action)) {
        return false;
      }
      if (!state.grouped) {
        return false;
      }
      return true;
    },
    process: (action, state) => {
      const field = getField(action);
      const { productIds, getProductFields } = state;

      const productsWithField = r.filter((id: number) => {
        const fields = getProductFields(id);
        return r.includes(field, fields);
      }, productIds);

      return most.of(productsWithField);
    },
    onSuccess: (productIds: number[], action) => {
      const field = getField(action);
      const value = action.payload;

      return r.map((id) => {
        return change('uop.requiredInformation', `products.${id}.${field}`, value);
      }, productIds);
    },
  });

export const productChangeEpic = () =>
  createEpic({
    signal: actionTypes.CHANGE,
    selector: (state: any) => {
      return {
        grouped: isGroupedMode(state),
      };
    },
    filter: (action: Change & Action, state: { grouped: boolean }) => {
      if (!isForThisForm(action)) {
        return false;
      }
      if (isGroupedField(action)) {
        return false;
      }
      if (state.grouped) {
        return false;
      }
      return true;
    },
    onSuccess: (x, action) => {
      const field = getField(action);
      const value = action.payload;

      return change('uop.requiredInformation', `products.GROUPED.${field}`, value);
    },
  });

export default () => combineEpics([groupChangeEpic(), productChangeEpic()]);
