import React, { useEffect, Fragment, lazy, Suspense } from 'react';
import { mush, withHooks, GetInnerProps } from '@ux/pure-enhance';
import { withProps, tap } from '@team-griffin/rehook';
import { replace } from 'connected-react-router';
import { connect } from '@ux/fabric';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import { getNextUrl } from '../../../domain/selectors/common';
import * as selectors from '../../../domain/selectors/pages/offers';
import { Status } from '../../../domain/constants/cross-sells';
import useEnsureStartUrl from '../../hooks/useEnsureStartUrl';
import Pending from './Pending';

const Offers = lazy(
  () =>
    import(
      /* webpackChunkName: 'offers' */
      '../offers'
    ),
);

interface OuterProps {}

const mapState = createStructuredSelector({
  status: selectors.getStatus,
  showPage: selectors.showPage,
  nextUrl: getNextUrl,
});
const mapActions = { replace };

const enhance = mush<OuterProps>('OffersPage')(
  withHooks(
    withProps(connect(mapState, mapActions)),
    tap((props) => {
      const {
        nextUrl,
        status,
        actions: { replace },
      } = props;

      useEnsureStartUrl(status === Status.REQUESTING);

      useEffect(() => {
        if (status === Status.HAS_NONE) {
          replace({ pathname: nextUrl });
        }
      }, [status, nextUrl, replace]);
    }),
  ),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureOffersPage = ({ showPage }: InnerProps) => {
  const intl = useIntl();

  return (
    <Fragment>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'brand.metaTitle.template.structure',
            },
            {
              page: intl.formatMessage({
                id: 'uop.metaTitle.offers',
              }),
              brand: intl.formatMessage({
                id: 'brand.metaTitle.template.brand',
              }),
            },
          )}
        </title>
      </Helmet>
      <Choose>
        <When condition={showPage}>
          <Suspense fallback={<Pending />}>
            <Offers />
          </Suspense>
        </When>
        <Otherwise>
          <Pending />
        </Otherwise>
      </Choose>
    </Fragment>
  );
};

export default enhance(PureOffersPage);
