import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import { LANDING_CAPUTRED, LandingCaptured } from '../../messages/root';
import { Screen } from '../../../core/common';

export interface State {
  landingScreen: Screen;
}

const initialState: State = {
  landingScreen: {
    key: '',
    path: [],
  },
};

export default createReducer<State, any>(initialState, {
  [LANDING_CAPUTRED]: (state, action: LandingCaptured) => {
    return r.assoc('landingScreen', action.payload.screen, state);
  },
});
