import { createEpic } from '@ux/fabric';
import { combineEpics } from 'redux-most';
import * as r from 'ramda';
import { initialize, getFormValues } from 'redux-form';
import * as most from 'most';
import { replace } from 'connected-react-router';
import { getEligibleFieldsByRegistrantType } from 'domain/selectors/details/registrarUk';
import { getIsPartialProfile } from 'domain/selectors/customer';

import { FetchRequiredFields } from '../../../core/details';
import { FETCH } from '../../signals/details';
import { fetch as fetchRequiredValues } from '../../signals/requiredValues';
import {
  FETCHED,
  fetchFailed,
  fetched,
  fetching,
  formPopulated,
  FORM_POPULATED,
} from '../../../domain/messages/details';
import { FETCHED as FETCHED_VALUES } from '../../../domain/messages/requiredValues';
import { getInitialValues, hasConflicts } from '../../../domain/selectors/details';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';
import { getNextUrl, getCurrentScreen } from '../../../domain/selectors/common';
import validate from '../../../domain/logic/details/validate';
import { State as RootState } from '../../../domain/reducers';

const fetchEpic = (fetch: FetchRequiredFields) =>
  createEpic({
    signal: FETCH,
    pending: fetching,
    process: () => fetch(),
    onSuccess: (data) => [fetched({ requiredFields: data })],
    onFailure: handleEsocketError(
      handleAuth((err) => {
        return fetchFailed(serverErrorToIntlId('uop.details.load.failed')(err));
      }),
    ),
  });

const fetchRequiredValuesEpic = createEpic({
  signal: FETCHED,
  onSuccess: () => [fetchRequiredValues()],
});

const fetchedEpic = createEpic({
  signal: FETCHED_VALUES,
  process: (action, state: RootState) => {
    const initialValues: any = getInitialValues(state);
    const eligibleFieldsByRegistrantType = getEligibleFieldsByRegistrantType(state);
    return validate(
      false,
      {
        GROUPED: {
          ...initialValues.GROUPED,
          fieldSets: ['domain.basic'],
        },
      },
      eligibleFieldsByRegistrantType,
    )
      .then(r.T)
      .catch(r.F);
  },
  onSuccess: (data: any, action, state: RootState) => {
    const formValues = getFormValues('uop.requiredInformation')(state);
    const initialValues = getInitialValues(state);
    return r.pipe(
      (readonly: boolean) => ({
        // eslint-disable-next-line camelcase
        __domain_basic_editing__: !readonly,
        products: initialValues,
      }),
      (values) => {
        // Adding a shouldUseAccount property to bind to the switch that allows users
        // to copy the account details to domain details for partial profile functionality
        const result = r.forEachObjIndexed((value: any) => {
          value['shouldUseAccount'] = true;
        }, r.path(['products'], values));

        values.products = result;
        return values;
      },
      (values) =>
        most.from([
          initialize(
            'uop.requiredInformation',
            { ...formValues, ...values },
            {
              keepDirty: false,
            },
          ),
          formPopulated(),
        ]),
    )(data);
  },
  onFailure: handleAuth((err) => {
    return fetchFailed(serverErrorToIntlId('uop.details.load.failed')(err));
  }),
});

const setGroupedEpic = createEpic({
  signal: FORM_POPULATED,
  onSuccess: (data, action, state: RootState) => {
    const values = getFormValues('uop.requiredInformation')(state);
    const group = !hasConflicts(state);

    return initialize(
      'uop.requiredInformation',
      {
        ...values,
        group,
      },
      {
        keepDirty: false,
      },
    );
  },
});

export const skipEpic = createEpic({
  signal: FETCHED,
  filter: (action, state: any) => {
    // customer doesn't have a full profile and
    // we are about to enter the required info page
    const isPartialProfile = getIsPartialProfile(state);
    const currentScreen = getCurrentScreen(state);

    const requiredFields = Object.values(action.payload.requiredFields);
    const flattenedRequiredFields = requiredFields.flat();
    const hasRequireFields = flattenedRequiredFields.length > 0;

    if (currentScreen?.key === 'details') {
      if (!hasRequireFields && !isPartialProfile) {
        return true;
      }
    }

    return false;
  },
  // ...skip to the next page
  onSuccess: (data, action, state) => replace(getNextUrl(state)),
});

export default (fetchRequiredFields: FetchRequiredFields) =>
  combineEpics([fetchEpic(fetchRequiredFields), fetchRequiredValuesEpic, fetchedEpic, setGroupedEpic, skipEpic]);

/* eslint-disable */
export const __test__ = {
  fetchEpic,
  fetchRequiredValuesEpic,
  fetchedEpic,
  setGroupedEpic,
  skipEpic,
};
