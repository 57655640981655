export default {
  login: '/anmeldung',
  offers: '/angebote',
  basket: '/warenkorb',
  details: '/domain-infos',
  payment: '/zahlung',
  transaction: '/checkout/transaction',
  failedTransaction: '/checkout/failed',
  completeTransaction: '/checkout/complete',
  confirmation: '/abschluss',
};
