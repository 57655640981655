import { createSelector } from 'reselect';
import { LabelStrategy } from 'domain/constants/basket';

import { BasketItemId, BasketItem, BasketItemResponse, Configurable, ProductOption } from '../../../core/basket';
import { getBasketItem } from './basketItem';

// it's possible to have add ons with a 0 quantity that are just part of the product
// we don't want to show those as configurables
const isAddon = (item: BasketItem) => item.quantity > 0;

// a basket item can have several child order items (add ons) but those addons might also have
// child order items, and so on. So we need to completely flatten them out here
const getRecursiveOrderItems = (basketItem: BasketItem | BasketItemResponse): BasketItem[] => {
  return (basketItem.addOns || [])
    .reduce((acc, item) => {
      return [...acc, item, ...getRecursiveOrderItems(item)];
    }, [])
    .filter(isAddon);
};

const getChildOrderItems = (id: BasketItemId) => createSelector([getBasketItem(id)], getRecursiveOrderItems);

const getProductOptions = (id: BasketItemId) =>
  createSelector([getBasketItem(id), getChildOrderItems(id)], (basketItem, children) => {
    const items = [basketItem, ...children];

    return (
      items
        // flatten out all the product options
        .reduce((acc, item) => acc.concat(item.productOptions || []), [] as ProductOption[])
        // we don't want to show configurables that aren't set
        .filter(
          (option) =>
            option.selectedOptionValue.optionValueName?.toLowerCase() !== 'none_set' &&
            option.selectedOptionValue.optionValueLabel?.toLowerCase() !== 'none_set',
        )
        .map((option) => {
          // it's possible to define a label strategy in ppe via the metadata
          // this determines whether you show the option, the value, both, or explicitly hidden
          const labelStrategy = option.metadata?.uop_label_strategy;
          let name: string = null;

          switch (labelStrategy) {
            case LabelStrategy.HIDDEN:
              name = null;
              break;
            case LabelStrategy.OPTION:
              // Operating System
              name = option.optionLabel;
              break;
            case LabelStrategy.CHOICE:
              // Ubuntu 18.04
              name = option.selectedOptionValue.optionValueLabel;
              break;
            case LabelStrategy.OPTION_AND_CHOICE:
            default:
              // Operating System - Ubuntu 18.04
              name = [option.optionLabel, option.selectedOptionValue.optionValueLabel].filter(Boolean).join(' - ');
              break;
          }

          return { name };
        })
        .filter((option) => option.name)
    );
  });

export const getConfigurables = (id: BasketItemId) =>
  createSelector([getChildOrderItems(id), getProductOptions(id)], (childOrderItems, productOptions) => {
    const configurableOrderItems = childOrderItems.map(
      (item): Configurable => {
        return {
          id: item.id,
          // @ts-ignore - missing from the type
          productName: item.name,
          // @ts-ignore
          displayName: item.name,
        };
      },
    );
    const configurableOptions = productOptions.map(
      (option, i): Configurable => {
        return {
          id: i,
          displayName: option.name,
          productName: option.name,
        };
      },
    );

    return [...configurableOrderItems, ...configurableOptions];
  });

// eslint-disable-next-line
export const __test_configurables__ = {
  getRecursiveOrderItems,
  getChildOrderItems,
  getProductOptions,
};
