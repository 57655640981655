import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';

import { fetchFailed, fetched, fetching, prefetched } from '../../../domain/messages/vouchers';
import * as basketMessages from '../../../domain/messages/basket';

const fetchingEpic = () =>
  createEpic({
    signal: basketMessages.FETCHING,
    onSuccess: () => fetching(),
  });

const prefetchedEpic = () =>
  createEpic({
    signal: basketMessages.PREFETCHED,
    onSuccess: (x, action: basketMessages.Prefetched) =>
      prefetched({
        vouchers: action.payload.vouchers,
      }),
  });

const fetchedEpic = () =>
  createEpic({
    signal: basketMessages.FETCHED,
    onSuccess: (x, action: basketMessages.Fetched) => fetched({ vouchers: action.payload.vouchers }),
  });

const failedEpic = () =>
  createEpic({
    signal: basketMessages.FETCH_FAILED,
    onSuccess: (x, action: basketMessages.Fetched) => fetchFailed(action.payload),
  });

export default () => combineEpics([fetchingEpic(), prefetchedEpic(), fetchedEpic(), failedEpic()]);
