import { create } from '@most/create';
import * as r from 'ramda';

import { ListenForLogin } from '../../core/login';

const getMethodNames = (window: Window): ['addEventListener', 'message'] => {
  if (window.addEventListener) {
    return ['addEventListener', 'message'];
  }
  // @ts-ignore
  return ['attachEvent', 'onmessage'];
};

const listenForLogin = (window: Window): ListenForLogin => (originUrl) => {
  return create((add, end) => {
    const [eventMethod, event] = getMethodNames(window);

    window[eventMethod](
      event,
      (e) => {
        if (e.origin !== originUrl) {
          return;
        }
        if (r.pathEq(['data', 'type'], 'SSO_IFRAME_LOADED', e)) {
          return add({ type: 'LOADED' });
        }
        if (e.data.payload.status) {
          add({ type: 'LOGGED_IN' });
          end();
        }
      },
      false,
    );
  });
};

export default (window: Window) => {
  return {
    listenForLogin: listenForLogin(window),
  };
};
