import { Http } from '@ux/drivers';
import { getBasketId, getLanguageCookie } from 'domain/selectors/common';

import { FetchCrossSells } from '../../core/cross-sells';
import normalize from '../../domain/transformers/cross-sells';

export default (driver: Http): FetchCrossSells => ({ brandId }) => {
  const basketId = getBasketId();
  const languageCookie = getLanguageCookie();

  return driver({
    url: `/api/basket/api/v2/baskets/${basketId}/cross-sells`,
    data: { localeCode: languageCookie },
    headers: {
      'X-Tenant': brandId,
    },
  }).then(({ data }) => {
    return normalize(data);
  });
};
