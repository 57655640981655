import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { push } from 'connected-react-router';
import { signals as authSignals } from '@ux/auth/idp';

import { getNextUrl, getUrl } from '../../../domain/selectors/common';
import { hasLoaded } from '../../../domain/selectors/login';
import { Core } from '../../../core';
import { ListenForLogin, LoginResponse } from '../../../core/login';
import * as signals from '../../signals/login';
import * as messages from '../../../domain/messages/login';
import pageView from './pageView';

const initializeEpic = (listen: ListenForLogin) =>
  createEpic({
    signal: signals.INITIALIZE,
    process: (action, state) => {
      const originUrl = getUrl('sso.login')(state).replace(/\/$/, '');
      return listen(originUrl);
    },
    onSuccess: (action: LoginResponse) => {
      switch (action.type) {
        case 'LOADED':
          return messages.iframeLoaded();
        case 'LOAD_FAILED':
          return messages.iframeLoadFailed();
        case 'LOGGED_IN':
          return messages.loggedIn();
        default:
          return [];
      }
    },
  });

const waitForLoginEpic = () =>
  createEpic({
    signal: signals.WAIT_FOR_LOGIN,
    intercept: (actions$, store: any) => {
      return actions$.delay(5000).filter(() => {
        const state = store.getState();
        const loaded = hasLoaded(state);
        return !loaded;
      });
    },
    onSuccess: () => messages.iframeLoadFailed(),
  });

const logInFailedEpic = () =>
  createEpic({
    signal: messages.IFRAME_LOAD_FAILED,
    onSuccess: (data, action, state) => {
      const path = getNextUrl(state);
      return authSignals.login(null, path);
    },
  });

const loggedInEpic = () =>
  createEpic({
    signal: messages.LOGGED_IN,
    onSuccess: (data, action, state) => [authSignals.fetch(), push(getNextUrl(state))],
  });

export default (deps: Core) =>
  combineEpics([
    initializeEpic(deps.login.listenForLogin),
    waitForLoginEpic(),
    logInFailedEpic(),
    pageView(deps.events.view, window),
    loggedInEpic(),
  ]);
