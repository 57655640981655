import { fetch, ndjson, xray, Http } from '@ux/drivers';
import { map, mapRej } from '@ux/promise';
import * as r from 'ramda';
import { BASKET_NOT_FOUND } from 'domain/constants/error';

import { handleAuthRequestValidation } from './utils';

const authGuard = () => (http: Http): Http => r.pipe(http, mapRej(handleAuthRequestValidation));

// if you hit an invalid endpoint, you get a legacy jsonrpc response
// which is still a 200, so we need to conver it into a rejection
// @ts-ignore
const jsonRpcGuard = () => (http: Http): Http =>
  r.pipe(
    http,
    map(
      r.when(r.path(['data', 'jsonrpc']), () => {
        throw new Error('unknown api endpoint');
      }),
    ),
  );

// for any 4xx error we want to treat it as an empty basket
// in theory 401s should still go through as we need to boot the user to sso
const emptyBasketGuard = () => (http: Http): Http =>
  r.pipe(
    http,
    mapRej((err) => {
      const status = `${r.pathOr('', ['status'], err)}`;
      if (status[0] === '4' && status !== '401') {
        return {
          ...err,
          status: 404,
          body: JSON.stringify({ code: BASKET_NOT_FOUND }),
        };
      }
      return err;
    }),
  );

export default () => r.pipe(xray(), emptyBasketGuard(), authGuard(), jsonRpcGuard(), ndjson())(fetch);
