import * as yup from 'yup';

import { testPhoneNumber } from './utils';
import { required, max, invalid } from './messages';
import getAddressSchema from './address';

const getSchema = (prefix: string = '', hasEmail: boolean = true) => {
  const addressSchema = getAddressSchema(prefix);

  return yup.object().shape({
    forename: yup.string().label(`${prefix}forename`).nullable().required(required).max(50, max),
    lastname: yup.string().label(`${prefix}lastname`).nullable().required(required).max(50, max),
    address: addressSchema,
    telephone: yup
      .string()
      .label(`${prefix}telephone`)
      .nullable()
      .test({
        name: 'valid',
        message: invalid,
        test: testPhoneNumber,
      })
      .required(required),
    email: hasEmail
      ? yup.string().label(`${prefix}email`).email(invalid).required(required)
      : yup.string().nullable().notRequired(),
  });
};

export default getSchema;
