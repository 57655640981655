import { FetchFollowUps } from 'core/follow-ups';

const fetchFollowUps = (): FetchFollowUps => ({ followUps = {}, basketKeywords = [] }) => {
  const followUpsKeywords = Object.keys(followUps);

  const filteredFollowUps = followUpsKeywords.reduce((acc, followUpsKeyword: string) => {
    if (basketKeywords.includes(followUpsKeyword)) {
      return [...acc, followUps[followUpsKeyword]];
    }
    return acc;
  }, []);

  return Promise.resolve(filteredFollowUps);
};

export default fetchFollowUps;
