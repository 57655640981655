import { createEpic, createStructuredSelector } from '@ux/fabric';
import { selectors as whitelabelSelectors } from '@ux/whitelabel';

import { FetchBoltOns, View } from '../../../core/bolt-ons';
import { FETCH, Fetch } from '../../signals/bolt-ons';
import { fetchFailed, fetched, fetching } from '../../../domain/messages/bolt-ons';
import { isFetching } from '../../../domain/selectors/bolt-ons';
import { handleAuth } from '../utils';

const fetchEpic = (fetch: FetchBoltOns) =>
  createEpic({
    signal: FETCH,
    selector: createStructuredSelector({
      fetching: isFetching,
      brandId: whitelabelSelectors.getBrandId,
    }),
    filter: (action: Fetch, state) => {
      return !state.fetching;
    },
    pending: fetching,
    process: (action, state) => fetch({ brandId: state?.brandId }),
    onSuccess: (data: View) => fetched(data),
    onFailure: handleAuth(fetchFailed),
  });

export default fetchEpic;
