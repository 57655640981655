import { combineReducers } from 'redux';

import accountCredits, { State as AccountCredit } from './account-credits';
import basket, { State as Basket } from './basket';
import vouchers, { State as Vouchers } from './vouchers';
import crossSells, { State as CrossSells } from './cross-sells';
import root, { State as Root } from './root';
import paymentTokens, { State as PaymentTokens } from './payment-tokens';
import paymentTokensV2, { State as PaymentTokensV2 } from './payment-tokens-v2';
import requiredFields, { State as RequiredFields } from './details';
import requiredValues, { State as RequiredValues } from './requiredValues';
import confirmation, { State as Confirmation } from './confirmation';
import followUps, { State as FollowUps } from './follow-ups';
import ads, { State as Ads } from './ads';
import products, { State as Products } from './products';
import customer, { State as Customer } from './customer';
import login, { State as Login } from './login';
import transaction, { State as Transaction } from './transaction';
import boltOns, { State as BoltOns } from './bolt-ons';
import upsells, { State as Upsells } from './upsells';

export interface State {
  accountCredits: AccountCredit;
  basket: Basket;
  vouchers: Vouchers;
  crossSells: CrossSells;
  customer: Customer;
  root: Root;
  paymentTokens: PaymentTokens;
  paymentTokensV2: PaymentTokensV2;
  requiredFields: RequiredFields;
  requiredValues: RequiredValues;
  confirmation: Confirmation;
  followUps: FollowUps;
  ads: Ads;
  products: Products;
  login: Login;
  transaction: Transaction;
  boltOns: BoltOns;
  upsells: Upsells;
}

export default combineReducers({
  accountCredits,
  basket,
  vouchers,
  crossSells,
  customer,
  root,
  paymentTokens,
  paymentTokensV2,
  requiredFields,
  requiredValues,
  confirmation,
  followUps,
  ads,
  products,
  login,
  transaction,
  boltOns,
  upsells,
});
