import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { selectors as product } from '@ux/product';

import { DispatchGAEvent, Ecommerce } from '../../../core/events';
import { mapEvent } from '../../../domain/transformers/events';
import { getGab } from '../../../domain/selectors/auto-bolt-ons';
import { State as RootState } from '../../../domain/reducers';
import * as signals from '../../signals/bolt-ons';
import * as messages from '../../../domain/messages/bolt-ons';
import * as basketSignals from '../../signals/basket';
import * as basketMessages from '../../../domain/messages/basket';
import { Event } from '../../../domain/constants/events';

// there is a basket epic that does the legwork here
// so this signal just defers to the basket signal
const removeEpic = () =>
  createEpic({
    signal: signals.REMOVE_BOLT_ON,
    pending: (action: signals.RemoveBoltOn) => messages.removing(action.payload),
    onSuccess: (x, action) =>
      basketSignals.remove({
        basketItemId: action.payload.basketItemId,
      }),
  });

const removedEpic = () =>
  createEpic({
    signal: signals.REMOVE_BOLT_ON,
    process: (action: signals.RemoveBoltOn, state, actions$, store) => {
      const basketItemId = action.payload.basketItemId;
      const subEpic = createEpic({
        signal: basketMessages.REMOVED,
        filter: (action: basketMessages.Removed) => {
          return action.payload.basketItemId === basketItemId;
        },
        onSuccess: (action: any) => action,
      });
      return subEpic(actions$, store);
    },
    onSuccess: (x, action) => {
      return messages.removed(action.payload);
    },
    join: true,
  });

const failedEpic = () =>
  createEpic({
    signal: signals.REMOVE_BOLT_ON,
    process: (action: signals.RemoveBoltOn, state, actions$, store) => {
      const basketItemId = action.payload.basketItemId;
      const subEpic = createEpic({
        signal: basketMessages.ADD_FAILURE,
        filter: (action: basketMessages.RemoveFailure) => {
          return action.meta.basketItemId === basketItemId;
        },
        onSuccess: (action: any) => action,
      });
      // @ts-ignore
      return subEpic(actions$, store);
    },
    onSuccess: (x, action) => {
      return messages.removeFailed(action.payload);
    },
    join: true,
  });

const gabAnalyticsEpic = (dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  createEpic({
    signal: signals.REMOVE_BOLT_ON,
    filter: (action: signals.RemoveBoltOn) => {
      return action.payload.auto;
    },
    process: (action: signals.RemoveBoltOn, state: RootState, actions$, store) => {
      const o = getGab(action.payload.basketItemId)(state);
      const name = product.getName(o);
      const category = product.getCategory(o);
      const sku = o.productId;
      const list = 'bolt-on (offers)';

      return createEpic({
        signal: messages.REMOVED,
        filter: (action2: messages.Removed) => {
          return action.payload.basketItemId === action2.payload.basketItemId;
        },
        process: () => {
          ecommerce([
            [
              'addProduct',
              {
                id: sku,
                name: `${name} (auto-added)`,
              },
            ],
            [
              'setAction',
              'remove',
              {
                list,
                category,
              },
            ],
          ]);

          dispatch(
            mapEvent({
              event: Event.REMOVE_GAB,
              sku,
            }),
          );
        },
      })(actions$, store);
    },
  });

export default (dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  combineEpics([removeEpic(), removedEpic(), failedEpic(), gabAnalyticsEpic(dispatch, ecommerce)]);
