import { createAction } from 'redux-actions';

// Action Types
const prefix = '@@uop/login/S_';
export const INITIALIZE = `${prefix}INITIALIZE`;
export const WAIT_FOR_LOGIN = `${prefix}WAIT_FOR_LOGIN`;

// Action Creators
export const initialize = createAction(INITIALIZE);
export const waitForLogin = createAction(WAIT_FOR_LOGIN);

// Action Payloads
export type Initialize = ReturnType<typeof initialize>;
export type WaitForLogin = ReturnType<typeof waitForLogin>;
