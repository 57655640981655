import { Http, Method } from '@ux/drivers';
import { UpdateAccountDetails } from 'core/customer';

const saveDetails = (driver: Http): UpdateAccountDetails => (account) => {
  return driver({
    url: '/api/account/v1/accounts/my/details',
    method: Method.PUT,
    data: account,
  });
};

export default saveDetails;
