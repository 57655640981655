import { Indexer } from '@uxdev/types';
import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import {
  FETCHED,
  FETCHING,
  PREFETCHED,
  REMOVED,
  REMOVE_FAILURE,
  REMOVING,
  Fetched,
  Prefetched,
  RemoveFailure,
  Removed,
  Removing,
} from '../../messages/vouchers';
import { Voucher } from '../../../core/vouchers';
import { ProductStatus } from '../../constants/product';

export interface State {
  fetching: boolean;
  prefetched: boolean;
  fetched: boolean;
  vouchers: Indexer<{}, Voucher>;
  pendingStates: Indexer<{}, ProductStatus>;
}

const initialState: State = {
  fetching: false,
  prefetched: false,
  fetched: false,
  vouchers: {},
  pendingStates: {},
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: r.assoc('fetching', true),
  [PREFETCHED]: (state, action: Prefetched) => {
    return {
      ...state,
      fetching: false,
      prefetched: true,
      vouchers: action.payload.vouchers,
    };
  },
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: false,
      prefetched: true,
      fetched: true,
      vouchers: action.payload.vouchers,
    };
  },

  [REMOVING]: (state, action: Removing) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.code]: ProductStatus.REMOVING,
      },
    };
  },
  [REMOVED]: (state, action: Removed) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.code]: ProductStatus.REMOVED,
      },
    };
  },
  [REMOVE_FAILURE]: (state, action: RemoveFailure) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.meta.code]: ProductStatus.ERROR,
      },
    };
  },
});
