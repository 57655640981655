import { Http, Method } from '@ux/drivers';
import { FetchProviderToken } from 'core/payment';
import { getBasketId } from 'domain/selectors/common';

const fetchTokens = (json: Http): FetchProviderToken => ({ paymentTokenId, language, paymentMethod }) => {
  const basketId = getBasketId();
  const request = {
    url: `/api/app/v3/commands/baskets/my/prepayment?basketId=${basketId}`,
    method: Method.POST,
    data: {
      paymentMethod,
      paymentTokenId,
      language,
    },
  };
  return json(request).then(({ data }) => data);
};

export default fetchTokens;
