import { Process3DSecure } from 'core/payment';

const process3DSecure = (document: Document): Process3DSecure => ({ redirectUrl, paReq, callbackUrl, md }) => {
  const body = document.querySelector('body');
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', redirectUrl);
  const pareq = document.createElement('input');
  pareq.setAttribute('type', 'hidden');
  pareq.setAttribute('name', 'PaReq');
  pareq.setAttribute('value', paReq);
  const termUrl = document.createElement('input');
  termUrl.setAttribute('type', 'hidden');
  termUrl.setAttribute('name', 'TermUrl');
  termUrl.setAttribute('value', callbackUrl);
  const imd = document.createElement('input');
  imd.setAttribute('type', 'hidden');
  imd.setAttribute('name', 'MD');
  imd.setAttribute('value', md);
  form.appendChild(pareq);
  form.appendChild(termUrl);
  form.appendChild(imd);

  if (body == null) {
    return Promise.reject(new Error('no body element on the page'));
  }

  body.appendChild(form);

  form.submit();

  // TODO abstract this as 'never' in Fabrics promises
  // eslint-disable-next-line no-empty-function
  return new Promise(() => {});
};

export default process3DSecure;
