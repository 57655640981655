import React from 'react';
import { withProps, mapProps } from '@team-griffin/rehook';
import { mush, withHooks, GetInnerProps } from '@ux/pure-enhance';
import { useStyles } from '@team-griffin/stylr';
import { Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { useTheme } from '@ux/theme-utils';
import { Content } from '@ux/typeface';
import { Indexer } from '@uxdev/types';
import ReactiveKnowledge from '@ux/reactive-knowledge';
import { useBreakpoints } from '@ux/responsive';
import * as r from 'ramda';
import * as a from 'ramda-adjunct';
import { ApmRoute } from '@elastic/apm-rum-react';

import { Screen } from '../../core/common';
// Pages
import PageTemplate from './layout/PageTemplate';
import AuthOffersPage from './pages/AuthOffersPage';
import OffersPage from './pages/OffersPage';
import AuthBasketPage from './pages/AuthBasketPage';
import BasketPage from './pages/BasketPage';
import DetailsPage from './pages/DetailsPage';
import CheckoutPage from './pages/CheckoutPage';
import ConfirmationPage from './pages/ConfirmationPage';
// @ts-ignore
import TransactionPage from './pages/TransactionPage';
// @ts-ignore
import TransactionFailedPage from './pages/TransactionFailedPage';
// @ts-ignore
import TransactionCompletePage from './pages/TransactionCompletePage';
import LoginPage from './pages/LoginPage';

const pages = {
  login: {
    basic: LoginPage,
    auth: LoginPage,
  },
  offers: {
    basic: OffersPage,
    auth: AuthOffersPage,
  },
  basket: {
    basic: BasketPage,
    auth: AuthBasketPage,
  },
  details: {
    basic: DetailsPage,
    auth: DetailsPage,
  },
  payment: {
    basic: CheckoutPage,
    auth: CheckoutPage,
  },
  confirmation: {
    basic: ConfirmationPage,
    auth: ConfirmationPage,
  },
};

const useStylesheet = () =>
  useStyles({
    default: {
      root: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'auto',
      },
    },
  });

interface OuterProps {
  routes: Indexer<{}, string>;
  screens: Screen[];
  firstUrl: string;
  homeUrl: string;
  showAuthPage: boolean;
}
const enhance = mush<OuterProps>('Core')(
  withHooks(
    mapProps(({ routes: allRoutes, screens, ...props }) => {
      const localeRoutes = allRoutes;
      const routes = screens.map((screen) => {
        const key = screen.key as keyof typeof pages;
        let Page = pages[key].basic;
        if (screen.auth) {
          Page = pages[key].auth;
        }
        return {
          ...screen,
          Page,
        };
      });

      return {
        ...props,
        localeRoutes,
        routes,
      };
    }),
    withProps((props) => {
      return {
        FirstPage: props.routes[0].Page,
      };
    }),
  ),
);
type InnerProps = GetInnerProps<typeof enhance>;

const PureCore = ({ routes, localeRoutes, homeUrl, firstUrl, FirstPage, showAuthPage }: InnerProps) => {
  const { isMinMd, isMinLg } = useBreakpoints();
  const { theme } = useTheme();
  const { styles } = useStylesheet();
  return (
    <Content component="div" style={styles.root}>
      <PageTemplate homeUrl={homeUrl}>
        <Switch>
          <ApmRoute path="/" exact={true} component={() => <FirstPage />} />
          <ApmRoute
            path={localeRoutes.transaction}
            exact={true}
            component={() => <TransactionPage theme={theme} isMinMd={isMinMd} isMinLg={isMinLg} />}
          />
          <ApmRoute
            path={localeRoutes.failedTransaction}
            exact={true}
            component={() => <TransactionFailedPage theme={theme} isMindMd={isMinMd} isMinLg={isMinLg} />}
          />
          <ApmRoute
            path={localeRoutes.completeTransaction}
            exact={true}
            component={() => <TransactionCompletePage theme={theme} />}
          />
          {r.map(({ key, path: paths, Page }) => {
            return a.mapIndexed((path, i) => {
              // alternate urls (i.e. /basket for non-english markets) should redirect to the native
              // url
              if (i > 0) {
                return <ApmRoute key={path} path={path} exact={true} component={() => <Redirect to={paths[0]} />} />;
              }

              // we want to accept the login url as a valid route, but we actually want to
              // always render the login page in the background
              if (key === 'login') {
                return <Route key={path} path={path} exact={true} render={() => null} />;
              }

              return <ApmRoute key={path} path={path} exact={true} component={() => <Page />} />;
            }, paths);
          }, routes)}
          <ApmRoute path="/" exact={false} component={() => <Redirect to={firstUrl} />} />
        </Switch>
        {showAuthPage && <LoginPage key="login" />}
      </PageTemplate>
      <ReactiveKnowledge theme={theme} />
    </Content>
  );
};

export default enhance(PureCore);
