import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { RequiredFieldSets, RequiredValues } from 'core/details';
import * as customer from 'domain/selectors/customer';
import * as details from 'domain/selectors/details';

import { getFieldSets } from './fields';
import { getFormValues } from './form';
import { pruneDomainInformation } from './registrarUkUtils';

const formSelector: any = formValueSelector('uop.requiredInformation');

const getRegistrantType = (productId: string) => (state: any) => {
  if (productId == null) {
    return formSelector(state, 'products.GROUPED.domain.group.uk.registrantType');
  }
  return formSelector(state, `products.${productId}.domain.group.uk.registrantType`);
};

export const getEligibleUkOrgs = createSelector(
  wlSelectors.getAppConfig('uop'),
  (config) => config?.domainDetails?.eligibleUkOrganisation ?? [],
);
export const getEligibleUkComps = createSelector(
  wlSelectors.getAppConfig('uop'),
  (config) => config?.domainDetails?.eligibleUkCompany ?? [],
);

//Returns an object of lists which will tell you if a field has the
//right registrant type to be displayed and used
export const getEligibleFieldsByRegistrantType = createSelector(
  getEligibleUkOrgs,
  getEligibleUkComps,
  (orgs: string[], comps: string[]) => ({
    eligibleUkOrgs: orgs,
    eligibleUkComps: comps,
  }),
);

export const showBusinessFields = (productId: string) =>
  createSelector<any, string, boolean>(
    getRegistrantType(productId),
    (type) => type != null && type !== '' && type !== 'IND',
  );

export const showOrganisation = (productId: string) =>
  createSelector<any, string, string[], boolean>(
    getRegistrantType(productId),
    getEligibleUkOrgs,
    (type, eligibleOrgs) => eligibleOrgs.includes(type),
  );

export const showCompanyNumber = (productId: string) =>
  createSelector<any, string, string[], boolean>(
    getRegistrantType(productId),
    getEligibleUkComps,
    (type, eligibleComps) => eligibleComps.includes(type),
  );

export const showPartnerNames = (productId: string) =>
  createSelector<any, string, boolean>(getRegistrantType(productId), (type) => type === 'PTNR');

export const showCharityNumber = (productId: string) =>
  createSelector<any, string, boolean>(getRegistrantType(productId), (type) => type === 'RCHAR');

export const showSchoolNumber = (productId: string) =>
  createSelector<any, string, boolean>(getRegistrantType(productId), (type) => type === 'SCH');

export const getModifiedDomainData = (state: any) => {
  const fieldSets = getFieldSets(state) as RequiredFieldSets;
  const values = getFormValues(state) as RequiredValues;
  const keys = Object.keys(values ?? {});
  const eligibleFieldsByRegistrantType = getEligibleFieldsByRegistrantType(state);
  return keys.reduce(
    (filtered, key) => ({
      ...filtered,
      [key]: pruneDomainInformation({
        key,
        fieldSets,
        values,
        eligibleFieldsByRegistrantType,
      }),
    }),
    {},
  );
};

type DomainDataType = {
  [productId: string]: {
    domain?: {
      basic: object;
    };
    shouldUseAccount: boolean;
  };
};

export const getModifiedDomainDataForPartialProfile = (state: any) => {
  const { accountDetails } = customer.getAccountFormValues(state);
  const accountDetailsForDomain = {
    forename: accountDetails?.forename,
    lastname: accountDetails?.lastname,
    telephone: accountDetails?.telephone,
    email: accountDetails?.email,
    address: accountDetails?.address,
  };
  const isGrouped = details.isGrouped(state);
  const values = getModifiedDomainData(state) as DomainDataType;
  const keys = Object.keys(values ?? {});

  if (isGrouped) {
    // Getting the grouped domain shouldUseAccount property which captures the
    // user action for 'Use account details (above)' switch, we utilize this to
    // merge individual domains basic info with the account info if the switch
    // 'Use account details (above)' is enabled since the shouldUseAccount property
    // on individual products is not updated when dealing with grouped domains
    const shouldUseAccountForGroupedDomains = details.getShouldUseAccount('GROUPED')(state);
    if (shouldUseAccountForGroupedDomains) {
      keys.map((key) => {
        values[key].domain.basic = { ...values[key].domain.basic, ...accountDetailsForDomain };
        delete values[key].shouldUseAccount;
      });
    }
  } else {
    keys.map((key) => {
      if (values[key].shouldUseAccount) {
        values[key].domain.basic = { ...values[key].domain.basic, ...accountDetailsForDomain };
      }
      delete values[key].shouldUseAccount;
    });
  }

  return values;
};
