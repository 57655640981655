import { createValidationEpic } from '@ux/form-utils';
import { VALIDATE } from 'application/signals/payment';

import { validating, validateSucceeded, validateFailure } from '../../../domain/messages/payment';
import schema from '../../../domain/schemas/payment';

// type ValidateEpic = Epic<Validate, Validating | ValidateSucceeded | ValidateFailure>;
export const validateEpic = createValidationEpic({
  form: 'uop.payment',
  signal: VALIDATE,
  success: validateSucceeded,
  failure: validateFailure,
  schema,
  pending: validating,
});

export default validateEpic;
