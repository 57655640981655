import { RootState, selectors as langSelectors } from '@ux/language';
import { createSelector } from 'reselect';

import gb from './en';

const { getSupportedLanguages } = langSelectors;

export const getLanguage = (state: RootState) => {
  const supportedLanguages = getSupportedLanguages(state);
  const locale = supportedLanguages?.[0] ?? 'en';
  const mainLocale = locale.split('-')[0].toLocaleLowerCase();
  return mainLocale;
};

export const getRoutes = createSelector<any, string, typeof gb>(getLanguage, (locale) => {
  if (['en', 'de'].includes(locale)) {
    return require(`./${locale}`).default;
  }
  return require('./en').default;
});

export const getBasketRoute = createSelector<any, typeof gb, string>(getRoutes, (routes) => routes.basket ?? null);

export const getEnRoutes = () => gb;
