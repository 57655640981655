// General
export const INTERNAL_SERVER_ERROR = 1000;
export const MALFORMED_REQUEST = 1010;

export const REQUEST_VALIDATION = 1100;
export const UNKNOWN_BRAND = 1101;

export const INFRASTRUCTURE_ERROR = 1200;
export const ESOCKET_TIMEOUT = 1950;

// View Aggregate fallback errors
export const OFFERS_ERROR = 1400;
export const DETAILS_ERROR = 1500;
export const CHECKOUT_ERROR = 1600;
export const CONFIRMATION_ERROR = 1700;

// Basket
export const BASKET_ERROR = 2000;
export const BASKET_TOKEN_ERROR = 2001;

export const BASKET_NOT_FOUND = 404;
export const BASKET_BAD_REQUEST = 400;
export const BASKET_NOT_FOUND_FOR_CUSTOMER = 401;
export const BASKET_EXPIRED = 2110;

export const BASKET_CREATE_FAILED = 2200;
export const BASKET_ASSIGN_FAILED = 2210;

export const BASKET_ADD_ITEM_FAILED = 2300;
export const BASKET_UPDATE_ITEM_FAILED = 2310;
export const BASKET_CHANGE_TERM_FAILED = 2320;
export const BASKET_REMOVE_ITEM_FAILED = 2330;
export const BASKET_UPGRADE_ITEM_FAILED = 2340;
export const BASKET_SAVE_META_FAILED = 2350;

export const BASKET_VOUCHER_ADD_FAILED = 2500;
export const BASKET_VOUCHER_INVALID = 2501;
export const BASKET_VOUCHER_EXPIRED = 2502;
export const BASKET_VOUCHER_MAX_USAGE = 2503;
export const BASKET_VOUCHER_DUPLICATE = 2504;
export const BASKET_VOUCHER_REMOVE_FAILED = 2510;

// Authentication
export const AUTH_ERROR = 3000; // General error; unable to verify etc
export const AUTH_INVALID = 3010; // Malformed; modified etc

// Payment
export const PAYMENT_ERROR = 4000;
export const PAYMENT_API_ERROR = 4010;
export const PAYMENT_API_AUTH = 4011;
export const PAYMENT_URL_ERROR = 4100;
export const PAYMENT_TOKENS_ERROR = 4200;
export const PAYMENT_TOKENISATION_ERROR = 4300;

// Brand
export const BRAND_ERROR = 5000;
export const BRAND_BASKET_EMPTY = 5101;
export const BRAND_BASKET_STATUS = 5120;
export const BRAND_DOMAIN_DATA_NOT_FOUND = 5500;
export const BRAND_DOMAIN_NOT_AVAILABLE = 5501;
export const BRAND_ORDER_NOT_FOUND = 5200;
export const BRAND_CUSTOMER_NOT_FOUND = 5201;
export const BRAND_BASKET_NOT_FOUND = 5202;

// Fabric
export const FABRIC_ERROR = 8000;
export const FABRIC_CUSTOMER_NOT_FOUND = 8210;
