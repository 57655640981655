export * from './getPaymentTokens';
export * from './getProviderToken';
export * from './payment-token';
export * from './provider-data';
export * from './process3DSecure';

export enum PostPaymentType {
  SUCCESS = 'Success',
  FAILURE = 'Failure',
}

export type PostPayment = ({
  operationId,
  orderId,
  transactionId,
  type,
}: {
  operationId: string;
  orderId?: string;
  transactionId?: string;
  type: PostPaymentType;
}) => Promise<any>;
