import { createEpic } from '@ux/fabric';
import { selectors as whitelabelSelectors } from '@ux/whitelabel';
import { selectors as authSelectors } from '@ux/auth/idp';
import { AssignBasket } from 'core/basket';
import { FETCHED, assigned } from 'domain/messages/basket';
import { hasBasketBeenAssigned as getHasBasketBeenAssigned } from 'domain/selectors/basket';

const assignEpic = (assign: AssignBasket) =>
  createEpic({
    signal: [FETCHED],
    selector: (state: any) => ({
      brandId: whitelabelSelectors.getBrandId(state),
      isAuthenticated: authSelectors.isAuthenticated(state),
      hasBasketBeenAssigned: getHasBasketBeenAssigned(state),
    }),
    filter: (action, state) => {
      const isAnonymousBasket = !action?.payload?.basket?.customer?.registered;
      // when the basket has fetched we assign the basket and then fetch basket again
      // to prevent infinite assign/fetch calls, if assign basket call return 200 and somehow the basket is still anonymous,
      // we check if the assign basket has been already called once
      if (isAnonymousBasket && state.isAuthenticated && !state.hasBasketBeenAssigned) {
        return true;
      }
      return false;
    },
    process: (_, state) => assign({ brandId: state?.brandId }),
    onSuccess: assigned,
  });

export default assignEpic;
