import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import { FETCHED, FETCHING, Fetched } from '../../messages/customer';
import { Customer } from '../../../core/customer';

export interface State {
  fetching: boolean;
  fetched: boolean;
  customer: {} | Customer;
}

const initialState: State = {
  fetching: false,
  fetched: false,
  customer: {} as Customer,
};

export default createReducer<State, any>(initialState, {
  [FETCHING]: r.assoc('fetching', true),
  [FETCHED]: (state, action: Fetched) => {
    return {
      ...state,
      fetching: false,
      fetched: true,
      customer: action.payload.customer,
    };
  },
});
