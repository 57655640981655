import { Indexer } from '@uxdev/types';
import { createReducer } from 'redux-create-reducer';
import * as messages from 'domain/messages/bolt-ons';
import * as basketMessages from 'domain/messages/basket';
import { BoltOn } from 'core/bolt-ons';
import { ProductStatus } from 'domain/constants/product';

export interface State {
  fetching: boolean;
  fetched: boolean;
  error: any;
  boltOns: Indexer<{}, BoltOn>;
  pendingStates: Indexer<{}, ProductStatus>;
}

const initialState: State = {
  fetching: false,
  fetched: false,
  error: null,
  boltOns: {},
  pendingStates: {},
};

export default createReducer<State, any>(initialState, {
  [messages.FETCHING]: (state) => {
    return {
      ...state,
      fetching: true,
      //if we're fetching, we disregard previous boltons
      boltOns: {},
    };
  },
  [messages.FETCHED]: (state, action: messages.Fetched) => {
    const staleStatuses = [ProductStatus.ADDED, ProductStatus.REMOVED, ProductStatus.UPDATED];

    return {
      ...state,
      fetching: false,
      fetched: true,
      error: null,
      boltOns: action.payload.boltOns,
      pendingStates: Object.entries(state.pendingStates).reduce((pendingStates, [key, value]) => {
        // once we've fetched the bolt ons we want to remove
        // any pending states that are considered "done"
        if (staleStatuses.includes(value)) {
          return pendingStates;
        }
        return {
          ...pendingStates,
          [key]: value,
        };
      }, {}),
    };
  },
  [messages.FETCH_FAILED]: (state, action: messages.FetchFailed) => {
    return {
      ...state,
      fetching: false,
      fetched: false,
      error: action.payload,
    };
  },
  [messages.ADDING]: (state, action: messages.Adding) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.ADDING,
      },
    };
  },
  [messages.ADDED]: (state, action: messages.Added) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.ADDED,
      },
    };
  },
  [messages.ADD_FAILED]: (state, action: messages.AddFailed) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.meta.basketItemId]: ProductStatus.ERROR,
      },
    };
  },

  [messages.REMOVING]: (state, action: messages.Removing) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.REMOVING,
      },
    };
  },
  [messages.REMOVED]: (state, action: messages.Removed) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.REMOVED,
      },
    };
  },
  [messages.REMOVE_FAILED]: (state, action: messages.RemoveFailed) => {
    return {
      ...state,
      pendingStates: {
        ...state.pendingStates,
        [action.payload.basketItemId]: ProductStatus.ERROR,
      },
    };
  },
  [basketMessages.REMOVING]: (state) => {
    return {
      ...state,
      pendingStates: {},
    };
  },
});
