import * as yup from 'yup';

import { required, max, invalid, ukPostcode } from './messages';

const getAddressSchema = (prefix: string = '') => {
  return yup.object().shape({
    line1: yup
      .string()
      .label(`${prefix}address.line1`)
      .matches(/^(?!PO BOX|POBOX.*$).*[a-zA-Z]/i, invalid)
      .nullable()
      .required(required)
      .max(50, max),
    line2: yup.string().label(`${prefix}address.line2`).nullable(true).max(50, max),
    city: yup.string().label(`${prefix}address.city`).nullable().required(required).max(50, max),
    postcode: yup
      .string()
      .when(`$${prefix}address.countryCode`, {
        is: 'GB',
        then: yup.string().matches(/^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i, ukPostcode),
        otherwise: yup.string(),
      })
      .required(required)
      .nullable()
      .label(`${prefix}address.postcode`),
    countryCode: yup.string().label(`${prefix}address.countryCode`).nullable().required(required),
  });
};

export default getAddressSchema;
