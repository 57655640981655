import { createEpic } from '@ux/fabric';
import { signals as modalSignals } from '@team-griffin/redux-modal-router';

import { Event } from '../../../domain/constants/events';
import { mapEvent } from '../../../domain/transformers/events';
import { CLOSE_HELP } from '../../signals/details';

const closeHelpEpic = (dispatch: Function) =>
  createEpic({
    signal: CLOSE_HELP,
    process: () => [
      dispatch(
        mapEvent({
          event: Event.FIELD_HELP_CLOSE,
        }),
      ),
    ],
    onSuccess: () => [modalSignals.close()],
  });

export default closeHelpEpic;
