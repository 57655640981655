import { createReducer } from 'redux-create-reducer';

import { IFRAME_LOADED } from '../../messages/login';
import { INITIALIZE } from '../../../application/signals/login';

export interface State {
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  loading: false,
  loaded: false,
};

export default createReducer<State>(initialState, {
  [INITIALIZE]: () => ({
    loading: true,
    loaded: false,
  }),
  [IFRAME_LOADED]: () => ({
    loading: false,
    loaded: true,
  }),
});
