import * as r from 'ramda';
import * as rA from 'ramda-adjunct';
import { createSelector } from 'reselect';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { Indexer } from '@uxdev/types';
import { PaymentMethods } from 'domain/constants/payment';
import { getLocation } from 'domain/selectors/common';
import qs from 'qs';

import { State as RootState } from '../../reducers';
import { getWiredTransferFlag } from '../customer';
import { hasFetched as hasFetchedPaymentTokens, hasFetchFailed as hasPaymentTokensFailed } from '../payment-tokens';
import {
  hasFetched as getHasAccountCreditsFetched,
  hasFetchFailed as hasAccountCreditsFailed,
} from '../account-credits';
import {
  hasPrefetched as hasPrefetchedBasket,
  hasFetched as getHasBasketFetched,
  hasFetchFailed as hasBasketFailed,
  isBasketAssigned as getIsBasketAssigned,
} from '../basket';

// Until the basket data is (in)complete and the account credit data is complete this is shown in an intermittent state
export const isSummaryReady = (state: any) => {
  const hasBasketFetched = getHasBasketFetched(state);
  const hasAccountCreditsFetched = getHasAccountCreditsFetched(state);
  const isBasketAssigned = getIsBasketAssigned(state);

  return hasBasketFetched && hasAccountCreditsFetched && isBasketAssigned;
};

// We have failed to load the vital data required for the page if either basket
//or account-credits fail
export const hasFetchFailed = r.converge(r.or, [hasBasketFailed, hasAccountCreditsFailed]);

// This screen shows a wub loader until the basket data is incomplete, however
// we also want to stop loading if fetch fails so pageLoader can show the retry button
export const isLoading = r.converge(r.pipe(r.or, r.not), [hasPrefetchedBasket, hasFetchFailed]);

//We show the payment section when it has finished fetching, either succesfully or with an error
export const paymentSectionReady = r.converge(r.or, [hasFetchedPaymentTokens, hasPaymentTokensFailed]);

const orderedOptions = [
  PaymentMethods.ZERO,
  PaymentMethods.CARD,
  PaymentMethods.ADD_CARD,
  PaymentMethods.SEPA,
  PaymentMethods.ADD_SEPA,
  // we're currently not supporting DDs for new orders
  // PaymentMethods.DIRECT_DEBIT,
  PaymentMethods.PAYPAL,
  PaymentMethods.WIRE_TRANSFER,
];

export const getPureOptions = createSelector(
  [wlSelectors.getBrandConfig],
  r.pathOr([], ['payment', 'supportedMethods']),
);

export const getOptions = createSelector(getPureOptions, getWiredTransferFlag, (options, supportsWiredTransfer) =>
  r.pipe(
    r.when(r.always(supportsWiredTransfer), r.append(PaymentMethods.WIRE_TRANSFER)),
    rA.contained,
    // @ts-ignore
    r.flip(r.filter)(orderedOptions),
  )(options),
);

export const getTealiumCategories = r.pipe(
  wlSelectors.getBrandConfig,
  r.pathOr([], ['analytics', 'tealium', 'privacy', 'categories']),
);

// grab the query string and parse it into an object
const getQuery = createSelector<RootState, any, Indexer<{}, string>>(
  [getLocation],
  r.pipe(r.propOr('', 'search'), r.invoker(2, 'parse')(r.__, { ignoreQueryPrefix: true }, qs)),
);

export const getOrderId = createSelector([getQuery], r.prop<string>('order'));

export const getOperationId = createSelector([getQuery], r.prop<string>('operationId'));

export const getTransactionToken = createSelector([getQuery], r.prop<string>('token'));
export const getTransactionId = createSelector([getQuery], r.prop<string>('transactionId'));

export const getTransactionType = createSelector([getQuery], r.prop<string>('type'));

// we want to ensure we don't allow a paypal token to be added if we already have one
// so we must have successfully fetched paymentTokens
// (regardless of if we have a paypal token back in the response)
export const getShowPaypal = hasFetchedPaymentTokens;
