import React from 'react';
import pureEnhance from '@ux/pure-enhance';
import { authGuard, Pending } from '@ux/auth/idp';
import AsyncComponent from '@ux/async-component';
import AsyncPending from './Pending';

const importTransaction = () =>
  import(
    /* webpackChunkName: 'checkout' */
    '../transaction/ConnectedTransaction'
  );

const PurePage = ({ theme }) => <AsyncComponent Pending={AsyncPending} component={importTransaction} theme={theme} />;

const enhance = pureEnhance('TransactionPage', {
  hocs: [authGuard()],
});

export default enhance(PurePage);
