import { combineEpics } from 'redux-most';

import { Core } from '../../../core';
import fetchTokensEpic from './fetchTokens';
import redirectEpic from './redirect';
import submitEpics from './submit';
import validateEpic from './validate';
import transationEpic from './transaction';
import completeEpic from './complete';
import staleEpic from './stale';
import pageView from './pageView';
import fetchProviderEpic from './fetchProvider';

export default (deps: Core) =>
  combineEpics([
    fetchTokensEpic(deps.payment.fetchPaymentTokens, deps.payment.fetchPaymentTokensV2),
    fetchProviderEpic(deps.payment.fetchProviderToken),
    redirectEpic(deps.navigation.navigate, deps.navigation.fullyQualifyPath),
    submitEpics(deps.events.dispatchGAEvent, window),
    validateEpic,
    transationEpic(deps.basket.orderBasket, deps.payment.process3DSecure, deps.payment.postPayment),
    completeEpic(
      deps.events.link,
      deps.events.view,
      deps.events.dispatchFBEvent,
      deps.events.dispatchGAEvent,
      deps.cookies,
      window,
    ),
    pageView(deps.events.view, window),
    staleEpic,
  ]);
