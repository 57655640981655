import merge from 'lodash.merge';
import { Product, Tier, PriceTier } from '@ux/product';
import { BasketItem } from 'core/basket';
import { transformPrice } from 'domain/transformers/prices';

// is the basket item's current term/interval the default price tier?
const isDefaultPrice = (item: BasketItem, product: Product) => {
  const interval = product?.defaultPrice?.interval;
  const term = product?.defaultPrice?.term;
  return item.paymentInterval === interval && item.term === term;
};

const mergeDefaultPrice = (item: BasketItem, product: Product, currency: string): Product => {
  return {
    ...product,
    defaultPrice: merge(product.defaultPrice, {
      retailPrice: transformPrice(item.itemPrices?.priceBeforeAdjustments, currency),
      firstTermCost: transformPrice(item.itemPrices?.firstTermCost, currency),
      recurringCost: transformPrice(item.itemPrices?.recurringCost, currency),
      upFrontCost: transformPrice(item.itemPrices?.upfrontCost, currency),
    }),
  };
};

// is the basket item's current term/interval the same as this tier?
const isTierPrice = (item: BasketItem, tier: Tier) => {
  return item.paymentInterval === tier.paymentInterval && item.term === tier.term;
};

// generate a tier from the basket item's price data
const createTier = (item: BasketItem, tier: PriceTier, currency: string): PriceTier => {
  return {
    ...tier,
    paymentInterval: item.paymentInterval,
    term: item.term,
    firstTermCost: transformPrice(item.itemPrices?.firstTermCost, currency),
    price: transformPrice(item.itemPrices?.priceBeforeAdjustments, currency),
    recurringCost: transformPrice(item.itemPrices?.recurringCost, currency),
    upFrontCost: transformPrice(item.itemPrices?.upfrontCost, currency),
  };
};

// does the basket item have price adjustments?
const hasAdjustments = (item: BasketItem) => {
  return item.itemPrices?.totalAdjustmentValue?.gross > 0;
};

// generate a tier from the basket item's price data
const createPromotionTier = (item: BasketItem, currency: string) => {
  const term = item.term;
  const paymentInterval = item.paymentInterval;
  const totalDiscountedPrice = transformPrice(item.itemPrices?.totalPrice, currency);
  // unit equivalent of totalPrice, only available for baskets created after multiquantity PPE changes
  const unitDiscountedPrice = transformPrice(item.itemPrices?.priceWithAdjustments, currency);
  const totalDiscountAmount = transformPrice(item.itemPrices?.totalAdjustmentValue, currency);
  // unit equivalent of AdjustmentValue, only available for baskets created after multiquantity PPE changes
  const unitDiscountAmount = transformPrice(item.itemPrices?.unitAdjustmentValue, currency);
  const percentOff = item.itemAdjustmentsSummary?.percentOff;

  const discountedPrice = unitDiscountedPrice ? unitDiscountedPrice : totalDiscountedPrice;
  const discountAmount = unitDiscountAmount ? unitDiscountAmount : totalDiscountAmount;

  return {
    term,
    paymentInterval,
    discountedPrice,
    discountAmount,
    percentOff,
  };
};

// find which price tier the basket item is currently set to
// and override it with the basket's pricing
const mergePrice = (item: BasketItem, product: Product, currency: string) => {
  if (isDefaultPrice(item, product)) {
    return mergeDefaultPrice(item, product, currency);
  }
  // loop through the price tiers to see if we can find a match
  const priceTiers = (product.priceTiers ?? []).map((tier) => {
    if (isTierPrice(item, tier)) {
      return createTier(item, tier, currency);
    }
    return tier;
  });

  return {
    ...product,
    priceTiers,
  };
};

// if the basket item has adjustments, we treat this as if it was on offer
// if the product doesn't have any offers, we just create a bespoke one
const mergePromotions = (item: BasketItem, product: Product, currency: string) => {
  let found = false;
  const isAdjusted = hasAdjustments(item);

  // if for some reason priceTiers isn't set (it has happened),
  // we should default it
  let promoProduct = {
    ...product,
    promotionMessage: {
      ...product.promotionMessage,
      priceTiers: (product.promotionMessage?.priceTiers ?? []).reduce((acc, tier) => {
        // if the price tier matches the basket's term/interval
        // we want to replace it with the basket's actual pricing
        if (isTierPrice(item, tier)) {
          found = true;
          // if the product has an offer for this tier
          // but the basket doesn't have any adjustments
          // that means that the offer isn't valid for this customer
          // so we should remove it from the price tiers
          if (!isAdjusted) {
            return acc;
          }
          return acc.concat(createPromotionTier(item, currency));
        }
        return acc.concat(tier);
      }, []),
    },
  };

  // if the currently-selected term/interval doesn't match a promotion tier
  // we want to force a new one onto the product
  if (!found && isAdjusted) {
    promoProduct = {
      ...promoProduct,
      promotionMessage: {
        ...promoProduct.promotionMessage,
        priceTiers: [...promoProduct.promotionMessage?.priceTiers, createPromotionTier(item, currency)],
      },
    };
  }

  return promoProduct;
};

// a  basket item contains a PPE product, but it has its own
// pricing fields, which take precedence over the PPE data
// this selector merges the basket item pricing into the
// PPE object
const getBasketItemProduct = (basketItem: BasketItem, currency: string) => {
  const product = basketItem.product;
  if (product == null) {
    return product;
  }

  // Don't mutate original basketItem product; its generally a
  // bad idea to have side effects but specifically this can
  // cause issues where there is one product for different basket items
  // and you end up altering the orignal product and effecting both items pricing
  let basketItemProduct = JSON.parse(JSON.stringify(product));
  basketItemProduct = mergePrice(basketItem, basketItemProduct, currency);
  basketItemProduct = mergePromotions(basketItem, basketItemProduct, currency);
  return basketItemProduct;
};

export default getBasketItemProduct;
