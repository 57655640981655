import { Http } from '@ux/drivers';

import _fetchBoltOns from './fetchBoltOns';
import _purchaseBoltOns from './purchaseBoltOns';

export default (http: Http) => {
  const fetchBoltOns = _fetchBoltOns(http);
  const purchaseBoltOns = _purchaseBoltOns(http);

  return {
    fetchBoltOns,
    purchaseBoltOns,
  };
};
