import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import * as rk from '@ux/reactive-knowledge';
import { getUpsellSkuId } from 'domain/selectors/products';
import { PurchaseUpsell } from 'core/upsells';
import * as basketMessages from 'domain/messages/basket';
import * as signals from 'application/signals/upsells';
import * as messages from 'domain/messages/upsells';

import { handleAuth, handleEsocketError } from '../utils';

const purchaseEpic = (purchase: PurchaseUpsell) =>
  createEpic({
    signal: signals.PURCHASE_UPSELL,
    selector: (state: any, action) => ({
      skuId: getUpsellSkuId(action.payload.productId)(state),
    }),
    pending: (action: signals.PurchaseUpsell) => messages.adding(action.payload),
    process: (action: signals.PurchaseUpsell, state: { skuId: number }) => {
      const { quantity, productId, ...args } = action?.payload ?? {};
      // @ts-ignore
      return purchase({
        ...args,
        skuId: state.skuId,
        quantity: quantity ?? 1,
      });
    },
    onSuccess: (data, action) => [
      messages.added(action.payload),
      basketMessages.removing({ basketItemId: action.payload.basketItemId }),
      rk.signals.add({
        type: rk.KnowledgeType.SMIRK,
        message: 'uop.basket.item.upsell.purchase.success',
      }),
    ],
    onFailure: handleEsocketError(
      handleAuth((err, action) => [
        messages.addFailed(err, action.payload),
        rk.signals.add({
          type: rk.KnowledgeType.ERK,
          message: 'uop.basket.add.failed',
        }),
      ]),
    ),
    join: true,
  });

export default (purchase: PurchaseUpsell) => combineEpics([purchaseEpic(purchase)]);

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  purchaseEpic,
};
