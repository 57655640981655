import { createEpic } from '@ux/fabric';
import { signals as orchestrationSignals } from '@ux/orchestration';

import * as root from '../../../application/signals/root';

const initializeEpic = () =>
  createEpic({
    signal: root.INITIALIZE,
    onSuccess: () => orchestrationSignals.initialize(),
  });

export default initializeEpic;
