import { Http } from '@ux/drivers';
import normalize from 'domain/transformers/payment-tokens-v2';
import { getBasketId } from 'domain/selectors/common';

const fetchTokens = (json: Http) => () => {
  const request = {
    url: '/api/app/v3/queries/payment/methods/my',
    data: {
      basketId: getBasketId(),
    },
  };
  return json(request).then(({ data }) => {
    return normalize(data);
  });
};

export default fetchTokens;
