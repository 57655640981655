import { createAction } from 'redux-actions';
import { PaymentTokens } from 'core/payment';

// Action Types
const prefix = '@@uop/payment/M_';
// -- Fetch Urls
export const TOKENS_FETCHING = `${prefix}TOKENS_FETCHING`;
export const TOKENS_FETCHED = `${prefix}TOKENS_FETCHED`;
export const TOKENS_FETCH_FAILED = `${prefix}TOKENS_FETCH_FAILED`;

export const TOKENS_FETCHING_V2 = `${prefix}TOKENS_FETCHING_V2`;
export const TOKENS_FETCHED_V2 = `${prefix}TOKENS_FETCHED_V2`;
export const TOKENS_FETCH_FAILED_V2 = `${prefix}TOKENS_FETCH_FAILED_V2`;

// -- Validate
export const VALIDATING = `${prefix}VALIDATING`;
export const VALIDATE_SUCCEEDED = `${prefix}VALIDATE_SUCCEEDED`;
export const VALIDATE_FAILURE = `${prefix}VALIDATE_FAILURE`;
// -- Submit
export const SUBMITTING = `${prefix}SUBMITTING`;
export const SUBMIT_SUCCEEDED = `${prefix}SUBMIT_SUCCEEDED`;
export const SUBMIT_FAILURE = `${prefix}SUBMIT_FAILURE`;
// -- Transaction
export const PROCESSING_TRANSACTION = `${prefix}PROCESSING_TRANSACTION`;
export const TRANSACTION_PROCESSED = `${prefix}TRANSACTION_PROCESSED`;
export const TRANSACTION_PROCESSED_V2 = `${prefix}TRANSACTION_PROCESSED_V2`;
export const TRANSACTION_FAILURE = `${prefix}TRANSACTION_FAILURE`;
export const TRANSACTION_FAILED = `${prefix}TRANSACTION_FAILED`;
export const TRANSACTION_COMPLETE = `${prefix}TRANSACTION_COMPLETE`;

// Action Creators
// -- Fetch Tokens
export const tokensFetching = createAction(TOKENS_FETCHING);
export const tokensFetched = createAction<{ tokens: PaymentTokens }>(TOKENS_FETCHED);
export const tokensFetchFailed = createAction(TOKENS_FETCH_FAILED);

export const tokensFetchingV2 = createAction(TOKENS_FETCHING_V2);
export const tokensFetchedV2 = createAction<{ tokens: PaymentTokens }>(TOKENS_FETCHED_V2);
export const tokensFetchFailedV2 = createAction(TOKENS_FETCH_FAILED_V2);
// -- Validate
export const validating = createAction(VALIDATING);
export const validateSucceeded = createAction(VALIDATE_SUCCEEDED);
export const validateFailure = createAction(VALIDATE_FAILURE);
// -- Submit
export const submitting = createAction(SUBMITTING);
export const submitSucceeded = createAction(SUBMIT_SUCCEEDED);
export const submitFailure = createAction(SUBMIT_FAILURE);
// -- Transaction
export const processingTransaction = createAction(PROCESSING_TRANSACTION);
export const transactionProcessed = createAction<{
  orderId: string;
}>(TRANSACTION_PROCESSED);
export const transactionProcessedV2 = createAction<{
  transactionId: string;
  operationId: string;
}>(TRANSACTION_PROCESSED_V2);
export const transactionFailure = createAction(TRANSACTION_FAILURE);
export const transactionFailed = createAction(TRANSACTION_FAILED);
export const transactionComplete = createAction<{ orderId: string }>(TRANSACTION_COMPLETE);

// -- Fetch Tokens
export type TokensFetching = ReturnType<typeof tokensFetching>;
export type TokensFetched = ReturnType<typeof tokensFetched>;
export type TokensFetchFailed = ReturnType<typeof tokensFetchFailed>;

export type TokensFetchingV2 = ReturnType<typeof tokensFetchingV2>;
export type TokensFetchedV2 = ReturnType<typeof tokensFetchedV2>;
export type TokensFetchFailedV2 = ReturnType<typeof tokensFetchFailedV2>;

export type Validating = ReturnType<typeof validating>;
export type ValidateSucceeded = ReturnType<typeof validateSucceeded>;
export type ValidateFailure = ReturnType<typeof validateFailure>;
// -- Submit
export type Submitting = ReturnType<typeof submitting>;
export type SubmitSucceeded = ReturnType<typeof submitSucceeded>;
// -- Transaction
export type TransactionProcessed = ReturnType<typeof transactionProcessed>;
export type TransactionProcessedV2 = ReturnType<typeof transactionProcessedV2>;
export type TransactionFailure = ReturnType<typeof transactionFailure>;
export type TransactionFailed = ReturnType<typeof transactionFailed>;
export type TransactionComplete = ReturnType<typeof transactionComplete>;
