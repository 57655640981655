import { expect } from 'chai';
import * as selectors from '../';

describe('domain / selectors / routes', () => {
  describe('getRoutes', () => {
    const { getRoutes } = selectors;
    const makeState = (language: string) => ({
      whitelabel: {
        appConfig: {
          data: {
            brand: {
              languages: [language],
            },
          },
        },
      },
    });

    context('when brand language is en-gb', () => {
      it('returns english routes', () => {
        const state = makeState('en-gb');
        const result = getRoutes(state);

        expect(result.basket).to.equal('/basket');
      });
    });
    context('when brand language is en-us', () => {
      it('returns english routes', () => {
        const state = makeState('en-us');
        const result = getRoutes(state);

        expect(result.basket).to.equal('/basket');
      });
    });
    context('when brand language is de-de', () => {
      it('returns german routes', () => {
        const state = makeState('de-de');
        const result = getRoutes(state);

        expect(result.basket).to.equal('/warenkorb');
      });
    });

    context('when language is unknown', () => {
      it;
      it('returns english routes', () => {
        const state = makeState('cz-cz');
        const result = getRoutes(state);

        expect(result.basket).to.equal('/basket');
      });
    });

    context('when state is not set', () => {
      it('returns english routes', () => {
        const state = {};
        const result = getRoutes(state);

        expect(result.basket).to.equal('/basket');
      });
    });
  });
});
