export const Event = {
  REDEEM_VOUCHER: '@uop/basket/redeem-voucher',
  ADD_OFFER: '@uop/offers/add',
  ADD_BOLT_ON: '@uop/basket/add-bolt-on',
  UPGRADE_BASKET_ITEM: '@uop/basket/upgrade',
  REMOVE_FROM_BASKET: '@uop/basket/remove',
  FIELD_HELP: '@uop/forms/open-help',
  FIELD_HELP_REMOVE: '@uop/basket/remove/field-help',
  FIELD_HELP_CLOSE: '@uop/forms/close-help',
  AD_CLICKED: '@uop/ad/clicked',
  FOLLOW_UP_CLICKED: '@uop/follow-up/clicked',
  PAYMENT_METHOD_PICKED: '@uop/payment/method-picked',
  ADD_DOP: '@uop/dop/add',
  REMOVE_DOP: '@uop/dop/remove',
  TOGGLE_GLOBAL_DOP: '@uop/dop/add-all-remove-all',
  PAYMENT_CREDITS_USED: '@uop/payment/credits-consumed',
  ADD_GAB: '@uop/auto-added bolt-on/add',
  REMOVE_GAB: '@uop/auto-added bolt-on/remove',
  ADD_ALL_GABS: '@uop/auto-added bolt-on/add all',
  REMOVE_ALL_GABS: '@uop/auto-added bolt-on/remove all',
};
