import { createAction } from 'redux-actions';
import { View } from 'core/vouchers';

const prefix = '@@uop/vouchers/M_';
// -- Fetch
export const FETCHING = `${prefix}FETCHING`;
export const PREFETCHED = `${prefix}PREFETCHED`;
export const FETCHED = `${prefix}FETCHED`;
export const FETCH_FAILED = `${prefix}FETCH_FAILED`;
// -- Add
export const ADDING = `${prefix}ADDING`;
export const ADDED = `${prefix}ADDED`;
export const ADD_FAILURE = `${prefix}ADD_FAILURE`;
// -- Remove
export const REMOVING = `${prefix}REMOVING`;
export const REMOVED = `${prefix}REMOVED`;
export const REMOVE_FAILURE = `${prefix}REMOVE_FAILURE`;

// -- Fetch
export const fetching = createAction(FETCHING);
export const prefetched = createAction<View>(PREFETCHED);
export const fetched = createAction<View>(FETCHED);
export const fetchFailed = createAction(FETCH_FAILED);
// -- Add
export const adding = createAction<{ code: string }>(ADDING);
export const added = createAction<{ code: string }>(ADDED);
export const addFailure = createAction(
  ADD_FAILURE,
  (err: any) => err,
  (err: any, code: string) => ({ code }),
);

// -- Remove
export const removing = createAction<{ code: string }>(REMOVING);
export const removed = createAction<{ code: string }>(REMOVED);
export const removeFailure = createAction(
  REMOVE_FAILURE,
  (err: any) => err,
  (err: any, code: string) => ({ code }),
);

// -- Fetch
export type Fetching = ReturnType<typeof fetching>;
export type Prefetched = ReturnType<typeof prefetched>;
export type Fetched = ReturnType<typeof fetched>;
export type FetchFailed = ReturnType<typeof fetchFailed>;
// -- Add
export type Adding = ReturnType<typeof adding>;
export type Added = ReturnType<typeof added>;
export type AddFailure = ReturnType<typeof addFailure>;
// -- Remove
export type Removing = ReturnType<typeof removing>;
export type Removed = ReturnType<typeof removed>;
export type RemoveFailure = ReturnType<typeof removeFailure>;
