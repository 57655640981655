import { Http } from '@ux/drivers';

import _redeemVoucher from './redeemVoucher';
import _removeVoucher from './removeVoucher';

export default (json: Http) => {
  return {
    redeemVoucher: _redeemVoucher(json),
    removeVoucher: _removeVoucher(json),
  };
};
