import { combineEpics } from 'redux-most';
import { createEpic, createStructuredSelector } from '@ux/fabric';
import { push } from 'connected-react-router';
import { of } from 'most';
import * as r from 'ramda';

import { Ecommerce, DispatchGAEvent } from '../../../core/events';
import { Event } from '../../../domain/constants/events';
import * as signals from '../../signals/cross-sells';
import * as messages from '../../../domain/messages/cross-sells';
import * as basketSignals from '../../signals/basket';
import * as basketMessages from '../../../domain/messages/basket';
import { getName, getProductId, hasAvailableCrossSells } from '../../../domain/selectors/cross-sells';
import { hasAutoBoltOns } from '../../../domain/selectors/auto-bolt-ons';
import { getNextUrl, getCurrentScreen } from '../../../domain/selectors/common';
import { mapEvent } from '../../../domain/transformers/events';

// there is a basket epic that does the legwork here
// so this signal just defers to the basket signal
const addEpic = () =>
  createEpic({
    signal: signals.ADD,
    selector: (state: any, action: signals.Add) => ({
      productId: getProductId(action.payload.productId)(state),
    }),
    pending: (action) =>
      messages.adding({
        index: action.payload.index,
        productId: action.payload.productId,
      }),
    onSuccess: (x, action, state) =>
      basketSignals.add({
        productId: state.productId,
        term: action.payload.term,
        paymentInterval: action.payload.paymentInterval,
        quantity: action.payload.quantity,
      }),
  });

const addedEpic = () =>
  createEpic({
    signal: signals.ADD,
    selector: (state: any, action: signals.Add) => ({
      productId: getProductId(action.payload.productId)(state),
    }),
    process: (action, state, actions$, store) => {
      const { productId } = state;
      // now we wait for the addToBasket epic to complete
      // we need to check the productId to ensure we're talking about the same product
      // as we could be adding multiple things to the basket
      const subEpic = createEpic({
        signal: basketMessages.ADDED,
        filter: r.pathEq(['payload', 'productId'], productId),
        // you have to return an action in onSuccess so why not just return the incoming action
        onSuccess: (action: basketMessages.Added) => action,
      });
      return subEpic(actions$, store);
    },
    onSuccess: (x, action) => {
      const {
        payload: { index, productId },
      } = action;

      return messages.added({
        index,
        productId,
      });
    },
    join: true,
  });

// now we have to wait for an addFailure message from the basket and piggyback off that too...
const failedEpic = () =>
  createEpic({
    signal: signals.ADD,
    selector: (state: any, action: signals.Add) => ({
      productId: getProductId(action.payload.productId)(state),
    }),
    process: (action, state, actions$, store) => {
      const { productId } = state;
      const subEpic = createEpic({
        signal: basketMessages.ADD_FAILURE,
        filter: r.pathEq(['meta', 'productId'], productId),
        onSuccess: (action2: any) => action2,
      });
      return subEpic(actions$, store);
    },
    onSuccess: ({ payload: err }, action) => {
      const {
        payload: { productId },
      } = action;

      return messages.addFailed(err, productId);
    },
    join: true,
  });

const analyticsEpic = (dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  createEpic({
    signal: messages.ADDED,
    selector: (state: any, action: messages.Added) => {
      return {
        productId: getProductId(action.payload.productId)(state),
        name: getName(action.payload.productId)(state),
      };
    },
    process: (action, state) => {
      dispatch(
        mapEvent({
          event: Event.ADD_OFFER,
          sku: state.productId,
          index: action.payload.index,
        }),
      );

      ecommerce([
        [
          'addProduct',
          {
            id: state.productId,
            name: state.name,
          },
        ],
        [
          'setAction',
          'add',
          {
            step: 0,
            list: 'UOP Offers',
          },
        ],
      ]);
    },
  });

const moveAlongEpic = () =>
  createEpic({
    signal: messages.ADDED,
    selector: createStructuredSelector({
      screen: getCurrentScreen,
      hasMoreOffers: hasAvailableCrossSells,
      hasAutoBoltOns,
      url: getNextUrl,
    }),
    onSuccess: (x, action, state: any) => {
      const { hasAutoBoltOns, hasMoreOffers, screen, url } = state;

      // make sure the user hasn't already left the offers page by this point
      if (screen.key !== 'offers') {
        return [];
      }
      if (hasMoreOffers) {
        return [];
      }
      // we don't want to redirect if the user still has GABs showing on this page
      if (hasAutoBoltOns) {
        return [];
      }
      return of(push(url)).delay(250);
    },
  });

export default (dispatch: DispatchGAEvent, ecommerce: Ecommerce) =>
  combineEpics([addEpic(), addedEpic(), failedEpic(), analyticsEpic(dispatch, ecommerce), moveAlongEpic()]);

// eslint-disable-next-line
export const __test__ = {
  addEpic,
  addedEpic,
  failedEpic,
  analyticsEpic,
  moveAlongEpic,
};
