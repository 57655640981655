import { createSelector as quickSelector } from '@ux/fabric';
import { getUpsellProduct } from 'domain/selectors/products';
import { ProductStatus } from 'domain/constants/product';
import { getScreen } from 'domain/selectors/common/screens';
import { Upsell } from 'core/upsells';

import { State as Upsells } from '../../reducers/uop/upsells';
import { State as RootState } from '../../reducers';

const getState = (state: RootState) => state.uop.upsells as Upsells;

export const hasUpsells = quickSelector([getState], (state) => {
  return Object.keys(state?.upsells ?? {}).length > 0;
});

export const getUpsells = quickSelector(
  [getState, (state: RootState) => (id: number) => getUpsellProduct(id)(state)],
  (state, getProduct) => {
    if (!state.upsells || Object.keys(state.upsells).length === 0) {
      return null;
    }
    return Object.keys(state.upsells).map((upSell) => {
      const product = getProduct((state.upsells[upSell].product as unknown) as number);
      return {
        ...state.upsells[upSell],
        product,
      } as Upsell;
    });
  },
);

export const getUpsell = (basketItemId: number) =>
  quickSelector<RootState, Upsell[], Upsell>([getUpsells], (upsells) =>
    upsells?.find((upsell) => upsell.basketItemId === basketItemId),
  );

export const isEnabled = quickSelector([getScreen('basket')], (state) => Boolean(state.upsell));

export const isFetching = quickSelector([getState], (state) => state.fetching);

export const hasPrefetched = quickSelector([getState], (state) => state.prefetched);

export const hasFetched = quickSelector([getState], (state) => state.fetched);
export const getPendingStates = quickSelector([getState], (state) => state?.pendingStates);

const getPendingState = (id: number) => quickSelector([getPendingStates], (pendingStates) => pendingStates?.[id]);

export const getStatus = (id: number) =>
  quickSelector([getPendingState(id)], (pending) => {
    return pending || ProductStatus.DEFAULT;
  });
