import { createEpic, createStructuredSelector } from '@ux/fabric';
import { getBrandId } from 'domain/selectors/confirmation/index';

import { FetchConfirmation, FetchConfirmationParams } from '../../../core/confirmation';
import { getOrderIdParam } from '../../../domain/selectors/confirmation';
import { FETCH, Fetch } from '../../signals/confirmation';
import { fetchFailed, fetched, fetching } from '../../../domain/messages/confirmation';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';

const selector = createStructuredSelector({
  orderId: getOrderIdParam,
  brandId: getBrandId,
});
const fetchEpic = (fetch: FetchConfirmation) =>
  createEpic({
    signal: FETCH,
    selector,
    pending: fetching,
    process: (action: Fetch, state: FetchConfirmationParams) => fetch(state),
    onSuccess: (confirmation: any) => fetched({ confirmation }),
    onFailure: handleEsocketError(
      handleAuth((err) => {
        return fetchFailed(serverErrorToIntlId('uop.confirmation.load.failed')(err));
      }),
    ),
  });

export default fetchEpic;
