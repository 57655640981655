import { Store } from 'redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BreakpointRoot } from '@ux/responsive';
import { ThemeProvider } from '@team-griffin/react-dye';
import TypographyImporter from '@ux/typography-importer';
import { selectors as wlSelectors } from '@ux/whitelabel';
import Helmet from 'react-helmet';
import { ConnectedRouter } from 'connected-react-router';
import { buildCssVars, Theme } from '@ux/theme-utils';
import { useSelector, Provider as ReduxProvider } from 'react-redux';
import { PageGuard } from '@team-griffin/redux-page-loader';
import { IntlProvider } from 'react-intl';
import StaticPageDestroy from '@ux/page-loader';
import { HeadingRoot, HeadingSection } from '@team-griffin/react-heading-section';
import { History } from 'history';
import useLocale from 'presentation/hooks/useLocale';
/**
 * By default, React Query Devtools are not included in production bundles when process.env.NODE_ENV === 'production',
 * so you don't need to worry about excluding them during a production build.
 * https://react-query.tanstack.com/devtools#import-the-devtools
 */
import { ReactQueryDevtools } from 'react-query/devtools';

import ConnectedApp from './ConnectedApp';

const { getTheme, getMessages, getAssets } = wlSelectors;

const DisplayProvider = () => {
  const theme = useSelector(getTheme) as Theme;
  const themeVars = buildCssVars(theme);
  const locale = useLocale();
  const messages = useSelector(getMessages);
  const { favicon } = (useSelector(getAssets) as { favicon: string }) ?? {};
  return (
    <ThemeProvider theme={theme}>
      <TypographyImporter theme={theme} />
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
        <style>{themeVars}</style>
        <meta name="theme-color" content={theme.palette.primary} />
        <style>
          {`
            .scroller {
              scrollbar-color: transparent transparent;
            }
            .scroller::-webkit-scrollbar {
              display: none;
            }
          `}
        </style>
      </Helmet>

      <IntlProvider locale={locale} messages={messages}>
        <PageGuard renderPage={() => <ConnectedApp />} renderDestroyer={() => <StaticPageDestroy theme={theme} />} />
      </IntlProvider>
    </ThemeProvider>
  );
};

const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: (count: number, err: { statusCode?: string | number; code?: string | number }) => {
        if (count >= 3) {
          return false;
        }
        if (`${err?.statusCode}`.startsWith('4') || `${err?.code}`.startsWith('4')) {
          return false;
        }
        return true;
      },
    },
  },
});

const AppProviders = ({ store, history }: { store: Store; history: History<{}> }) => (
  <ReduxProvider store={store}>
    <ConnectedRouter history={history}>
      <QueryClientProvider client={queryCache} contextSharing={true}>
        <BreakpointRoot>
          <HeadingRoot>
            <HeadingSection>
              <DisplayProvider />
            </HeadingSection>
          </HeadingRoot>
        </BreakpointRoot>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConnectedRouter>
  </ReduxProvider>
);
export default AppProviders;
