import { getFormValues, getFormInitialValues } from 'redux-form';
import { createSelector } from 'reselect';
import { createSelector as quickSelector } from '@ux/fabric';
import * as r from 'ramda';
import { Customer, CustomerFormData, AccountDetails } from 'core/customer';
import { AccountType } from 'domain/constants/customer';

import { State as RootState } from '../../reducers';
import { State } from '../../reducers/uop/customer';

const getState: (state: RootState) => State = r.path(['uop', 'customer']);

export const hasFetched = quickSelector([getState], r.prop('fetched'));

export const getWiredTransferFlag = quickSelector([getState], r.pathOr(false, ['customer', 'wireTransferAllowed']));

export const getCustomerDetails = quickSelector([getState], (state: State) => state.customer as Customer);

export const getIsPartialProfile = quickSelector([getCustomerDetails], (customer: Customer) => {
  if (!customer?.firstName) {
    return true;
  }
  if (!customer?.lastName) {
    return true;
  }
  if (!customer?.telephone) {
    return true;
  }
  const address = customer?.address;
  if (address == null) {
    return true;
  }
  if (!address.address1 || !address.city || !address.postcode || !address.country) {
    return true;
  }
  return false;
});

const mapEmptyStringToNullReducer = (acc: Record<string, unknown>, entry: [string, string]) => {
  const [key, value] = entry;
  return {
    ...acc,
    [key]: value || null,
  };
};

export const getMappedAccountData = (formValues: CustomerFormData) =>
  quickSelector([getCustomerDetails, getAccountType], (customerDetails, type) => {
    const accountDetailsFormValues = formValues?.accountDetails;
    const {
      forename,
      lastname,
      telephone,
      address,
      companyName,
      companyNumber,
      companyTaxNumber,
    } = accountDetailsFormValues;
    const { fax, mobile } = customerDetails;

    const mappedAccountDetails = {
      firstName: forename,
      lastName: lastname,
      telephone,
      address: {
        address1: address?.line1,
        address2: address?.line2,
        address3: address?.line3,
        city: address?.city,
        region: address?.state,
        postcode: address?.postcode,
        country: address?.countryCode,
      },
      type,
      companyName,
      companyNumber,
      companyTaxNumber,
      fax,
      mobile,
    };

    const { address: mappedAddress, ...rest } = mappedAccountDetails;

    // @ts-ignore: Typing this is complicated, but the return type of the function is correct
    return Object.entries(rest).reduce(mapEmptyStringToNullReducer, {
      address: Object.entries(mappedAddress).reduce(mapEmptyStringToNullReducer, {}),
    }) as AccountDetails;
  });

export const getAccountInitialFormValues = createSelector(
  [getFormInitialValues('uop.requiredInformation')],
  (formValues: { customer: CustomerFormData }) => formValues?.customer,
);

export const getAccountFormValues = createSelector(
  [getFormValues('uop.requiredInformation')],
  (formValues: { customer: CustomerFormData }) => formValues?.customer,
);

export const isAccountFormSectionPristine = createSelector(
  [getAccountInitialFormValues, getAccountFormValues],
  (initialFormValues, formValues) => r.equals(initialFormValues, formValues),
);

export const hasInvalidAccountDetails = createSelector(
  [getFormValues('uop.requiredInformation')],
  // eslint-disable-next-line camelcase
  (formValues: { __invalid_account_details__: boolean }) => formValues?.['__invalid_account_details__'],
);

export const getIsCompany = createSelector(
  [getFormValues('uop.requiredInformation')],
  (formValues: { customer: { isCompany: boolean } }) => formValues?.customer?.['isCompany'],
);

const getAccountType = createSelector(
  [getCustomerDetails, getIsPartialProfile, getIsCompany],
  (customerDetails, isPartialProfile, isCompany) => {
    const type = customerDetails?.type;
    // set the account type to INDIVIDUAL/COMPANY only when the company details form section is displayed (is partial profile)
    // otherwise set the account type to whatever the api returns
    if (isPartialProfile) {
      if (isCompany) {
        return AccountType.COMPANY;
      }
      return AccountType.INDIVIDUAL;
    }

    return type;
  },
);
