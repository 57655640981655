import { dispatchGAEvent } from '@ux/tagmg';
import { init as initApm } from '@elastic/apm-rum';

import createComposition from './composition';
import App from './presentation/components/AppProviders';
import render from './render';
declare global {
  interface Window {
    ENVIRONMENT: string;
  }
}
const environment = window.ENVIRONMENT;
initApm({
  serviceName: 'uop-ui',
  environment,
  serverUrl: '/api/apm',
  serviceVersion: '1.0.0',
});

dispatchGAEvent(
  'timing',
  // @ts-ignore
  'UOP Critical Path',
  'start -> main bundle',
  Math.round(performance.now()),
);

const { store, history, init } = createComposition();

render(App)({
  store,
  history,
});

init();
