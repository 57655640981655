import { createAction } from 'redux-actions';

const prefix = '@@uop/account-credits/M_';
export const FETCHING = `${prefix}FETCHING`;
export const FETCHED = `${prefix}FETCHED`;
export const FETCH_FAILED = `${prefix}FETCH_FAILED`;

export const fetching = createAction(FETCHING);
export const fetched = createAction<{ credits: { available: number } }>(FETCHED);
export const fetchFailed = createAction(FETCH_FAILED);

export type Fetching = ReturnType<typeof fetching>;
export type Fetched = ReturnType<typeof fetched>;
export type FetchFailed = ReturnType<typeof fetchFailed>;
