/* eslint-disable camelcase */
import { BrandIds } from 'domain/constants/brands';

interface GoogleConversionData {
  conversion_label: string;
}

/**
 * Get the google ads info for new/existing customers
 * returning the 'conversion_id' and 'conversion_label'.
 *
 * @param {BrandIds} brandId
 * @param {boolean} isNewCustomer
 * @returns {Object} { conversion_id: 'AW-XXXXXXX', conversion_label: '<random-label>' }
 */
export const getGAInfoNcEc = (brandId: string, isNewCustomer: boolean): GoogleConversionData => {
  switch (brandId) {
    case BrandIds.REG:
      return {
        conversion_label: isNewCustomer ? 'dZ17CND-mYQDEPzamPYD' : 'H2t5CPjfy4QDEPzamPYD',
      };
    case BrandIds.HE:
      return {
        conversion_label: isNewCustomer ? 'nmHWCPuOxfkCEP_I_5ED' : '5n1ECO_p8fkCEP_I_5ED',
      };
    default:
      return { conversion_label: '' };
  }
};
