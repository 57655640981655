import { Http } from '@ux/drivers';
import { FetchUpsells } from 'core/upsells';
import normalize from 'domain/transformers/upsells';
import { FetchStatus } from 'domain/constants/basket';
import { getBasketId, getLanguageCookie } from 'domain/selectors/common';

/* We prefetch upsells initially as it produces a significantly
faster HTTP response due to not sending down product data. This
allows us to determine UI pending state earlier and then fetch
the upsells with product data later.
*/
export default (driver: Http): FetchUpsells => ({ brandId }) => {
  const basketId = getBasketId();
  const languageCookie = getLanguageCookie();

  return driver({
    url: `/api/basket/api/v2/baskets/${basketId}/up-sells`,
    data: { localeCode: languageCookie },
    headers: {
      'X-Tenant': brandId,
    },
  }).then(({ data }) => {
    return {
      type: FetchStatus.PREFETCH,
      payload: normalize(data),
    };
  });
};
