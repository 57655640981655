import { Http } from '@ux/drivers';
import * as r from 'ramda';
import { FetchAccountCredits } from 'core/account-credits';
import { getBasketId } from 'domain/selectors/common';

const fetchAccountCredits = (json: Http): FetchAccountCredits => () => {
  const request = {
    url: '/api/app/v3/queries/account-credits',
    data: {
      basketId: getBasketId(),
    },
  };

  return json(request).then<any>(r.prop('data'));
};

export default fetchAccountCredits;
