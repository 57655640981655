import { createEpic, createStructuredSelector } from '@ux/fabric';
import { selectors as authSelectors } from '@ux/auth/idp';
import { combineEpics } from 'redux-most';
import { initialize } from 'redux-form';

import { FETCH_TOKENS } from '../../signals/payment';
import { handleAuth, handleEsocketError, serverErrorToIntlId } from '../utils';
import {
  FetchPaymentTokens,
  FetchPaymentTokensV2,
  PaymentToken,
  PaymentTokens,
  PaymentTokenV2,
} from '../../../core/payment';
import { getAlwaysNewPaypal, getCards, getSepas, isAdyenProvider } from '../../../domain/selectors/payment-tokens';
import {
  tokensFetchedV2,
  tokensFetchFailedV2,
  tokensFetchingV2,
  TOKENS_FETCHED_V2,
  tokensFetchFailed,
  tokensFetched,
  tokensFetching,
  TOKENS_FETCHED,
} from '../../../domain/messages/payment';

const { isAuthenticated } = authSelectors;

const getDefaultId = (tokens: PaymentToken[] | PaymentTokenV2[]) => {
  const tokenValues = Object.values(tokens);
  if (!tokenValues.length) {
    return;
  }
  return tokenValues[0].id;
};

const fetchTokensEpic = (fetchPaymentTokens: FetchPaymentTokens) =>
  createEpic({
    signal: FETCH_TOKENS,
    selector: createStructuredSelector({
      isAuthenticated: isAuthenticated,
      excludePaypal: getAlwaysNewPaypal,
    }),
    filter: (_, { isAuthenticated }) => isAuthenticated,
    pending: () => tokensFetching(),
    process: (_, { excludePaypal }) => fetchPaymentTokens({ excludePaypal }),
    onSuccess: (data: PaymentTokens) => tokensFetched({ tokens: data }),
    onFailure: handleEsocketError(
      handleAuth((err) => tokensFetchFailed(serverErrorToIntlId('uop.paymentTokens.load.failed')(err))),
    ),
  });
const fetchTokensEpicV2 = (fetchPaymentTokens: FetchPaymentTokensV2) =>
  createEpic({
    signal: FETCH_TOKENS,
    selector: createStructuredSelector({
      isAuthenticated: isAuthenticated,
      isAdyenProvider: isAdyenProvider,
    }),
    filter: (_, { isAuthenticated, isAdyenProvider }) => isAuthenticated && isAdyenProvider,
    pending: () => tokensFetchingV2(),
    process: () => fetchPaymentTokens(),
    onSuccess: (data) => tokensFetchedV2({ tokens: data }),
    onFailure: handleEsocketError(
      handleAuth((err) => tokensFetchFailedV2(serverErrorToIntlId('uop.paymentTokens.load.failed')(err))),
    ),
  });

export const fetchSuccessEpic = () =>
  createEpic({
    signal: [TOKENS_FETCHED, TOKENS_FETCHED_V2],
    process: (_, state: any) => {
      const cards = getCards(state);
      const sepa = getSepas(state);
      const identifier = getDefaultId(cards);
      const identifierSepa = getDefaultId(sepa);
      return {
        identifier,
        identifierSepa,
      };
    },
    onSuccess: ({ identifier, identifierSepa }) =>
      initialize(
        'uop.payment',
        {
          identifier,
          identifierSepa,
        },
        { keepDirty: true },
      ),
  });

export default (fetchPaymentTokens: FetchPaymentTokens, fetchPaymentTokensV2: FetchPaymentTokensV2) =>
  combineEpics([fetchTokensEpic(fetchPaymentTokens), fetchTokensEpicV2(fetchPaymentTokensV2), fetchSuccessEpic()]);

// eslint-disable-next-line
export const __test__ = {
  fetchTokensEpic,
  fetchTokensEpicV2,
  fetchSuccessEpic,
};
