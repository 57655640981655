import { Http, Method } from '@ux/drivers';
import { createSequentialQueue } from '@ux/queue';
import { PurchaseUpsell } from 'core/upsells';
import { getBasketId, getLanguageCookie } from 'domain/selectors/common';

export default (driver: Http): PurchaseUpsell => {
  const action: PurchaseUpsell = ({ basketItemId, quantity = 1, ...args }) => {
    const basketId = getBasketId();
    const languageCookie = getLanguageCookie();

    return driver({
      url: `/api/basket/api/v2/baskets/${basketId}/items/${basketItemId}?localeCode=${languageCookie}`,
      method: Method.PUT,
      data: {
        ...args,
        quantity,
      },
    });
  };

  return createSequentialQueue(action);
};
