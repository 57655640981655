import { combineEpics } from 'redux-most';
import { Core } from 'core';

import fetch from './fetch';
import prefetch from './prefetch';
import purchase from './purchase';
import stale from './stale';

export default (deps: Core) =>
  combineEpics([
    prefetch(deps.upsells.prefetchUpSells),
    fetch(deps.upsells.fetchUpSells),
    purchase(deps.upsells.purchaseUpSell),
    stale(),
  ]);
