import { createEpic } from '@ux/fabric';

import { Event } from '../../../domain/constants/events';
import { mapEvent } from '../../../domain/transformers/events';
import { AD_CLICKED, AdClicked } from '../../signals/confirmation';

const adClickedEpic = (dispatch: Function) =>
  createEpic({
    signal: AD_CLICKED,
    process: (action: AdClicked) => [
      dispatch(
        mapEvent({
          event: Event.AD_CLICKED,
          index: action.payload.index,
        }),
      ),
    ],
  });

export default adClickedEpic;
