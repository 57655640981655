import { Http } from '@ux/drivers';
import * as r from 'ramda';
import { FetchRequiredValues } from 'core/details';
import { getBasketId } from 'domain/selectors/common';

const fetchRequiredValues = (json: Http): FetchRequiredValues => () => {
  const request = {
    url: '/api/app/v3/queries/baskets/my/provisioning-fields-data',
    data: {
      basketId: getBasketId(),
    },
  };

  return json(request).then<any>(r.prop('data'));
};

export default fetchRequiredValues;
