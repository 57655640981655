import { Http } from '@ux/drivers';

import _fetchCustomer from './fetchCustomer';
import _updateAccountDetails from './updateAccountDetails';

export default (json: Http) => {
  return {
    fetchCustomer: _fetchCustomer(json),
    updateAccountDetails: _updateAccountDetails(json),
  };
};
