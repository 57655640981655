import { Navigate, FullyQualifyPath } from 'core/navigation';
import { createEpic } from '@ux/fabric';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { combineEpics } from 'redux-most';
import { REDIRECT, Redirect } from 'application/signals/payment';
import { PaymentRoutes } from 'domain/constants/payment';
import { getRoutes } from 'domain/selectors/routes';
import { getCurrency } from 'domain/selectors/basket';

const redirectEpic = (navigate: Navigate, fullyQualifyPath: FullyQualifyPath) =>
  createEpic({
    signal: REDIRECT,
    selector: (state: any) => ({
      baseUrl: wlSelectors.getUrl('upc.index')(state),
      routes: getRoutes(state),
      currency: getCurrency(state),
    }),
    process: (action: Redirect, state) => {
      const { payload } = action;
      const { baseUrl, routes, currency } = state;
      const path = PaymentRoutes[payload.method];
      const providerId = payload.providerId;
      const transactionId = payload.transactionId;
      const failure = fullyQualifyPath(routes.failedTransaction);
      const success = fullyQualifyPath(routes.transaction);
      const url = `${baseUrl}${path}?successUrl=${encodeURIComponent(success)}&failureUrl=${encodeURIComponent(
        failure,
      )}`;

      navigate(
        providerId
          ? `${url}&id=${providerId}&transactionId=${transactionId}`
          : `${url}&currency=${encodeURIComponent(currency)}`,
      );
    },
  });

export default (navigate: Navigate, fullyQualifyPath: FullyQualifyPath) =>
  combineEpics([redirectEpic(navigate, fullyQualifyPath)]);

// eslint-disable-next-line
export const __test__ = {
  redirectEpic,
};
