import * as yup from 'yup';
import * as r from 'ramda';

import { required } from '../../../basic/messages';

export const appPurpose = yup.string().label('domain.group.us.appPurpose').required(required);

export const category = yup.string().label('domain.group.us.category').required(required);

export const organisation = yup
  .string()
  .label('domain.group.us.organisation')
  .when('$domain.group.us.category', {
    is: r.contains(r.__, ['C21', 'C31', 'C32']),
    then: yup.string().required(required),
    otherwise: yup.string().nullable(true),
  });
