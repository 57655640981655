import { schema, normalize } from 'normalizr';
import { Upsell, UpsellResponse } from 'core/upsells';
import { Indexer } from '@uxdev/types';
import { Product } from '@ux/product';

import { productSchema } from './products';

const upsellsSchema = new schema.Entity<Upsell>(
  'upsells',
  { product: productSchema },
  {
    idAttribute: 'productId',
    mergeStrategy: (a, b) => (a.weight > b.weight ? a : b),
    // @ts-ignore type mismatch
    processStrategy: (data: UpsellResponse) => {
      return {
        basketItemId: data.basketItemId,
        productId: data.productId,
        product: data.itemProjection,
        weight: data.weighting,
        promotionalMessage: data.promotionalMessage,
      };
    },
  },
);

export default (data: Array<UpsellResponse> | UpsellResponse) => {
  const { entities } = normalize(Array.isArray(data) ? data : [data], [upsellsSchema]);
  return (entities as unknown) as {
    upsells: Indexer<{}, Upsell>;
    products: Indexer<{}, Product>;
  };
};
