import { createAction } from 'redux-actions';
import { Indexer } from '@uxdev/types';
import { Product } from '@ux/product';
import { Upsell } from 'core/upsells';
import { BasketItemId, ProductId } from 'core/basket';

const prefix = '@@uop/upsells/M_';

export const FETCHING = `${prefix}FETCHING`;
export const PREFETCHED = `${prefix}PREFETCHED`;
export const FETCHED = `${prefix}FETCHED`;
export const FETCH_FAILED = `${prefix}FETCH_FAILED`;
export const ADDING = `${prefix}ADDING`;
export const ADDED = `${prefix}ADDED`;
export const ADD_FAILED = `${prefix}ADD_FAILED`;

export const fetching = createAction(FETCHING);
export const prefetched = createAction<{
  upsells: Indexer<{}, Upsell>;
}>(PREFETCHED);
export const fetched = createAction<{
  upsells: Indexer<{}, Upsell>;
  products: Indexer<{}, Product>;
}>(FETCHED);
export const fetchFailed = createAction(FETCH_FAILED);
export const adding = createAction<{
  basketItemId: BasketItemId;
  productId: ProductId;
}>(ADDING);
export const added = createAction<{
  basketItemId: BasketItemId;
  productId: ProductId;
}>(ADDED);
export const addFailed = createAction(
  ADD_FAILED,
  (err) => err,
  (
    err,
    payload: {
      basketItemId: BasketItemId;
      productId: ProductId;
    },
  ) => payload,
);

export type Fetching = ReturnType<typeof fetching>;
export type Prefetched = ReturnType<typeof prefetched>;
export type Fetched = ReturnType<typeof fetched>;
export type FetchFailed = ReturnType<typeof fetchFailed>;
export type Adding = ReturnType<typeof adding>;
export type Added = ReturnType<typeof added>;
export type AddFailed = ReturnType<typeof addFailed>;
