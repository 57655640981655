import { createAction } from 'redux-actions';
import { BasketItemId, ProductId } from 'core/basket';

// Action Types
const prefix = '@@uop/upsells/S_';
export const FETCH = `${prefix}FETCH`;
export const PREFETCH = `${prefix}PREFETCH`;
export const PURCHASE_UPSELL = `${prefix}PURCHASE_UPSELL`;

// Action Creators
export const fetch = createAction(FETCH);
export const prefetch = createAction(PREFETCH);
export const purchaseUpsell = createAction<{
  basketItemId: BasketItemId;
  productId: ProductId;
  term: number;
  paymentInterval: number;
  quantity?: number;
  addOnItems?: Array<{
    skuId: number;
    quantity: number;
  }>;
  optionValueIds?: Array<number>;
}>(PURCHASE_UPSELL);

// Action Payloads
export type Fetch = ReturnType<typeof fetch>;
export type Prefetch = ReturnType<typeof prefetch>;
export type PurchaseUpsell = ReturnType<typeof purchaseUpsell>;
